import {httpsCallable} from "firebase/functions";
import {firebaseFunctions} from "../firebase/firebase";
import {
  GetAvailableApiKeysRequest,
  GetAvailableApiKeysResponse
} from "tobl-data-schema/dist/api/block/ai/getAvailbleApiKeys";


// Create a reference to the cloud function for getting available API keys
const getAvailableApiKeysFunction = httpsCallable(
  firebaseFunctions,
  "ai-getAvailableApiKeys"  // This should match the name you used to export
  // the function in your Firebase Functions code
);

// Define the function to get available API keys
export async function getAvailableApiKeys(data: GetAvailableApiKeysRequest): Promise<GetAvailableApiKeysResponse> {
  try {
    const response = await getAvailableApiKeysFunction(data);
    return response.data as GetAvailableApiKeysResponse;
  } catch (e) {
    // console.error(e);
    return {keys: []};
  }
}
