import React from 'react';
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  useTheme
} from '@mui/material';
import Logo from "./Logo";
import BlockPartialEditor from "../block/menu/BlockPartialEditor";
import MainDrawerTeamList from "./MainDrawerTeamList";
import AddIcon from "@mui/icons-material/Add";
import HomeIcon from "@mui/icons-material/Home";
import Typography from "@mui/material/Typography";
import {
  BlockType,
  TOP_LEVEL_BLOCK_ID
} from "tobl-data-schema/dist/schema/block/block";
import {AddEditMode} from "../block/menu/BlockMenuAddOrEditDialog";
import {BlockEditButtonContext} from "../block/menu/BlockEditButtonContext";

interface LeftDrawerProps {
  drawerOpen: boolean;
  handleDrawerClose: () => void;
  isMobile: boolean;
  homeHandleClick: () => void;
}

const MainDrawer: React.FC<LeftDrawerProps> = ({
                                                 drawerOpen,
                                                 handleDrawerClose,
                                                 isMobile,
                                                 homeHandleClick
                                               }) => {
  const theme = useTheme();
  const fullScreen = window.innerWidth >= theme.breakpoints.values.lg;
  const isHomePage = window.location.pathname === "/app" || window.location.pathname === "/";

  return (
    <Drawer
      anchor={'left'}
      open={drawerOpen}
      onClose={handleDrawerClose}
      variant={isMobile ? 'temporary' : 'permanent'}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: 300,
          borderRadius: 0,
          boxShadow: 'none',
          border: 'none',
          outline: `1px solid ${theme.palette.divider}`,
        },
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
        role="presentation"
        onClick={handleDrawerClose}
        onKeyDown={handleDrawerClose}
        bgcolor={theme.palette.background.default}
      >
        <List>
          <ListItem
            onClick={() => {
              homeHandleClick();
              handleDrawerClose();
            }}
            sx={{
              cursor: 'pointer',
              padding: fullScreen ? '25px 20px 30px' : '20px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Logo size={24}/>
          </ListItem>
          <MenuItem
            onClick={homeHandleClick}
            selected={isHomePage}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: '0px 19px',
              margin: '0px 0px',
              height: '40px'
            }}
          >
            <HomeIcon
              sx={{width: '20px', height: '20px'}}
              color={!isHomePage ? 'inherit' : 'primary'}
            />
            <ListItemText
              primary={
                <Typography
                  variant={'subtitle2'}
                  color={!isHomePage ? 'inherit' : 'primary'}
                  width={'100%'}
                  ml={2}
                >
                  Home
                </Typography>
              }
            />
          </MenuItem>
          <MainDrawerTeamList/>

          <BlockPartialEditor
            addEditMode={AddEditMode.ADD}
            blockType={BlockType.team}
            blockId={TOP_LEVEL_BLOCK_ID}
            buttonContext={BlockEditButtonContext.TITLE_CHANGE}
          >
            <MenuItem
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                padding: '0px 16px',
                margin: '0px 0px',
                height: '40px'
              }}
            >
              <Box width={'21px'} height={'24px'} display={'flex'}
                   alignItems={'center'}>
                <AddIcon
                  sx={{width: '26px'}}
                  color={'inherit'}
                />
              </Box>
              <Typography
                variant={'subtitle2'}
                color={'inherit'}
                width={'100%'}
                ml={'17px'}
              >
                Create new team
              </Typography>
            </MenuItem>
          </BlockPartialEditor>

        </List>
      </Box>
    </Drawer>
  );
};

export default MainDrawer;
