import React, {useState} from 'react';
import MenuItem from '@mui/material/MenuItem';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ManageBlockModal
  from "../../../components/block/projects/ManageBlockModal";
import Dialog from '@mui/material/Dialog';
import {SchemaBlock} from "tobl-data-schema/dist/schema/block/block";

interface ManageBlockTreeMenuItemProps {
  block: SchemaBlock;
}

const ManageBlockTreeMenuItem: React.FC<ManageBlockTreeMenuItemProps> = ({block}) => {
  const [blockMapOpen, setBlockMapOpen] = useState(false);

  return (
    <>
      <MenuItem onClick={(e) => {
        e.stopPropagation();
        setBlockMapOpen(true)
      }}>
        <AccountTreeIcon fontSize={'small'} sx={{mr: 1}}/>
        Manage Block Tree
      </MenuItem>
      {blockMapOpen &&
          <Dialog open={blockMapOpen}
                  onClose={() => setBlockMapOpen(false)}
                  fullWidth={true}
                  fullScreen={true}
                  sx={{ // Add custom styles
                    '.MuiDialog-paper': { // Target the paper element inside the dialog
                      width: '90%', // Set the width to 90%
                      height: '90%', // Set the height to 90%
                    },
                  }}
          >
              <ManageBlockModal block={block} open={blockMapOpen}
                                setOpen={setBlockMapOpen}/>
          </Dialog>}
    </>
  );
};

export default ManageBlockTreeMenuItem;
