import TextField from "@mui/material/TextField";
import React from "react";
import {useTranslation} from "react-i18next";
import {
  BlockType_Processor,
  SchemaBlock_Processor_Config,
} from "tobl-data-schema/dist/schema/block/block_type/processor";
import {BlockEditFormRow} from "./BlockEditFormRow";

export function EditFormProcessorFreeTextInput({
                                                 configuration,
                                                 updateConfigData,
                                               }: {
  configuration: SchemaBlock_Processor_Config,
  updateConfigData: (key: string, value: any) => void
}) {
  const {t} = useTranslation();

  return (
    <>
      {configuration.blockType_Processor === BlockType_Processor.triggered_text_document && (
        <>
          <BlockEditFormRow label={t("Text")}>
            <TextField
              onKeyDown={e => e.stopPropagation()}
              onKeyUp={e => e.stopPropagation()}
              type="text"
              multiline
              minRows={10}
              value={configuration.text || ""}
              onChange={(e) => updateConfigData('text', e.target.value)}
              fullWidth
            />
          </BlockEditFormRow>
        </>
      )}
    </>
  );
}
