import {useEffect, useState} from 'react';
import {
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  where
} from "firebase/firestore";
import {SchemaTrace} from "tobl-data-schema/dist/schema/trace";
import {COLLECTION_TRACES} from "../models/trace";
import {getDocsFromSnapshot} from "../firebase/firestore/getDocsFromSnapshot";

const DOCUMENTS_PER_PAGE = 50;

export const useTraces = (projectBlockId: string, sortOption: 'title' | 'createdAt', page: number) => {
  const [traces, setTraces] = useState<SchemaTrace[] | undefined>(undefined);

  useEffect(() => {
    if (!projectBlockId) {
      console.error("Project block ID is not defined.");
      return;
    }

    const q = query(
      COLLECTION_TRACES,
      where("projectBlockId", "==", projectBlockId),
      orderBy(sortOption, sortOption === 'title' ? 'asc' : 'desc'),
      limit(DOCUMENTS_PER_PAGE * page)
    );

    getDocs(q)
      .then(getDocsFromSnapshot<SchemaTrace>)
      .then(setTraces);

    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const fetchedTraces = getDocsFromSnapshot<SchemaTrace>(querySnapshot);
        setTraces(fetchedTraces);
      },
      (error) => {
        console.error("Error fetching traces: ", error, "Code: ", error.code);
      }
    );

    return () => {
      unsubscribe();
    };

  }, [projectBlockId, sortOption, page]);


  useEffect(() => {
    setTraces(undefined);
  }, [projectBlockId]);

  return traces;
};
