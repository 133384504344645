import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import languageDetector from "i18next-browser-languagedetector";

// Import additional JSON files
import blockEn from "./block/en.json";
import messagesEn from "./messages/en.json";
import profileEn from "./profile/en.json";
import authenticationEn from "./authentication/en.json";
import settingsEn from "./settings/en.json";
import welcomeEn from "./welcome/en.json";

// Import additional Korean JSON files
import blockKo from "./block/ko.json";
import messagesKo from "./messages/ko.json";
import profileKo from "./profile/ko.json";
import authenticationKo from "./authentication/ko.json";
import settingsKo from "./settings/ko.json";
import welcomeKo from "./welcome/ko.json";


// Merge translations
const mergedTranslationEn = {
  ...blockEn,
  ...messagesEn,
  ...profileEn,
  ...authenticationEn,
  ...settingsEn,
  ...welcomeEn
};

const mergedTranslationKo = {
  ...blockKo,
  ...messagesKo,
  ...profileKo,
  ...authenticationKo,
  ...settingsKo,
  ...welcomeKo
};

i18n.use(languageDetector).use(initReactI18next).init(
  {
    resources: {
      en: {
        translation: mergedTranslationEn,
      },
      ko: {
        translation: mergedTranslationKo,
      }
    },
    fallbackLng: "en",
    detection: {
      // 다음과 같은 순서로 언어를 감지합니다.
      order: ['queryString', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      caches: ['localStorage', 'cookie'], // 사용자의 언어 설정을 캐시에 저장합니다.
    },
    interpolation: {
      escapeValue: false, // XSS 공격 방지를 위해 필요합니다. React는 기본적으로 이를 처리합니다.
    },
    react: {
      useSuspense: false, // Suspense를 사용하지 않도록 설정합니다. 필요에 따라 변경할 수 있습니다.
    },
  });
