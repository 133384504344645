import React from 'react';
import {Box, Typography, useMediaQuery, useTheme} from '@mui/material';
import HomeFooter from '../../components/home/HomeFooter';
import HomeTitle from "../../components/home/HomeTitle";
import {GRAY} from "../../theme/colors";
import {useTranslation} from "react-i18next";
import {useInView} from "react-intersection-observer";

interface Props {
  title: string;
  description?: string;
  videoUrl: string;
  children: React.ReactNode;
}

const MainView: React.FC<Props> = ({title, description, videoUrl, children}) => {
  const theme = useTheme();
  const {t} = useTranslation();
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '0px -50px',
  });

  const style = inView ? {
    opacity: 1,
    transform: 'translateY(0)',
    transition: 'opacity 1.5s ease-out, transform 1.5s ease-out',
    transitionDelay: '1.5s',
  } : {
    opacity: 0,
    transform: 'translateY(50px)',
    transitionDelay: '0s',
  };

  const background = <Box sx={{
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    background: GRAY.w900,
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      background: 'radial-gradient(circle, rgba(0, 0, 0, 0.7) 30%, transparent 100%)',
      pointerEvents: 'none',
    },
  }}>
    <Box sx={{
      position: 'relative',
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      background: 'transparent',
      '&::after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        width: '100%',
        height: '30%',
        top: 0,
        left: 0,
        background: 'linear-gradient(to bottom, rgba(0,0,0,1) 0%, transparent 70%)',
        pointerEvents: 'none',
      },
    }}>
      <video autoPlay loop muted playsInline style={{
        width: 'auto',
        height: '100%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      }}>
        <source src={videoUrl} type="video/mp4"/>
        Your browser does not support the video tag.
      </video>
    </Box>
  </Box>;

  return (
    <Box
      margin="auto"
      minHeight={'100vh'}
      display='flex'
      flexDirection='column'
      justifyContent='space-between'
      sx={{
        background: 'linear-gradient(to top left, rgba(255, 142, 255, 0.2) 10%, transparent 50%)',
      }}
    >
      <Box>
        <Box sx={{
          position: 'absolute',
          width: '100%',
          height: largeScreen ? '56.25vw' : '100vh',
          aspectRatio: 16 / 9,
          overflow: 'hidden',
        }}>
          {background}
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              maxWidth: '1000px',
              margin: 'auto',
              width: '100%',
              padding: 4,
            }}>
            <HomeTitle text={title} textAlign={'center'} divider={false}/>
            <Typography
              variant={largeScreen ? "h6" : "subtitle1"}
              color={GRAY.w100}
              textAlign={'center'}
              mt={'20px'}
              ref={ref} style={style}
            >
              {description}
            </Typography>
          </Box>
        </Box>
        <Box
          position={'relative'}
          borderColor={theme.palette.background.default}
        >
          <Box
            mt={largeScreen ? '56.25vw' : '100vh'}
            pb={'100px'}
            sx={{
              background: 'linear-gradient(to top, transparent 0%,  rgba(83, 142, 255, 0.1) 30%, rgba(83, 142, 255, 0.1) 50%, transparent 100%)',
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
      <HomeFooter/>
    </Box>
  );
}

export default MainView;
