import React, {useState} from 'react';
import {ListItemIcon, ListItemText, MenuItem, Snackbar} from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import {SchemaBlock} from "tobl-data-schema/dist/schema/block/block";
import {useTranslation} from "react-i18next";
import {ManageButton} from '../../../views/block/ManageBlockSettingView';

interface ToggleActiveStatusMenuOptionProps {
  block: SchemaBlock;
  isTeamBlock?: boolean;
}

const BlockMenuCopyBlockIdButton: React.FC<ToggleActiveStatusMenuOptionProps> = ({block, isTeamBlock}) => {
  const {t} = useTranslation();
  const [showSnackbar, setShowSnackbar] = useState(false);

  const copyBlockIdToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(block.id);
      setShowSnackbar(true);
    } catch (err) {
      console.error('Failed to copy text');
    }
  };

  return (
    <>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={() => setShowSnackbar(false)}
        message={isTeamBlock ? "Team ID copied to clipboard" : "Block ID copied to clipboard"}
      />
      {(isTeamBlock
        ? <ManageButton
          icon={<FileCopyIcon/>}
          title={t("Team ID")}
          description={block.id}
          buttonTitle={t('Copy team ID')}
          onClick={(event) => {
            event.stopPropagation();
            copyBlockIdToClipboard();
          }}
        />
        : <MenuItem onClick={(event) => {
          event.stopPropagation();
          copyBlockIdToClipboard();
        }}>
          <ListItemIcon>
            <FileCopyIcon color={'primary'}/>
          </ListItemIcon>
          <ListItemText>
            {t('Copy BlockID')}
          </ListItemText>
        </MenuItem>)}
    </>
  );
};

export default BlockMenuCopyBlockIdButton;
