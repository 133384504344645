import React, {useEffect, useState} from 'react';
import {CircularProgress, LinearProgress, useTheme} from '@mui/material';
import {collection, doc, onSnapshot} from "firebase/firestore";
import {db} from "../../../../../firebase/firebase";
import {parseDoc} from "../../../../../firebase/firestore/parseDoc";
import {ErrorOutline} from '@mui/icons-material';
import {SchemaTrace} from "tobl-data-schema/dist/schema/trace";
import {
  RunResult,
  RunStatus,
  SchemaBlockRun
} from "tobl-data-schema/dist/schema/block/run/run";

export enum ProgressType {
  CIRCULAR = 'circular',
  LINEAR = 'linear'
}

interface RunProgressProps {
  trace: SchemaTrace;
  progressType?: ProgressType;
}

export const DocumentTraceProgress: React.FC<RunProgressProps> = ({
                                                                    trace,
                                                                    progressType = ProgressType.CIRCULAR
                                                                  }) => {
  const theme = useTheme();
  const [runs, setRuns] = useState<{
    [blockId: string]: SchemaBlockRun
  }>({});
  const [progress, setProgress] = useState<number>(0);
  const [unsubscribes, setUnsubscribes] = useState<Array<() => void>>([]);
  const hasFailedRun = Object.values(runs).some(run => run.runResult === RunResult.failure);
  const notFinishedRun = Object.values(runs).some(run => run.runStatus !== RunStatus.finished);

  useEffect(() => {
    const newUnsubscribes: Array<() => void> = [];

    Object.entries(trace.blocks).forEach(([blockId, blockDetail]) => {
      if (!blockDetail.runId) {
        return;
      }

      const q = doc(
        collection(db, `blocks/${blockId}/runs`),
        blockDetail.runId
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        if (snapshot.exists() === false) {
          return;
        }
        const newRun = parseDoc<SchemaBlockRun>(snapshot);
        setRuns(prevRuns => ({...prevRuns, [newRun.id]: newRun}));
      });

      newUnsubscribes.push(unsubscribe);
    });

    setUnsubscribes(newUnsubscribes);

    return () => {
      newUnsubscribes.forEach(unsubscribe => unsubscribe());
    };
  }, [trace.blocks]);

  useEffect(() => {
    const blockCount = Object.values(trace.blocks).length;
    const totalProgress = Object.values(runs).reduce(
      (acc, run) => acc +
        (run.runStatus === RunStatus.finished ? 100 : 0), 0);
    const newProgress = blockCount > 0 ? (totalProgress / blockCount) : 0;

    setProgress(newProgress);

    if (newProgress >= 99) {
      unsubscribes.forEach(unsubscribe => unsubscribe());
    }
    return () => {
      unsubscribes.forEach(unsubscribe => unsubscribe());
    }
  }, [runs, unsubscribes, trace]);

  return (
    hasFailedRun
      ? <ErrorOutline
        sx={{
          fontSize: 30,
          color: theme.palette.error.light,
          pointerEvents: 'none',
        }}/>
      : notFinishedRun
        ? progressType === ProgressType.CIRCULAR
          ? <CircularProgress
            size={26}
            variant="determinate"
            value={progress}
          />
          : <LinearProgress
            sx={{height: '1px'}}
            variant="determinate"
            value={progress}
          />
        : null
  );
};
