import React, {useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  LinearProgress,
  ListItemIcon,
  ListItemText,
  MenuItem
} from "@mui/material";
import PlayArrowIcon from '@mui/icons-material/PlayArrow'; // Importing the PlayArrow icon
import UploadFiles from "../inputs/UploadFiles";
import {BlockType, SchemaBlock} from "tobl-data-schema/dist/schema/block/block";
import {
  BlockType_Input
} from "tobl-data-schema/dist/schema/block/block_type/input";
import TextInput from "../inputs/TextInput";

const BlockMenuRunButton: React.FC<{ block: SchemaBlock }> = ({block}) => {
  const [open, setOpen] = useState(false);

  const [progress, setProgress] = useState(0);

  const handleClose = () => {
    // Reset the progress
    setProgress(0);
    // Close the dialog
    setOpen(false);
  };

  const handleRunClick = (e: React.MouseEvent) => {
    // Prevent propagation and other default actions
    e.stopPropagation();

    // Open the dialog
    setOpen(true);
  };

  if (block.blockType !== BlockType.input) return null;

  return <>
    <MenuItem color="primary" onClick={handleRunClick}>
      <ListItemIcon>
        <PlayArrowIcon color={'primary'}/>
      </ListItemIcon>
      <ListItemText>
        Run
      </ListItemText>
    </MenuItem>

    <Dialog open={open} onClose={handleClose}>
      <DialogTitle variant="h5" textAlign={'center'}>{block.title}</DialogTitle>
      <DialogContent>
        {block.blockType === BlockType.input &&
          ((block.configuration.blockType_Input === BlockType_Input.file_uploader_pdf ||
              block.configuration.blockType_Input === BlockType_Input.file_uploader_audio ||
              block.configuration.blockType_Input === BlockType_Input.file_uploader_video ||
              block.configuration.blockType_Input === BlockType_Input.file_uploader_image ||
              block.configuration.blockType_Input === BlockType_Input.file_uploader_text
            ) ? (
                <UploadFiles blockId={block.id}
                             setProgress={setProgress}
                             handleClose={handleClose}
                />
              )
              : block.configuration.blockType_Input === BlockType_Input.text ? (
                  <TextInput
                    blockId={block.id}
                    setProgress={setProgress}
                    handleClose={handleClose}
                  />
                ) :
                "Unsupported block type."
          )}
      </DialogContent>
      {progress > 0 && (
        <LinearProgress variant="determinate" value={progress}/>)}
    </Dialog>
  </>
};

export default BlockMenuRunButton;
