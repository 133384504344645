// Define the schema for the addApiKey request
import {httpsCallable} from "firebase/functions";
import {firebaseFunctions} from "../firebase/firebase";
import {
  addAiApiKeyRequest
} from "tobl-data-schema/dist/api/block/ai/addAiApiKey";


// Create a reference to the cloud function for adding an API key
const addApiKeyFunction = httpsCallable(
  firebaseFunctions,
  "ai-addAiApiKey"
);

// Define the function to add an API key
export async function addApiKey(request: addAiApiKeyRequest) {
  await addApiKeyFunction(request);
}
