import React from 'react';
import {SchemaAppCreate, SchemaAppXlsxAggregator} from "../../../models/apps";
import InputBlockSelector from "./InputBlockSelector";

export default function AppFormXlsxAggregator({app, setApp}: {
  app: Partial<SchemaAppXlsxAggregator>
  setApp: React.Dispatch<React.SetStateAction<Partial<SchemaAppCreate>>>
}) {

  return (
    <>
      <InputBlockSelector
        label={'Xlsx Input Block'}
        value={app.xlsxFileBlockId || ""}
        onUpdate={(xlsxFileBlockId) => {
          setApp((p) => ({
            ...p,
            xlsxFileBlockId: xlsxFileBlockId
          }))
        }}
      />
    </>
  );
}
