import React, { createContext, useContext, useState, useEffect, ReactNode, FunctionComponent } from 'react';

interface ThemeContextProps {
  mode: 'dark' | 'light' | 'auto';
  effectiveMode: 'dark' | 'light'; // effectiveMode 상태 추가
  setMode: (mode: 'dark' | 'light' | 'auto') => void;
  setEffectiveMode: (mode: 'dark' | 'light') => void; // 이 함수를 추가
}

// 기본값 설정
const ThemeContext = createContext<ThemeContextProps>({
  mode: 'auto',
  effectiveMode: 'light', // 초기 기본값 설정
  setMode: () => {},
  setEffectiveMode: () => {}, // 초기 기본 함수 설정
});


export const useThemeContext = () => {
  return useContext(ThemeContext);
};

interface ThemeProviderProps {
  children: ReactNode;
}

export const ThemeContextProvider: FunctionComponent<ThemeProviderProps> = ({ children }) => {
  const savedMode = localStorage.getItem('themeMode') as 'dark' | 'light' | 'auto' || 'auto';
  const [mode, setMode] = useState<'dark' | 'light' | 'auto'>(savedMode);
  const [effectiveMode, setEffectiveMode] = useState<'dark' | 'light'>(savedMode === 'dark' ? 'dark' : 'light');

  useEffect(() => {
    const mql = window.matchMedia('(prefers-color-scheme: dark)');

    const updateModeBasedOnSystem = () => {
      const newMode = mql.matches ? 'dark' : 'light';
      if (mode === 'auto') {
        setEffectiveMode(newMode);
      }
    };
    mql.addEventListener('change', updateModeBasedOnSystem);
    updateModeBasedOnSystem();

    return () => {
      mql.removeEventListener('change', updateModeBasedOnSystem);
    };
  }, [mode]);


  useEffect(() => {
    localStorage.setItem('themeMode', mode);
  }, [mode]);

  return (
    <ThemeContext.Provider value={{ mode, setMode, effectiveMode, setEffectiveMode }}>
      {children}
    </ThemeContext.Provider>
  );
};
