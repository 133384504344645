import {useBlockTree} from "./useBlockTree";

export const useGetBlockFromBlockTreeLookup = (blockId: string) => {
  const {blockLookup, blockTreeLoading} = useBlockTree();

  return {
    block: blockLookup?.[blockId],
    blockTreeLoading
  };
};
