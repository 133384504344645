import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
  BlockType_Processor,
  SchemaBlock_Processor_Config_TableSummarizer,
  TableSummarizerAggregate,
} from "tobl-data-schema/dist/schema/block/block_type/processor";
import {BlockEditFormRow} from "./BlockEditFormRow";

type Row = {
  column: string;
  aggregate: TableSummarizerAggregate;
  [key: string]: string | TableSummarizerAggregate;
};

export function EditFormProcessorTableSummarizer({
                                                   configuration,
                                                   updateConfigData,
                                                 }: {
  configuration: SchemaBlock_Processor_Config_TableSummarizer,
  updateConfigData: (key: string, value: any) => void
}) {

  const {t} = useTranslation();

  const [newRows, setNewRows] = useState<Row[]>([]);

  const handleAddRow = () => {
    setNewRows([...newRows, {
      column: "",
      aggregate: TableSummarizerAggregate.sum
    }]);
  };

  console.log(configuration);

  useEffect(() => {
    if (configuration.aggregateColumns) {
      const initialRows = Object.entries(configuration.aggregateColumns).map(([column, aggregate]) => ({
        column,
        aggregate
      }));

      setNewRows(initialRows);
    }
  }, []);

  useEffect(() => {
    const aggregateColumns = newRows.reduce<{
      [key: string]: TableSummarizerAggregate
    }>((acc, row) => {
      if (row.column.trim() !== '') {
        acc[row.column] = row.aggregate;
      }
      return acc;
    }, {});

    updateConfigData('aggregateColumns', aggregateColumns);
  }, [newRows]);

  const handleNewRowChange = (index: number, key: string, value: string | TableSummarizerAggregate) => {
    const updatedRows = [...newRows];
    updatedRows[index][key] = value;
    setNewRows(updatedRows);
  };

  const handleDeleteRow = (index: number) => {
    const {column, aggregate} = newRows[index];
    handleAggregateColumnsChange(column, aggregate);
    const updatedRows = [...newRows];
    updatedRows.splice(index, 1);
    setNewRows(updatedRows);
  };

  const handleAggregateColumnsChange = (column: string, aggregate: TableSummarizerAggregate) => {

    updateConfigData('aggregateColumns', {
      ...configuration.aggregateColumns,
      [column]: aggregate
    });
  };

  useEffect(() => {

    if (configuration.groupByColumns === undefined) {
      updateConfigData('groupByColumns', []);
    }
    if (configuration.aggregateColumns === undefined) {
      updateConfigData('aggregateColumns', {
        "column": TableSummarizerAggregate.sum
      });
    }
  }, []);

  if (configuration.blockType_Processor !== BlockType_Processor.table_summarizer) {
    return null;
  }

  if (configuration.aggregateColumns === undefined) {
    return null;
  }

  if (configuration.groupByColumns === undefined) {
    return null;
  }

  return (
    <>
      {configuration.blockType_Processor === BlockType_Processor.table_summarizer && (
        <>
          <BlockEditFormRow label={t("Group By Columns")}>
            <TextField
              onKeyDown={e => e.stopPropagation()}
              onKeyUp={e => e.stopPropagation()}
              type="text"
              value={configuration.groupByColumns.join(', ')}
              onChange={(e) => updateConfigData('groupByColumns', e.target.value.split(',').map(s => s.trim()).filter(s => s !== ''))}
              fullWidth
            />
          </BlockEditFormRow>


          <BlockEditFormRow label={t("Aggregate Columns")}>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  {newRows.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <TextField
                          onKeyDown={e => e.stopPropagation()}
                          onKeyUp={e => e.stopPropagation()}
                          type="text"
                          label={t("Column")}
                          value={row.column}
                          onChange={(e) => handleNewRowChange(index, "column", e.target.value)}
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        <FormControl fullWidth>
                          <InputLabel>{t("Aggregate Method")}</InputLabel>
                          <Select
                            value={row.aggregate}
                            onChange={(e) => handleNewRowChange(index, "aggregate", e.target.value as TableSummarizerAggregate)}
                          >
                            {Object.values(TableSummarizerAggregate).map((method) => (
                              <MenuItem key={method}
                                        value={method}>{method}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        <Button
                          onClick={() => handleDeleteRow(index)}>Delete</Button>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell colSpan={3}>
                      <Button onClick={handleAddRow}>Add</Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </BlockEditFormRow>
        </>
      )}
    </>
  );
}
