import {httpsCallable} from "firebase/functions";
import {firebaseFunctions} from "../firebase/firebase";
import {
  GetBlockTreeRequest,
  GetBlockTreeResponse
} from "tobl-data-schema/dist/api/block/block/getBlockTree";

// Create a reference to the cloud function
const getBlockTreeCloudFunction = httpsCallable(
  firebaseFunctions,
  "block-getBlockTree" // the name of your Cloud Function
);

// This should only be used to construct the tree. Use useBlockTree instead.
export async function getBlockTree(request: GetBlockTreeRequest): Promise<GetBlockTreeResponse> {
  // Make the request to the cloud function
  const response = await getBlockTreeCloudFunction(request);
  return response.data as GetBlockTreeResponse;
}
