import {useEffect, useState} from 'react';
import {doc, getDoc} from 'firebase/firestore';
import {COLLECTION_APPS, SchemaApp} from '../models/apps';
import {parseDoc} from "../firebase/firestore/parseDoc";

const useGetApp = (appId: string | undefined) => {
  const [app, setApp] = useState<SchemaApp | undefined>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<unknown>();
  const [reloadTrigger, setReloadTrigger] = useState(false); // Add this line

  const update = () => {
    setReloadTrigger(prev => !prev);
  };
  useEffect(() => {
    if (!appId) return;
    const fetchApp = async () => {
      setLoading(true);
      try {
        const appDoc = await getDoc(doc(COLLECTION_APPS, appId));
        if (appDoc.exists()) {
          setApp(parseDoc<SchemaApp>(appDoc));
        } else {
          setError(new Error('App not found'));
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchApp();
  }, [appId, reloadTrigger]);

  return {app, loading, error, update};
};

export default useGetApp;
