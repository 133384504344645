// VoiceInputComponent.tsx
import React, {useEffect, useMemo, useState} from 'react';
import {Box, CircularProgress, TextField} from '@mui/material';
import {VoiceRecorder} from "./VoiceRecorder";
import {AddRunRequest} from "tobl-data-schema/dist/api/block/block/run/addRun";
import {addRun} from "../../../cloud_functions/addRun";
import {doc, onSnapshot} from "firebase/firestore";
import {COLLECTION_TRACES} from "../../../models/trace";
import {parseDoc} from "../../../firebase/firestore/parseDoc";
import {SchemaTrace} from "tobl-data-schema/dist/schema/trace";
import {v4 as uuidv4} from "uuid";
import {useSnackbar} from "../../../hooks/useSnackbar";
import {SchemaAppEmailSender} from "../../../models/apps";

interface VoiceInputComponentProps {
  app: SchemaAppEmailSender;
  promptText: string | undefined;
  setPromptText: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const VoiceInputComponent: React.FC<VoiceInputComponentProps> = ({
                                                                   app,
                                                                   promptText,
                                                                   setPromptText
                                                                 }) => {
  const [audioUrl, setAudioUrl] = useState<{
    fileUrl: string,
    storagePath: string,
    title: string
  }>(); // Add this line
  const [isLoading, setIsLoading] = useState(false); // Add this line
  const traceId = useMemo(() => uuidv4(), [audioUrl]); // Add this line

  const {showSnackbar} = useSnackbar();
  const [audioTraceIdToSubscribe, setAudioTraceIdToSubscribe] = useState<string>()

  const [audioToblTrace, setAudioToblTrace] = useState<SchemaTrace>()

  useEffect(
    () => {
      if (!audioUrl) return;
      if (!traceId) return;

      const data: AddRunRequest = {
        blockId: app.voiceRecognitionBlockId,
        clientInputData: audioUrl,
        traceId: traceId,
      }
      showSnackbar('Processing the voice input', 'success');

      try {
        addRun(data).then(() => {
          setAudioTraceIdToSubscribe(traceId);
        });
      } catch (error) {
        console.error("Error adding run: ", error);
        setIsLoading(false);
      }
    },
    [traceId, app]
  );

  useEffect(() => {
    if (!audioTraceIdToSubscribe) return;
    const unsubscribe = onSnapshot(
      doc(COLLECTION_TRACES, audioTraceIdToSubscribe),
      (doc) => {
        setAudioToblTrace(parseDoc<SchemaTrace>(doc));
      }
    );
    return () => unsubscribe();

  }, [audioTraceIdToSubscribe]);

  useEffect(() => {
    const blocks = audioToblTrace?.blocks;

    if (!blocks) return;

    const resultBlock = Object.values(blocks)
      .find(block => block.isResultBlock);

    if (!resultBlock) return;
    if (!isLoading) return;

    if (!resultBlock.preview) {
      return;
    }

    setPromptText((p: string | undefined) => {
      let output = ""
      if (p) {
        output = p + "\n"
      }
      return output + resultBlock.preview
    });

    setIsLoading(false);
  }, [audioToblTrace]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
      gap={5}
    >
      <TextField
        multiline
        minRows={4}
        maxRows={100}
        fullWidth
        placeholder={app.description}
        value={promptText}
        variant="outlined"
        sx={{
          flexGrow: 1,
          border: '1px solid #E0E0E0',
        }}
        onChange={(e) => setPromptText(e.target.value)}
      />

      <Box
        height={'30px'}
      >
        {isLoading ?
          <CircularProgress/> :
          <VoiceRecorder
            setIsLoading={setIsLoading}
            groupId={app.groupId}
            setAudioUrl={setAudioUrl}
          />
        }
      </Box>
    </Box>
  );
};

export default VoiceInputComponent;
