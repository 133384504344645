import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {
  verifyInvite,
  VerifyInviteRequest,
  VerifyInviteResponse
} from '../../cloud_functions/invite';
import {LoginView} from "../app/LoginView";
import {useGlobalContext} from "../../hooks/GlobalContext";

export function InviteHandler() {
  const {token} = useParams();
  const navigate = useNavigate();
  const [verificationMessage, setVerificationMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const user = useGlobalContext().user;

  useEffect(() => {
    if (!user) {
      return;
    }
    const verifyToken = async () => {
      if (!token) {
        setError('No token provided');
        return;
      }
      try {
        const request: VerifyInviteRequest = {token};
        const response: VerifyInviteResponse = await verifyInvite(request);
        setVerificationMessage(response.message);

        // Navigate to /app after successful verification
        setTimeout(() => {
          navigate('/admin');
        }, 2000); // 2000 milliseconds = 2 seconds
      } catch (err) {
        setError(`Failed to verify token: ${err}`);
      }
    };

    verifyToken();
  }, [token, navigate, user]); // Include navigate as a dependency

  if (verificationMessage) {
    return <div>{verificationMessage}</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return <LoginView/>;
}
