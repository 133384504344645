import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  ListItemText,
  MenuItem,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import {doc, setDoc} from "firebase/firestore";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable
} from "firebase/storage";
import {v4 as uuidv4} from "uuid";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from '@mui/icons-material/Settings';
import {useGlobalContext} from "../../hooks/GlobalContext";
import {SchemaUser} from "tobl-data-schema/dist/schema/user";
import {COLLECTION_USERS} from "../../models/user";
import {useTranslation} from "react-i18next";

export default function EditProfileButton() {
  const theme = useTheme();
  const [isKeyDialogOpen, setKeyDialogOpen] = useState(false);
  const [userData, setUserData] = useState<SchemaUser>();
  const init = useGlobalContext();
  const user = init.user;
  const userInfo = init.userInfo;
  const [profileImage, setProfileImage] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const {t} = useTranslation();

  useEffect(() => {
    setUserData(userInfo);
    if (userInfo?.photoURL) {
      setProfileImage(userInfo?.photoURL);
    }
  }, [userInfo]);

  const handleSave = async () => {
    if (user) {
      await setDoc(doc(COLLECTION_USERS, user.uid), {
        ...userData,
        photoURL: profileImage
      });
    }
    setKeyDialogOpen(false);
  };

  const handleImageUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file || !user) return;

    // Show local preview
    const localURL = URL.createObjectURL(file);
    setProfileImage(localURL);

    const storage = getStorage();
    const storagePath = `users/${user.uid}/${uuidv4()}-${file.name}`;
    const storageRef = ref(storage, storagePath);

    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      'state_changed',
      null,
      (error) => {
        console.error(error);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        setProfileImage(downloadURL); // Set the permanent URL after successful upload
      }
    );
  };


  if (!userData) {
    return null;
  }

  return (
    <>
      <MenuItem onClick={() => setKeyDialogOpen(true)}>
        <ListItemText>
          {t('edit_profile')}
        </ListItemText>
      </MenuItem>
      <Dialog
        open={isKeyDialogOpen}
        onClose={() => setKeyDialogOpen(false)}
        fullWidth
        maxWidth={'sm'}
        fullScreen={fullScreen}
      >
        <DialogTitle>{t("edit_profile")}</DialogTitle>
        <DialogContent>
          <DialogContentText mb={2}>
            {t("please_enter_the_name_you_want_to_use")}
          </DialogContentText>
          <Grid container spacing={3}>
            {/* User Details Section */}
            <Grid item xs={12} sm={6} sx={{alignItems: 'start'}}>
              <Typography variant={'body2'}>{t('name')}</Typography>
              <Paper
                sx={{marginTop: '5px', borderColor: theme.palette.divider}}>
                <TextField
                  required
                  fullWidth
                  autoFocus
                  onKeyDown={e => e.stopPropagation()}
                  onKeyUp={e => e.stopPropagation()}
                  value={userData.displayName}
                  onChange={(e) => setUserData({
                                                 ...userData,
                                                 displayName: e.target.value
                                               })}
                />
              </Paper>
              <Typography variant={'body2'}
                          mt={'20px'}>{t('Email')}</Typography>
              <Paper sx={{
                marginTop: '5px',
                padding: '17px 15px',
                borderColor: theme.palette.divider
              }}>
                <Typography color={theme.palette.text.secondary}>
                  {user?.email}
                </Typography>
              </Paper>
            </Grid>
            {/* Profile Image Section */}
            <Grid item xs={12} sm={6}>
              <Box display="flex" justifyContent="center">
                <Avatar
                  sx={{
                    width: '166px',
                    height: '166px',
                    border: '2px solid',
                    borderColor: theme.palette.divider,
                    backgroundColor: 'transparent',
                    margin: '15px 20px 0px',
                  }}
                >
                  <Avatar src={profileImage || user?.photoURL || ''}
                          sx={{width: '150px', height: '150px'}}/>
                </Avatar>
                <input
                  style={{display: 'none'}}
                  type="file"
                  onChange={handleImageUpload}
                  ref={fileInputRef}
                />
                <label style={{position: 'absolute', right: '20px'}}>
                  <IconButton color="primary" component="span">
                    <SettingsIcon/>
                    <input
                      style={{display: 'none'}}
                      type="file"
                      onChange={handleImageUpload}
                      ref={fileInputRef}
                    />
                  </IconButton>
                </label>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setKeyDialogOpen(false)} color="inherit">
            {t("cancel")}
          </Button>
          <Button onClick={handleSave} color="inherit">
            {t("save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
