import React, {useState} from 'react';
import {Box, Paper, Typography, useTheme} from '@mui/material';
import {useNavigate} from "react-router-dom";
import BlockTypeIcon from '../../../views/block/BlockTypeIcon';
import {SchemaBlock} from "tobl-data-schema/dist/schema/block/block";

interface BlockRowProps {
  block: SchemaBlock;
}

const ProjectButton: React.FC<BlockRowProps> = (
  {
    block
  }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  const handleClick = () => {
    navigate(`/admin/${block.id}`);
  };

  return (
    <Paper
      aria-controls={block.id}
      aria-haspopup="true"
      onClick={handleClick}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      style={{
        aspectRatio: '1/1',
        width: '100%',
        height: '100%',
        cursor: 'pointer',
        border: '1px solid',
        borderColor: isHovered ? theme.palette.text.secondary : theme.palette.divider,
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100%"
        fontSize={'3rem'}
      >
        <BlockTypeIcon block={block}/>
        <Typography
          variant="h6"
          textAlign={'center'} pl={1} pr={1} mt={1}
        >
          {block.title}
        </Typography>
      </Box>
    </Paper>
  );
};

export default ProjectButton;
