import React, {useState} from 'react';
import AppAliasDialog from "./AppAliasDialog";
import {Box, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import useGetAppAliasByAppId from "../../../hooks/useGetAppAliasByAppId";

interface GroupAliasMenuItemProps {
  appId: string;
}

const AppAliasMenuItem: React.FC<GroupAliasMenuItemProps> = ({appId}) => {

  const {appAlias, updateAppAlias} = useGetAppAliasByAppId(appId);

  const [open, setOpen] = useState(false);

  const handleClickOpen = (e: React.MouseEvent) => {
    window.open(`/app/${appAlias?.alias || appId}`, '_blank');
    e.stopPropagation();
    setOpen(true);
  };

  const handleSetAlias = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpen(true);
  };

  return (
    <>
      <Box>
        <ListItem>
          <ListItemIcon onClick={handleClickOpen}>
            <OpenInNewIcon fontSize={'small'} sx={{mr: 1}}/>
          </ListItemIcon>
          <ListItemText onClick={handleSetAlias}>
            {`tobl.ai/app/${appAlias?.alias || appId}`}
            {!appAlias?.alias && ' (Set Alias)'}
          </ListItemText>
        </ListItem>
      </Box>
      <AppAliasDialog appId={appId}
                      open={open}
                      onClose={() => {
                        updateAppAlias();
                        setOpen(false)
                      }}
      />
    </>

  );
};

export default AppAliasMenuItem;
