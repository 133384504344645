import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/Delete';
import {useSnackbar} from "../../../hooks/useSnackbar";
import {useBlockTree} from "../../../hooks/useBlockTree";
import {deleteBlock} from "../../../cloud_functions/deleteBlock";
import {SchemaBlock} from "tobl-data-schema/dist/schema/block/block";

interface DeleteBlockMenuItemProps {
  block: SchemaBlock;
  handleClose: () => void;
}

const DeleteBlockMenuItem: React.FC<DeleteBlockMenuItemProps> = ({
                                                                   block,
                                                                   handleClose
                                                                 }) => {
  const {refreshBlockTree} = useBlockTree();
  const {showSnackbar} = useSnackbar();

  const handleDeleteBlock = async () => {
    try {
      showSnackbar("Deleting the block.");

      await deleteBlock({
                          blockId: block.id
                        });
      showSnackbar("Deleted block.");
      refreshBlockTree();
    } catch (error) {
      console.error(error);
      showSnackbar("Failed to delete block.");
    }
  };

  return (
    <MenuItem onClick={() => {
      handleDeleteBlock();
      handleClose();
    }}>
      <DeleteIcon fontSize={'small'} sx={{mr: 1}}/>
      Delete Block
    </MenuItem>
  );
};

export default DeleteBlockMenuItem;
