import React, {useEffect, useState} from 'react';
import {doc, onSnapshot} from "firebase/firestore";
import {db} from "../../../firebase/firebase";
import {parseDoc} from "../../../firebase/firestore/parseDoc";
import {
  Box,
  Chip,
  CircularProgress,
  Dialog,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import {useGetBlock} from "../../../hooks/useGetBlock";
import {
  RunResult,
  RunStatus,
  SchemaBlockRun
} from "tobl-data-schema/dist/schema/block/run/run";
import {getColorByResult} from "../../../theme/colors";
import {useSnackbar} from "../../../hooks/useSnackbar";
import {useTranslation} from "react-i18next";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import BlockTypeIcon from "../BlockTypeIcon";
import {rerunTask} from "../../../cloud_functions/rerunTask";
import DocumentCards from "../document/DocumentCards";
import {RunDetailsTable} from "./RunDetailsTable";
import RefreshIcon from "@mui/icons-material/Refresh";

interface RunDetailsDialogProps {
  onClose: () => void;
  isDiagramOpen: boolean;
  blockId: string;
  runId: string; // <-- added runId as a prop
}

const RunDetailsDialog: React.FC<RunDetailsDialogProps> = (
  {
    onClose,
    isDiagramOpen,
    blockId,
    runId // <-- use runId prop here
  }) => {

  const [run, setRun] = useState<SchemaBlockRun>(); // Moved useState here
  const theme = useTheme();
  const {t} = useTranslation();
  const {block} = useGetBlock(blockId);
  const {showSnackbar} = useSnackbar();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

  useEffect(() => {
    const runDocRef = doc(db, `blocks/${blockId}/runs`, runId);

    const unsubscribe = onSnapshot(runDocRef, (snapshot) => {
      if (snapshot.exists()) {
        const schemaBlockRun = parseDoc<SchemaBlockRun>(snapshot);
        setRun(schemaBlockRun);
      } else {
        console.warn("Run does not exist");
      }
    }, (error) => {
      console.error("Error fetching run:", error);
    });

    return () => {
      unsubscribe();
    };
  }, [blockId, runId]);

  if (!block || !run) {
    return null;
  }

  const chipColorResult = getColorByResult(run?.runResult || RunResult.pending, theme);
  const chipColorStatus = getColorByResult(run?.runStatus || RunResult.pending, theme);

  return (
    <Dialog
      key={block.id}
      open={isDiagramOpen}
      onClose={onClose}
      fullWidth
      maxWidth={"md"}
      fullScreen={fullScreen}
    >
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
          padding: '15px 10px'
        }}
      >
        <IconButton
          color="primary"
          aria-label="close"
          onClick={onClose}
          style={{
            color: theme.palette.text.primary,
            width: '40px',
            height: '40px',
          }}
        >
          <CloseIcon fontSize={'medium'}/>
        </IconButton>
      </Box>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'center',
            padding: '10px 0 60px'
          }}
        >
          <Typography variant={'h4'} margin={'0px 20px 15px'}>
            {block.title}
          </Typography>
          <Typography
            variant="subtitle1"
            mb={2}
            color={theme.palette.text.secondary}
          >
            <BlockTypeIcon block={block}/>{" " + t(block.blockType)}
          </Typography>
          <Box mb={10}>
            {run.runStatus === RunStatus.finished ? (
              <Chip
                label={run.runStatus.toUpperCase()}
                style={{
                  backgroundColor: chipColorStatus,
                  margin: '0px 5px',
                  color: theme.palette.background.default
                }}
              />
            ) : (
              <CircularProgress variant="determinate"
                                value={0.5}
                                style={{
                                  width: 20,
                                  height: 20,
                                  margin: '0 20px'
                                }}/>
            )}
            <Chip
              // label={run.runResult.toUpperCase()}
              label={run.runResult.toUpperCase()}
              style={{
                backgroundColor: chipColorResult,
                margin: '0px 5px',
                color: theme.palette.background.default
              }}
            />
            <IconButton
              color="primary"
              aria-label="rerun"
              onClick={() => {
                showSnackbar("Enqueuing a rerun task.");

                rerunTask({
                            blockId: blockId,
                            runId: run.id
                          }).then(() => {
                  showSnackbar("Successfully enqueued rerun task. The run will be rerun shortly.");
                }).catch((error) => {
                  
                  console.error(error);
                  showSnackbar(`Rerun failed. ${error}`);
                });
                onClose();
              }}
            >
              <RefreshIcon/>
            </IconButton>
          </Box>
          <DocumentCards
            key={run.id}
            blockId={blockId}
            runId={run.id}
          />
          <RunDetailsTable
            blockId={blockId}
            run={run}
            chipColorResult={chipColorResult}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default RunDetailsDialog;
