import React, {useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {Button, DialogTitle, useMediaQuery, useTheme} from "@mui/material";
import {BlockEditForm} from "../edit/BlockEditForm";
import {
  BlockType,
  SchemaBlockEdit
} from "tobl-data-schema/dist/schema/block/block";
import {useTranslation} from "react-i18next";
import {
  BlockDocumentType
} from "tobl-data-schema/dist/schema/block/document/document";
import {BlockEditorContext} from "./BlockEditorContext";
import {BlockEditButtonContext} from "./BlockEditButtonContext";
import EditFormApi from "../edit/EditFormApi";
import {
  SchemaBlock_Team_Config
} from "tobl-data-schema/dist/schema/block/block_type/team";

export enum AddEditMode {
  ADD = 'add',
  EDIT = 'edit',
}

interface Props {
  open: boolean;
  onClose: (e: React.MouseEvent) => void;
  onSave: (e: React.MouseEvent) => void;
  blockData: SchemaBlockEdit;
  parentOutputType?: BlockDocumentType;
  setBlockData: React.Dispatch<React.SetStateAction<SchemaBlockEdit>>;
  addEditMode: AddEditMode;
  isTeamBlock?: boolean;
  buttonContext: BlockEditButtonContext;
  blockId: string
}

export function BlockMenuAddOrEditDialog(
  {
    open,
    onClose,
    onSave,
    blockData,
    parentOutputType,
    setBlockData,
    addEditMode,
    isTeamBlock,
    buttonContext,
    blockId
  }: Props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const {t} = useTranslation();
  const [isSaving, setIsSaving] = React.useState(false);
  const [isSubmitReady, setIsSubmitReady] = useState(true)
  const handleSave = async (e: React.MouseEvent) => {
    setIsSaving(true); // Disable the button
    try {
      await onSave(e); // Assuming onSave is an async operation
    } finally {
      setIsSaving(false); // Re-enable the button after operation
    }
  };

  const updateBlockData = (key: string, value: any) => {
    setBlockData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const updateConfigData = (key: string, value: any) => {
    setBlockData((prevData) => ({
      ...prevData,
      configuration: {
        ...prevData.configuration,
        [key]: value,
      },
    }));
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth={isTeamBlock ? "xs" : "md"}
      fullScreen={fullScreen}
      PaperProps={isTeamBlock ? undefined : {
        style: {
          marginTop: 0,
          marginBottom: 'auto',
          maxHeight: '100%',
        }
      }}
    >
      <DialogTitle>
        {addEditMode === AddEditMode.ADD
          ? t(`${blockData.blockType} Add a Block`)
          : isTeamBlock
            ? buttonContext === BlockEditButtonContext.API_KEY_CHANGE
              ? t("AI Key")
              : t('Change Name')
            : t('Edit Block')}
      </DialogTitle>
      <DialogContent
        onKeyDown={e => {
          e.stopPropagation();
        }}
        onKeyUp={e => e.stopPropagation()}
      >
        {blockData.blockType === BlockType.team && buttonContext === BlockEditButtonContext.API_KEY_CHANGE
          ? <EditFormApi
            configuration={blockData.configuration as SchemaBlock_Team_Config}
            updateConfigData={updateConfigData}
            teamBlockId={blockId}
          />
          : <BlockEditorContext.Provider
            value={{
              updateConfigData,
              isSubmitReady,
              setIsSubmitReady,
              addEditMode,
              blockId,
              blockData
            }}
          >
            <BlockEditForm
              parentOutputType={parentOutputType}
              updateBlockData={updateBlockData}
            />
          </BlockEditorContext.Provider>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">
          {t('cancel')}
        </Button>
        <Button onClick={handleSave}
                color="inherit"
                disabled={!isSubmitReady || isSaving}
        >
          {addEditMode === AddEditMode.ADD ? t('Add') : t('Edit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
