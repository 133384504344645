import React, {useEffect, useMemo} from 'react';
import ReactFlow, {
  Background,
  BackgroundVariant,
  Controls,
  Edge,
  MarkerType,
  Node,
  Position,
  useEdgesState,
  useNodesState
} from 'reactflow';
import 'reactflow/dist/style.css';
import {
  TreeNode
} from "../../components/block/projects/documents/block_tree_map/BlockTreeMapView";
import {
  getChildrenBlockIdsFromTree,
  useBlockTree
} from "../../hooks/useBlockTree";
import {
  TypeBlockLookup,
  TypeBlockTree
} from "tobl-data-schema/dist/api/block/block/getBlockTree";
import BlockTreeItem from "./BlockTreeItem";
import {useMediaQuery} from "@mui/material";

interface BlockNodeData {
  blockId: string;
}

const HORIZONTAL_SPACING = 300;
const VERTICAL_SPACING = 130;

const convertBlockToFlowNode = (
  node: TreeNode,
  parentNodeId: string | null = null,
  depth = 0,
  yOffset = 0
): { nodes: Node<BlockNodeData>[], edges: Edge[], subtreeHeight: number } => {
  const nodes: Node<BlockNodeData>[] = [];
  const edges: Edge[] = [];

  const nodeId = `node-${node.blockId}`;
  const xPosition = depth * HORIZONTAL_SPACING;
  const yPosition = yOffset;

  const nodeData: Node<BlockNodeData> = {
    id: nodeId,
    type: 'customNode', // 또는 다른 노드 타입
    data: {blockId: node.blockId},
    position: {x: xPosition, y: yPosition},
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
  };
  nodes.push(nodeData);

  if (parentNodeId) {
    const edge: Edge = {
      id: `e${parentNodeId}-${nodeId}`,
      source: parentNodeId,
      target: nodeId,
      markerEnd: MarkerType.Arrow,
    };
    edges.push(edge);
  }

  let accumulatedHeight = 0;
  node.children.forEach(child => {
    const childYOffset = yOffset + accumulatedHeight;
    const {
      nodes: childNodes,
      edges: childEdges,
      subtreeHeight: childSubtreeHeight,
    } = convertBlockToFlowNode(child, nodeId, depth + 1, childYOffset);

    nodes.push(...childNodes);
    edges.push(...childEdges);

    accumulatedHeight += childSubtreeHeight;
  });

  const subtreeHeight = Math.max(VERTICAL_SPACING, accumulatedHeight + VERTICAL_SPACING);

  return {nodes, edges, subtreeHeight};
};

interface BlockTreeManageViewProps {
  blockId: string; // blockId를 prop으로 받습니다.
}

const ManageBlockTreeView: React.FC<BlockTreeManageViewProps> = ({blockId}) => {
  const {blockLookup, blockTree} = useBlockTree();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const isHeightSmall = useMediaQuery('(max-height:1024px)');

  // blockToTreeNode을 ManageBlockTreeView 컴포넌트 내부로 이동
  const blockToTreeNode = (blockId: string, blockLookup: TypeBlockLookup, blockTree: TypeBlockTree): TreeNode | null => {
    if (!blockLookup || !blockTree) {
      return null;
    }

    const block = blockLookup[blockId];
    if (!block) {
      return null;
    }

    const childrenBlockIds = getChildrenBlockIdsFromTree(blockTree, blockId);
    const children = childrenBlockIds.map(childBlockId => blockToTreeNode(childBlockId, blockLookup, blockTree)).filter(Boolean) as TreeNode[];

    return {
      blockId: block.id,
      runId: block.id, // 이 부분은 필요에 따라 수정될 수 있습니다.
      children
    };
  };

  useEffect(() => {
    if (blockLookup && blockTree) {
      const treeNode = blockToTreeNode(blockId, blockLookup, blockTree);
      if (treeNode) {
        const {nodes, edges} = convertBlockToFlowNode(treeNode);
        setNodes(nodes);
        setEdges(edges);
      }
    }
  }, [blockId, blockLookup, blockTree]);

  const nodeTypes = useMemo(() => ({customNode: BlockTreeItem}), []);

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      nodeTypes={nodeTypes}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      fitView
    >
      <Controls/>
      <Background variant={BackgroundVariant.Dots} gap={20} size={1}/>
    </ReactFlow>
  );
};

export default ManageBlockTreeView;
