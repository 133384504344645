import React from "react";
import BlockTreeMapForDocument
  from "../../../components/block/projects/documents/block_tree_map/BlockTreeMapForDocument";
import {SchemaBlockRun} from "tobl-data-schema/dist/schema/block/run/run";
import {Box, Typography, useTheme} from "@mui/material";

export function RunDetailsTable({
                                  blockId,
                                  run,
                                  chipColorResult
                                }: {
  blockId: string,
  run: SchemaBlockRun,
  chipColorResult: string
}) {

  return (
    <>
      <DetailEntry title="Traces">
        <Box margin={'10px 0px 0px'}>
          <BlockTreeMapForDocument blockId={blockId} traceId={run.traceId}/>
        </Box>
      </DetailEntry>

      {run.errorMessages && run.errorMessages.length > 0 &&
          <DetailEntry title="Error Messages" textColor={chipColorResult}>
            {run.errorMessages.join('\n')}
          </DetailEntry>
      }

      <DetailEntry title="Updated At">
        {run.updatedAt.toDate().toLocaleString()}
      </DetailEntry>

      <DetailEntry title="Created At">
        {run.createdAt.toDate().toLocaleString()}
      </DetailEntry>

      <DetailEntry title="Trace ID">
        {run.traceId}
      </DetailEntry>

      <DetailEntry title="Block ID">
        {blockId}
      </DetailEntry>

      <DetailEntry title="Run ID">
        {run.id}
      </DetailEntry>
    </>
  );
}

function DetailEntry({title, children, textColor}: {
  title: string,
  children: React.ReactNode,
  textColor?: string
}) {
  const theme = useTheme();
  return (
    <Box
      mt={3}
      mb={2}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        wordBreak: 'break-word'
      }}
    >
      <Typography
        variant={'h6'}
        mb={1}
        component="div"
        color={theme.palette.primary.main}
      >
        {title.toUpperCase()}
      </Typography>
      <Typography variant={'body1'} component="div" textAlign={'center'}
                  style={{color: textColor}}>
        {children}
      </Typography>
    </Box>
  );
}
