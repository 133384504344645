import React, {useState} from 'react';
import {Box, Button, Grid} from '@mui/material';
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import ContactView from "../../views/app/ContactView";
import {useGlobalContext} from "../../hooks/GlobalContext";
import BlockPartialEditor from '../block/menu/BlockPartialEditor';
import {AddEditMode} from "../block/menu/BlockMenuAddOrEditDialog";
import {
  BlockType,
  TOP_LEVEL_BLOCK_ID
} from "tobl-data-schema/dist/schema/block/block";
import HomePricingBox from './HomePricingBox';
import {HomePricingYearlyButton} from './HomePricingYearlyButton';
import {BlockEditButtonContext} from "../block/menu/BlockEditButtonContext";

const plans = [
  {
    title: "free.title",
    price: 0,
    buttonLabel: "free.buttonLabel",
    features: [
      "✔ Create one project block",
      "✔ Total of five blocks available",
      "✔ Add unlimited collaborators"
    ]
  },
  // {
  //   title: "standard.title",
  //   price: "standard.price",
  //   buttonLabel: "standard.buttonLabel",
  //   features: [
  //     "✔ Create one team block",
  //     "✔ Create up to three projects",
  //     "✔ Unlimited blocks for $20 each",
  //     "✔ Add unlimited collaborators"
  //   ]
  // },
  {
    title: "plus.title",
    price: 400,
    buttonLabel: "plus.buttonLabel",
    features: [
      "✔ Unlimited project creation",
      "✔ Unlimited block creation",
      "✔ Add unlimited collaborators"
    ]
  },
  {
    title: "enterprise.title",
    buttonLabel: "enterprise.buttonLabel",
    features: [
      "✔ Unlimited project creation",
      "✔ Unlimited block creation",
      "✔ Add unlimited collaborators",
      "✔ AI automation consulting",
      "✔ Custom blocktree support",
      "✔ Novel block creation"
    ]
  }
];


const HomePricing: React.FC = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const [isContactOpen, setIsContactOpen] = useState(false);
  const {user} = useGlobalContext();
  const [isYearly, setIsYearly] = useState(false);

  return (
    <>
      <Box padding={'0px 20px'}>
        <HomePricingYearlyButton isYearly={isYearly} setIsYearly={setIsYearly}/>
      </Box>
      <Grid container spacing={3} padding={'20px'} mb={'60px'}>
        {plans.map((plan) => (
          <Grid item md={4} sm={12} xs={12} key={plan.title}>
            <HomePricingBox
              title={plan.title}
              price={plan.price}
              features={plan.features}
              isYearly={isYearly}
              height={'550px'}
              button={plan.title === "free.title" && user
                ? (
                  <BlockPartialEditor
                    addEditMode={AddEditMode.ADD}
                    blockType={BlockType.team}
                    blockId={TOP_LEVEL_BLOCK_ID}
                    buttonContext={BlockEditButtonContext.TITLE_CHANGE}
                  >
                    <Button
                      variant="outlined"
                      size={'small'}
                      sx={{
                        padding: '1px 8px 2px',
                      }}
                    >
                      {t(plan.buttonLabel) + ' ↗'}
                    </Button>
                  </BlockPartialEditor>
                ) : (
                  <Button
                    variant="outlined"
                    size={'small'}
                    sx={{
                      padding: '1px 8px 2px',
                    }}
                    onClick={() => {
                      if (plan.title === "free.title") {
                        navigate(`/login`)
                      } else {
                        setIsContactOpen(true);
                      }
                    }}>
                    {t(plan.buttonLabel) + ' ↗'}
                  </Button>
                )}
            />
          </Grid>
        ))}
      </Grid>
      <ContactView
        isOpen={isContactOpen}
        onClose={() => {
          setIsContactOpen(false);
        }}
      />
    </>
  );
};

export default HomePricing;
