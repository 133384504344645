import React, {useEffect} from 'react';
import TextField from "@mui/material/TextField";
import {useTranslation} from "react-i18next";
import {
  SchemaBlock_Processor_Config_GoogleDocumentAi
} from "tobl-data-schema/dist/schema/block/block_type/processor";
import {BlockEditFormRow} from "./BlockEditFormRow";

const DEFAULT_GOOGLE_PROJECT_ID = '60655372156';
const DEFAULT_GOOGLE_LOCATION = 'us';
const DEFAULT_GOOGLE_PROCESSOR_ID = '2e88e9fcb1fbe7f7';

export default function EditFormProcessorAiDocument(
  {
    configuration,
    updateConfigData,
  }: {
    configuration: SchemaBlock_Processor_Config_GoogleDocumentAi,
    updateConfigData: (key: string, value: any) => void
  }) {
  const {t} = useTranslation();
  // Set default values if fields are empty
  useEffect(() => {
    if (!configuration.googleProjectId) {
      updateConfigData('googleProjectId', DEFAULT_GOOGLE_PROJECT_ID);
    }
    if (!configuration.googleLocation) {
      updateConfigData('googleLocation', DEFAULT_GOOGLE_LOCATION);
    }
    if (!configuration.googleProcessorId) {
      updateConfigData('googleProcessorId', DEFAULT_GOOGLE_PROCESSOR_ID);
    }
  }, [configuration, updateConfigData]);

  return (
    <>
      <BlockEditFormRow label={t("Google Project ID")}>
        <TextField
          onKeyDown={e => e.stopPropagation()}
          onKeyUp={e => e.stopPropagation()}
          value={configuration.googleProjectId || DEFAULT_GOOGLE_PROJECT_ID}
          onChange={(e) =>
            updateConfigData('googleProjectId', e.target.value)}
          fullWidth
          onClick={e => e.stopPropagation()}
        />
      </BlockEditFormRow>

      <BlockEditFormRow label={t("Google Location")}>
        <TextField
          onKeyDown={e => e.stopPropagation()}
          onKeyUp={e => e.stopPropagation()}
          value={configuration.googleLocation || DEFAULT_GOOGLE_LOCATION}
          onChange={(e) =>
            updateConfigData('googleLocation', e.target.value)}
          fullWidth
          onClick={e => e.stopPropagation()}
        />
      </BlockEditFormRow>

      <BlockEditFormRow label={t("Google Processor ID")}>
        <TextField
          onKeyDown={e => e.stopPropagation()}
          onKeyUp={e => e.stopPropagation()}
          value={configuration.googleProcessorId || DEFAULT_GOOGLE_PROCESSOR_ID}
          onChange={(e) =>
            updateConfigData('googleProcessorId', e.target.value)}
          fullWidth
          onClick={e => e.stopPropagation()}
        />
      </BlockEditFormRow>
    </>
  );
}
