import React, {useEffect} from "react";
import EditFormProcessorAiText from "./EditFormProcessorAiText";
import EditFormProcessorAiDocument from "./EditFormProcessorAiDocument";
import {
  BlockType_Processor,
  SchemaBlock_Processor_Config_AudioAi,
  SchemaBlock_Processor_Config_GoogleDocumentAi,
} from "tobl-data-schema/dist/schema/block/block_type/processor";
import {TextAiConfigs} from "./TextAiConfigs";

export function EditFormProcessorAi({
                                      configuration,
                                      updateConfigData,
                                    }: {
  configuration: TextAiConfigs | SchemaBlock_Processor_Config_GoogleDocumentAi | SchemaBlock_Processor_Config_AudioAi,
  updateConfigData: (key: string, value: any) => void
}) {
  useEffect(() => {
    if (!configuration.blockType_Processor) {
      updateConfigData('BlockType_Processor', BlockType_Processor.text_to_text_ai);
    }
  }, [configuration, updateConfigData]);

  return (
    <>
      {(configuration.blockType_Processor === BlockType_Processor.text_to_json_ai ||
        configuration.blockType_Processor === BlockType_Processor.text_to_text_ai ||
        configuration.blockType_Processor === BlockType_Processor.texts_to_text_ai ||
        configuration.blockType_Processor === BlockType_Processor.search_google_with_ai ||
        configuration.blockType_Processor === BlockType_Processor.api_google_news ||
        configuration.blockType_Processor === BlockType_Processor.api_google_news_multi ||
        configuration.blockType_Processor === BlockType_Processor.search_wikipedia_with_ai
      ) && (
        <EditFormProcessorAiText configuration={configuration}
                                 updateConfigData={updateConfigData}/>
      )}


      {configuration.blockType_Processor === BlockType_Processor.google_document_ai && (
        <EditFormProcessorAiDocument configuration={configuration}
                                     updateConfigData={updateConfigData}/>
      )}
    </>
  );
}
