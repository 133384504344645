import React from 'react';
import {SchemaAppCreate, SchemaAppEmailSender} from "../../../models/apps";
import InputBlockSelector from "./InputBlockSelector";

export default function AppFormVoiceInput({app, setApp}: {
  app: Partial<SchemaAppEmailSender>
  setApp: React.Dispatch<React.SetStateAction<Partial<SchemaAppCreate>>>
}) {

  return (
    <>
      <InputBlockSelector
        label={'Voice Processing Input Block'}
        value={app.voiceRecognitionBlockId || ""}
        onUpdate={(voiceInputBlockId) => {
          setApp((p) => ({
            ...p,
            voiceRecognitionBlockId: voiceInputBlockId
          }))
        }}
      />
      <InputBlockSelector
        label={'Email Sender/Recipient Input Block'}
        value={app.senderReceiptProcessingBlockId || ""}
        onUpdate={(blocKId) => {
          setApp((p) => ({
            ...p,
            senderReceiptProcessingBlockId: blocKId
          }))
        }}
      />
      <InputBlockSelector
        label={'Email Content Input Block'}
        value={app.contentProcessingBlockId || ""}
        onUpdate={(blocKId) => {
          setApp((p) => ({
            ...p,
            contentProcessingBlockId: blocKId
          }))
        }}
      />


    </>
  );
}
