import * as React from 'react';
import {createTheme, Theme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {GRAY, GREEN} from './colors';
import {typography} from "./typography";
import {useThemeContext} from "./ThemeContextProvider";

type ThemeWrapperProps = {
  children: React.ReactNode;
};

const borderRadius = '0px';

const ThemeWrapper: React.FC<ThemeWrapperProps> = ({children}) => {
  const {mode} = useThemeContext();
  const isDarkMode = mode === 'dark' || (mode === 'auto' && window.matchMedia('(prefers-color-scheme: dark)').matches);

  const themeData: Theme = React.useMemo(
    () =>
      createTheme(
        {
          typography,
          palette: {
            mode: isDarkMode ? 'dark' : 'light',
            primary: {
              light: isDarkMode ? GREEN.w700 : GREEN.w500,
              main: isDarkMode ? GREEN.w600 : GREEN.w600,
              dark: isDarkMode ? GREEN.w500 : GREEN.w700,
            },
            text: {
              primary: isDarkMode ? GRAY.w200 : GRAY.w900,
              secondary: isDarkMode ? GRAY.w500 : GRAY.w500,
              disabled: isDarkMode ? GRAY.w700 : GRAY.w100,
            },
            secondary: {
              light: isDarkMode ? GRAY.w700 : GRAY.w200,
              main: isDarkMode ? GRAY.w900 : GRAY.w100,
              dark: isDarkMode ? GRAY.w500 : GRAY.w700,
            },
            background: {
              default: isDarkMode ? GRAY.w800 : GRAY.w100,
              paper: isDarkMode ? GRAY.w700 : GRAY.w100,
            },
          },
          components: {
            MuiButton: {
              styleOverrides: {
                root: {
                  textTransform: 'none',
                  fontWeight: 500,
                  fontSize: '1rem',
                  borderRadius: borderRadius,
                  padding: '8px 25px',
                  boxShadow: 'none',
                },
                contained: {
                  boxShadow: 'none',
                  color: isDarkMode ? GRAY.w900 : GRAY.w100,
                  border: '1px solid',
                  borderColor: isDarkMode ? GRAY.w100 : GRAY.w700,
                  backgroundColor: isDarkMode ? GRAY.w100 : GRAY.w700,
                  '&:hover': {
                    color: isDarkMode ? GREEN.w900 : GREEN.w100,
                    border: '1px solid',
                    borderColor: isDarkMode ? GREEN.w100 : GREEN.w700,
                    backgroundColor: isDarkMode ? GREEN.w100 : GREEN.w700,
                  },
                },
                outlined: {
                  boxShadow: 'none',
                  color: isDarkMode ? GRAY.w100 : GRAY.w700,
                  border: '1px solid',
                  borderColor: isDarkMode ? GRAY.w100 : GRAY.w700,
                  backgroundColor: 'transparent',
                  '&:hover': {
                    color: isDarkMode ? GREEN.w100 : GREEN.w700,
                    border: '1px solid',
                    borderColor: isDarkMode ? GRAY.w100 : GRAY.w700,
                    backgroundColor: isDarkMode ? GRAY.w100 : GRAY.w700,
                  }
                },
                sizeSmall: {
                  padding: '5px 10px',
                },
                sizeLarge: {
                  padding: '20px 40px',
                  fontSize: '1.5rem',
                  width: '100%'
                }
              },
              defaultProps: {
                disableElevation: true,
                size: 'medium',
              },
            },
            MuiTypography: {
              styleOverrides: {
                root: {
                  color: isDarkMode ? GRAY.w100 : GRAY.w700,
                  wordBreak: 'keep-all'
                },
              },
            },
            MuiIconButton: {
              styleOverrides: {
                root: {
                  color: isDarkMode ? GRAY.w100 : GRAY.w700,
                },
              },
            },
            MuiAppBar: {
              styleOverrides: {
                root: {
                  boxShadow: 'none',
                },
              },
            },
            MuiCheckbox: {
              styleOverrides: {
                root: {
                  transform: 'scale(1.1)',
                },
              },
            },
            MuiChip: {
              styleOverrides: {
                root: {
                  borderRadius: 1,
                  fontSize: '0.75rem',
                  height: '22px',
                },
              },
            },
            MuiMenu: {
              styleOverrides: {
                paper: {
                  borderRadius: borderRadius,
                  border: 'none',
                  padding: '0px',
                  boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.5)',
                },
              },
            },
            MuiMenuItem: {
              styleOverrides: {
                root: {
                  padding: '10px 15px',
                  margin: '5px 11px',
                  borderRadius: borderRadius,
                },
              },
            },
            MuiInput: {
              styleOverrides: {
                underline: {
                  '&:before': {
                    borderBottom: 'none',
                  },
                  '&:hover:not(.Mui-disabled):before': {
                    borderBottom: 'none',
                  },
                },
              },
            },
            MuiOutlinedInput: {
              styleOverrides: {
                root: {
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                },
              },
            },
            MuiCard: {
              styleOverrides: {
                root: {
                  background: isDarkMode ? GRAY.w800 : GRAY.w100,
                  boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.5)',
                  border: 'none',
                },
              },
            },
            MuiPaper: {
              styleOverrides: {
                root: {
                  margin: 0,
                  borderRadius: borderRadius,
                  background: isDarkMode ? GRAY.w700 : GRAY.w100,
                  border: '1px solid',
                  boxShadow: 'none'
                },
              },
            },
            MuiDialog: {
              styleOverrides: {
                paper: {
                  background: isDarkMode ? GRAY.w800 : GRAY.w100,
                  border: 'none',
                  borderRadius: borderRadius,
                }
              }
            },
            MuiDialogTitle: {
              defaultProps: {
                variant: 'h5',
              },
            },
            MuiDialogContentText: {
              defaultProps: {
                variant: 'body2',
              },
            },
          },
          shape: {
            borderRadius: 2,
          },
        }),
    [isDarkMode]
  );

  return (
    <ThemeProvider theme={themeData}>
      <CssBaseline/>
      {children}
    </ThemeProvider>
  );
};

export default ThemeWrapper;
