import React, {useEffect, useMemo, useState} from 'react';
import {Box, Tooltip, Typography, useMediaQuery, useTheme} from '@mui/material';
import {BlockType, SchemaBlock} from "tobl-data-schema/dist/schema/block/block";
import TeamView from "../../components/block/team/TeamView";
import {
  BlockType_Project
} from "tobl-data-schema/dist/schema/block/block_type/project";
import DocumentsView
  from "../../components/block/projects/documents/DocumentsView";
import {useBlockTree} from "../../hooks/useBlockTree";
import AddDocumentButton
  from "../../components/block/projects/documents/AddDocumentButton";
import useFindTriggerBlock from "../../hooks/useFindTriggerBlock";
import {
  TypeBlockTree
} from "tobl-data-schema/dist/api/block/block/getBlockTree";
import MessageNoBlocks
  from "../../components/block/projects/documents/MessageNoBlocks";
import TitleChangeDialog from "./TitleChangeDialog";
import {doc, updateDoc} from "firebase/firestore";
import {COLLECTION_BLOCKS} from "../../models/block/block";
import ProjectBlockContextMenu from "./settings/ProjectBlockContextMenu";
import Dialog from "@mui/material/Dialog";
import ManageBlockModal from "../../components/block/projects/ManageBlockModal";

// 별도의 함수로 switch문을 처리합니다.
function renderProjectView(block: SchemaBlock) {
  if (block.blockType !== BlockType.project) return null;
  const containerBlock = block.configuration;
  switch (containerBlock.blockType_Project) {
    case BlockType_Project.documents:
      return <DocumentsView projectBlock={block} key={block.id}/>;
    default:
      return <DocumentsView projectBlock={block} key={block.id}/>;
  }
}

const hasNoBlocks = (blockTree: TypeBlockTree, parentBlockId?: string): boolean => {
  if (!parentBlockId || !blockTree[parentBlockId] || !blockTree) {
    return false;
  }
  const children = blockTree[parentBlockId];

  return Object.keys(children).length === 0;
};

const BlockView: React.FC<{ blockId: string }> = ({blockId}) => {
  const theme = useTheme();
  const {blockLookup} = useBlockTree();
  const triggerBlock = useFindTriggerBlock(blockId);
  const {blockTree} = useBlockTree();
  const [blockMapOpen, setBlockMapOpen] = useState(false);
  const [titleDialogOpen, setTitleDialogOpen] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const noBlocks = useMemo(
    () => hasNoBlocks(blockTree as TypeBlockTree, blockId),
    [blockTree, blockId]
  );

  const [title, setTitle] = useState("");

  useEffect(() => {
    if (blockLookup && blockLookup[blockId]) {
      setTitle(blockLookup[blockId].title);
    }
  }, [blockLookup, blockId]);

  const updateTitleInFirestore = async (newTitle: string) => {
    try {
      // Assuming 'blocks' is your Firestore collection and 'blockId' is the id of the block
      updateDoc(
        doc(
          COLLECTION_BLOCKS,
          blockId
        ),
        {
          title: newTitle
        }
      )
      setTitle(newTitle);
    } catch (error) {
      console.error("Error updating title: ", error);
    }
  };


  if (!blockLookup) {
    return null;
  }

  const block = blockLookup && blockLookup[blockId];

  const handleClickOpen = () => {
    setTitleDialogOpen(true);
  };

  const handleClose = (newTitle: string | undefined) => {
    if (newTitle) {
      updateTitleInFirestore(newTitle);
    }
    setTitleDialogOpen(false);
  };

  if (!block) {
    return null;
  }

  if (block.blockType === BlockType.team) {
    return <Box
      maxWidth={1280}
      margin="auto"
      padding={fullScreen ? '0 25px' : '0'}
    >
      <TeamView block={block} key={block.id}/>
    </Box>;
  }

  if (block.blockType === BlockType.project) {
    return (
      <>
        <Box
          maxWidth={1280}
          margin="auto"
          padding={fullScreen ? '0 25px' : '0'}
        >
          <Box padding="0px 20px 40px 20px">
            <Box
              maxWidth={1280}
              display="flex"
              alignItems={'center'}
              mt={1}
              pb={1}
              mr={'2px'}
            >
              <Typography variant={'h3'} width={'100%'}
                          onClick={handleClickOpen}
                          sx={{cursor: 'pointer'}}>
                {title || "Loading..."}
              </Typography>
              <TitleChangeDialog
                open={titleDialogOpen}
                currentTitle={title}
                handleClose={handleClose}
              />
              {triggerBlock && <Box mr={1}>
                  <Tooltip
                      title={`Add Document`}>
                      <AddDocumentButton block={triggerBlock}/>
                  </Tooltip>
              </Box>}
              <ProjectBlockContextMenu block={block}/>
            </Box>
            {noBlocks
              ? <MessageNoBlocks open={blockMapOpen} setOpen={setBlockMapOpen}/>
              : renderProjectView(block)}
            {blockMapOpen &&
                <Dialog open={blockMapOpen}
                        onClose={() => setBlockMapOpen(false)}
                        fullWidth={true}
                        fullScreen={true}
                        sx={{ // Add custom styles
                          '.MuiDialog-paper': { // Target the paper element inside the dialog
                            width: '90%', // Set the width to 90%
                            height: '90%', // Set the height to 90%
                          },
                        }}
                >
                    <ManageBlockModal block={block} open={blockMapOpen}
                                      setOpen={setBlockMapOpen}/>
                </Dialog>}          </Box>
        </Box>

      </>
    );
  }

  return null;
}

export default BlockView;
