import {useEffect, useState} from 'react';
import {getDocs, query, where} from 'firebase/firestore';
import {APP_ALIAS_COLLECTION, SchemaAppAlias} from "../models/app_alias";
import {getDocsFromSnapshot} from "../firebase/firestore/getDocsFromSnapshot";

const useGetAppAliasByAppId = (appId: string) => {
  const [appAlias, setAppAlias] = useState<SchemaAppAlias>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<unknown>();

  const [update, setUpdate] = useState(false)

  const updateAppAlias = () => {
    setUpdate(!update)
  }

  useEffect(() => {
    const fetchGroupAlias = async () => {
      try {
        const docRef = query(
          APP_ALIAS_COLLECTION,
          where("appId", "==", appId)
        );
        const appAlias = await getDocs(docRef).then(getDocsFromSnapshot<SchemaAppAlias>)

        if (appAlias) {
          setAppAlias(appAlias[0]);
        } else {
          console.log("No such group alias!");
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchGroupAlias();
  }, [appId, update]);

  return {appAlias, loading, error, updateAppAlias};
};

export default useGetAppAliasByAppId;
