import {db} from "../firebase/firebase";
import {collection} from "firebase/firestore";

export const TABLE_MAIL = "mail";
export const COLLECTION_MAIL = collection(db, TABLE_MAIL);

export interface SchemaMail {
  from?: string;
  replyTo?: string;
  to: string | string[];
  cc?: string | string[];
  bcc?: string | string[];
  headers?: { [key: string]: string };
  message: {
    messageId?: string;
    subject: string;
    text: string;
    html: string;
    amp?: string;
    attachments?: Array<{
      filename: string;
      path: string;
      cid: string;
    }>;
  };
}
