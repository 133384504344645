import React from "react";
import {
  BlockType_Processor
} from "tobl-data-schema/dist/schema/block/block_type/processor";
import {useTranslation} from "react-i18next";
import {
  Box,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import BlockTypeButton from "./BlockTypeButton";
import {EmojiForBlockType} from "../../../views/block/BlockTypeIcon";
import {BlockType} from "tobl-data-schema/dist/schema/block/block";
import {
  BlockType_Input
} from "tobl-data-schema/dist/schema/block/block_type/input";
import {
  BlockType_Project
} from "tobl-data-schema/dist/schema/block/block_type/project";
import CloseIcon from "@mui/icons-material/Close";
import {
  BlockDocumentType
} from "tobl-data-schema/dist/schema/block/document/document";
import {
  BLOCK_TYPE_TO_INPUT_OUTPUT_TYPE
} from "tobl-data-schema/dist/schema/block/block_type/blockTypeToInputOutputType";
import {BlockEditFormRow} from "./BlockEditFormRow";

interface Props {
  selectedType: string;
  updateConfigData: (key: string, value: any) => void;
  blockType: BlockType;
  parentOutputType?: BlockDocumentType;
}

export function BlockTypeSelector(
  {
    selectedType,
    updateConfigData,
    blockType,
    parentOutputType
  }: Props) {
  const theme = useTheme();
  const {t} = useTranslation();
  const [open, setOpen] = React.useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [blockTypeField, setBlockTypeField] = React.useState('blockType_Processor');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (blockType === BlockType.processor) {
      setBlockTypeField('blockType_Processor');
    } else if (blockType === BlockType.input) {
      setBlockTypeField('blockType_Input');
    } else if (blockType === BlockType.project) {
      setBlockTypeField('blockType_Project');
    }
  }, [blockType]);

  const getFilteredBlockTypes = () => {
    if (blockType === BlockType.processor) {
      const filteredTypes = Object.values(BlockType_Processor).filter(
        (type) => BLOCK_TYPE_TO_INPUT_OUTPUT_TYPE[type].inputType === parentOutputType
      );

      if ((parentOutputType === BlockDocumentType.text_document ||
          parentOutputType === BlockDocumentType.text_documents ||
          parentOutputType === BlockDocumentType.json_document) &&
        !filteredTypes.includes(BlockType_Processor.text_merger)) {
        filteredTypes.unshift(BlockType_Processor.text_merger);
      }

      return filteredTypes;
    } else if (blockType === BlockType.input) {
      return Object.values(BlockType_Input).toSorted();
    } else if (blockType === BlockType.project) {
      return Object.values(BlockType_Project).toSorted();
    } else {
      return [];
    }
  };

  return (
    <>
      <BlockEditFormRow key="blockType" label={blockTypeField}>
        <Box onClick={handleOpen} padding={'15px'}>
          {selectedType && EmojiForBlockType(selectedType)} {' '}
          {t(selectedType) || t("Choose Block Type")}
        </Box>
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth
          maxWidth="sm"
          fullScreen={fullScreen}
        >
          <DialogTitle display={'flex'} justifyContent={'space-between'}
                       alignItems={'center'}
                       sx={{padding: '15px 10px 15px 25px'}}>
            {t(blockTypeField)}
            <IconButton
              color="primary"
              aria-label="close"
              onClick={handleClose}
              style={{
                color: theme.palette.text.primary,
                width: '40px',
                height: '40px',
              }}
            >
              <CloseIcon fontSize={'medium'}/>
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2} mt={-1.5}>
              {getFilteredBlockTypes().length !== 0 ? getFilteredBlockTypes().map((type) => (
                <Grid item sm={4} xs={6} key={type}>
                  <BlockTypeButton
                    key={type}
                    type={type}
                    onClick={() => {
                      updateConfigData(blockTypeField, type);
                      handleClose();
                    }}
                  />
                </Grid>
              )) : <Typography margin={'auto'} padding={'40px'}>
                {t("There are no available blocks.")}
              </Typography>
              }
            </Grid>
          </DialogContent>
        </Dialog>
      </BlockEditFormRow>
    </>
  );
}
