import {httpsCallable} from "firebase/functions";
import {firebaseFunctions} from "../firebase/firebase";
import {
  AddBlockRequest,
  AddBlockResponse
} from "tobl-data-schema/dist/api/block/block/addBlock";


// Create a reference to the cloud function
const addBlockCloudFunction = httpsCallable(
  firebaseFunctions,
  "block-addBlock"
);

// Define the function to add a block
export async function addBlock(request: AddBlockRequest): Promise<AddBlockResponse> {
  const response = await addBlockCloudFunction(request);
  return response.data as AddBlockResponse;
}
