// ContainerTableRow.tsx

import React, {useCallback, useEffect, useState} from 'react';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {useTranslation} from "react-i18next";
import {SchemaBlock_Team_Config} from "tobl-data-schema/dist/schema/block/block_type/team";
import {Paper, Typography, useTheme} from '@mui/material';
import AddApiKeyButton from "../../user/AddApiKeyButton";
import {deleteDoc, doc} from "firebase/firestore";
import {COLLECTION_API_KEYS} from "../../../models/api_keys";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import {getAvailableApiKeys} from "../../../cloud_functions/getAvailableApiKeys";
import {LimitedApiKeyInformation} from "tobl-data-schema/dist/api/block/ai/getAvailbleApiKeys";

interface Props {
  configuration: SchemaBlock_Team_Config;
  updateConfigData: (key: string, value: any) => void;
  teamBlockId: string;
}

const EditFormApi: React.FC<Props> = ({
  teamBlockId,
  configuration,
  updateConfigData
}) => {
  const theme = useTheme();
  const {t} = useTranslation();
  const [availableApiKeys, setAvailableApiKeys] = useState<LimitedApiKeyInformation[]>([]);

  const fetchAvailableApiKeys = useCallback(async () => {
    try {
      console.log('Fetching available API keys...');
      const response = await getAvailableApiKeys(
        {
          addToblKeys: true,
          teamBlockId
        });
      setAvailableApiKeys(response.keys);
    } catch (error) {
      console.error('Error fetching available API keys:', error);
    }
  }, [teamBlockId]);

  useEffect(() => {
    fetchAvailableApiKeys();
  }, [fetchAvailableApiKeys]);


  const deleteApiKey = async (id: string) => {
    const confirmDelete = window.confirm(t('Are you sure you want to delete this API key?'));
    if (confirmDelete) {
      await deleteDoc(doc(COLLECTION_API_KEYS, id));
      await fetchAvailableApiKeys(); // Refresh the keys after deletion
    }
  };

  return (
    <>
      {availableApiKeys.length > 0 &&
        <>
          <Typography
            variant={'body2'}>{t("Please select an API key to use")}</Typography>
          <Paper
            sx={{
              marginTop: "5px",
              marginBottom: "10px",
              borderColor: theme.palette.divider
            }}>
            <Select
              value={configuration.aiApiKeyId || (availableApiKeys ? availableApiKeys.filter(key => key.isToblKey)[0]?.id : "")}
              onChange={(e) => updateConfigData('aiApiKeyId', e.target.value)}
              fullWidth
              onClick={e => e.stopPropagation()}
              sx={{padding: "5px 10px"}}
            >
              {availableApiKeys?.map((key) => (
                <MenuItem
                  key={key.id}
                  value={key.id}
                  sx={{
                    height: "60px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}
                >
                  {key.displayName} {key.provider}
                  {(configuration.aiApiKeyId !== key.id &&
                    <IconButton edge="end" onClick={(e) => {
                      e.stopPropagation();
                      return deleteApiKey(key.id);
                    }}>
                      <DeleteIcon color={'disabled'}/>
                    </IconButton>
                  )}
                </MenuItem>
              ))}
            </Select>
          </Paper>
        </>
      }
      <AddApiKeyButton teamBlockId={teamBlockId}
        setAvailableApiKeys={setAvailableApiKeys}/>
    </>
  );
};

export default EditFormApi;
