import React, {useState} from 'react';
import {Box, Card, Typography, useTheme} from '@mui/material';
import {BlockActiveStatus, BlockType, SchemaBlockEdit} from "tobl-data-schema/dist/schema/block/block";
import {useTranslation} from "react-i18next";
import {useBlockSubscription} from "../../../hooks/useBlockSubscription";
import BlockTypeIcon from "../../../views/block/BlockTypeIcon";
import DeleteIcon from "@mui/icons-material/Delete";

interface Props {
  blockId: string;
  blockData: SchemaBlockEdit;
  updateBlockData: (key: string, value: any) => void;
  disabled?: boolean;
}

const EditFormProcessorMergerItem: React.FC<Props> = ({
  blockId,
  blockData,
  updateBlockData,
  disabled = false
}) => {
  const theme = useTheme();
  const {block} = useBlockSubscription(blockId);
  const {t} = useTranslation();
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseOver = () => {
    if (disabled) return;
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    if (disabled) return;
    setIsHovered(false);
  };

  const handleDelete = (blockIdToRemove: string) => {
    if (disabled) return;
    const newParentBlockIds = blockData.parentBlockIds.filter((blockId) => blockId !== blockIdToRemove);
    updateBlockData("parentBlockIds", newParentBlockIds);
  };

  if (!block) {
    return null;
  }

  const blockType = block.blockType === BlockType.project
    ? block.configuration.blockType_Project
    : block.blockType === BlockType.input
      ? block.configuration.blockType_Input
      : block.blockType === BlockType.processor
        ? block.configuration.blockType_Processor
        : block.blockType;

  return (
    <Box display="flex" flexDirection="row" alignItems="center" justifyContent={"center"}>
      <Card
        elevation={0}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onClick={() => handleDelete(blockId)}
        style={{
          width: "100%",
          backgroundColor: isHovered ? theme.palette.secondary.light : theme.palette.background.default,
          boxShadow: 'none',
          border: '1px solid',
          borderColor: theme.palette.text.primary,
          padding: '10px 15px',
          cursor: disabled ? undefined : 'pointer',
          opacity: block.activeStatus !== BlockActiveStatus.active || isHovered ? 0.4 : 1
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          fontSize={'26px'}
        >
          <Box
            width={"100%"}
            mr={'15px'}
            display={'flex'}
            flexDirection={"column"}
            alignItems={'start'}
          >
            <Typography variant="body2" fontSize={'0.75rem'}>
              {t(blockType)}
            </Typography>
            <Typography variant="subtitle1">
              {block.title}
            </Typography>
          </Box>
          <BlockTypeIcon block={block}/>
        </Box>
      </Card>
      {isHovered && (
        <Box
          position="absolute"
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          onClick={() => handleDelete(blockId)}
          pt={'10px'}
        >
          <DeleteIcon/>
        </Box>
      )}
    </Box>
  );
};

export default EditFormProcessorMergerItem;
