import {useEffect, useState} from 'react';
import {getSubtreeFromBlockId, useBlockTree} from './useBlockTree'; // Import your useBlockTree hook
import {BlockType, SchemaBlock} from "tobl-data-schema/dist/schema/block/block";
import {
  TypeBlockTree
} from "tobl-data-schema/dist/api/block/block/getBlockTree";

const useFindTriggerBlock = (initialParentBlockId: string | undefined) => {
  const [triggerBlock, setTriggerBlock] = useState<SchemaBlock | null>(null);
  const {blockTree, blockLookup} = useBlockTree();

  const [subTree, setSubTree] = useState<TypeBlockTree>()

  useEffect(() => {
    if (!blockTree || !initialParentBlockId || !blockLookup) {
      return;
    }
    setSubTree(getSubtreeFromBlockId(blockTree, initialParentBlockId));
  }, [blockTree, initialParentBlockId]);

  useEffect(() => {
    const findTriggerBlock = async () => {
      if (!initialParentBlockId || !subTree || !blockLookup) {
        return;
      }

      const queue = [{blockId: initialParentBlockId, tree: subTree}];

      while (queue.length > 0) {
        const {blockId, tree} = queue.shift()!; // Dequeue

        const block = blockLookup[blockId];

        if (!block) {
          return;
        }

        if (block.blockType === BlockType.input) {
          setTriggerBlock(block);
          return; // Exit the function as we found the first inputs block
        }

        // Enqueue child blocks for further searching
        if (tree[blockId]) {
          for (const childBlockId in tree[blockId]) {
            queue.push({blockId: childBlockId, tree: tree[blockId]});
          }
        }
      }
    };

    findTriggerBlock();
  }, [initialParentBlockId, subTree, blockLookup]);

  return triggerBlock;
};

export default useFindTriggerBlock;
