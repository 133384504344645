// DocumentDetailsTable.tsx

import React, {useEffect, useState} from 'react';
import {collection, getDocs, query, where} from 'firebase/firestore';
import {db} from "../../../firebase/firebase";
import {Box, Button, Typography, useTheme} from '@mui/material';
import {
  SchemaBlockDocument
} from "tobl-data-schema/dist/schema/block/document/document";
import {parseDoc} from "../../../firebase/firestore/parseDoc";

interface DocumentCardsProps {
  blockId: string;
  runId: string;
}

export const linkify = (text: string, color: string) => {
  const urlPattern = /https?:\/\/[^\s]+/g;

  if (!text) {
    return '';
  }

  if (typeof text !== 'string') {
    return text;
  }

  return text.replace(
    urlPattern,
    (url: string) =>
      `<a href="${url}" target="_blank" rel="noopener noreferrer" style="color: ${color};">${url}</a>`
  );
};


const copyToClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
  } catch (err) {
    alert('Failed to copy.');
  }
};


export const LinkedValue = ({value}: { value: string }) => {
  const theme = useTheme();

  return (
    <div
      style={{
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
        overflowX: 'auto',
        wordBreak: 'break-all'

      }}
      dangerouslySetInnerHTML={{__html: linkify(value, theme.palette.text.primary)}}
    />
  );
};

const DocumentCards: React.FC<DocumentCardsProps> = (
  {
    blockId,
    runId
  }) => {
  const theme = useTheme();
  const [documents, setDocuments] = useState<SchemaBlockDocument[]>([]);

  useEffect(() => {
    const fetchDocuments = async () => {
      const q = query(
        collection(db, `blocks/${blockId}/documents`),
        where('runId', '==', runId)
      );
      const querySnapshot = await getDocs(q);
      const docs = querySnapshot.docs.map(doc => parseDoc<SchemaBlockDocument>(doc));
      setDocuments(docs);
    };

    fetchDocuments();
  }, [blockId, runId]);

  return (
    <Box>
      {documents.map((doc, index) => {
        return (
          <Box key={doc.id}>
            {Object.entries(doc.data).map((entry, entryIndex) => {

              let valueText = entry[1];

              if (entry[0] === 'texts') {
                valueText = valueText.join('\n');
              }

              let value = valueText;
              try {
                value = JSON.parse(valueText);
                value = JSON.stringify(value, null, 2);
              } catch (e) {
                // Keep the value as is.
              }

              return (
                <Box
                  key={entry[0]} mt={entryIndex === 0 ? 0 : 6} mb={4}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center'
                  }}
                >
                  <Typography
                    variant={'h6'} mb={1}
                    component="div"
                    color={theme.palette.primary.main}
                  >
                    {entry[0].toUpperCase()}
                  </Typography>

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => copyToClipboard(value)}
                    style={{marginTop: '10px'}}
                  >
                    Copy Content
                  </Button>

                  {/*<Paper sx={{padding: '2px 20px', marginTop: 1}}>*/}
                  <Typography variant={'body1'} component="div"
                    textAlign={'left'}>
                    <LinkedValue value={value}/>
                  </Typography>
                  {/*</Paper>*/}
                </Box>
              );
            })}
          </Box>
        );
      })}
    </Box>
  );
};

export default DocumentCards;
