import React, {useState} from 'react';
import {Box, Button, LinearProgress, Typography, useTheme} from '@mui/material';
import {useGlobalContext} from "../../hooks/GlobalContext";
import HomeTitle from '../../components/home/HomeTitle';
import {useTranslation} from "react-i18next";
import ContactView from "../app/ContactView";
import MainView from "./MainView";

const MainViewContactScreen: React.FC = () => {
  const theme = useTheme();
  const globalContext = useGlobalContext();
  const userTopBlocks = globalContext?.userTopBlocks || [];
  const {t} = useTranslation();
  const [isContactOpen, setIsContactOpen] = useState(false);

  if (!userTopBlocks) {
    return <Box style={{padding: 20}}><LinearProgress/></Box>;
  }

  return (
    <MainView
      title={t("Contact Us")}
      description={t("Have questions or need assistance? We're here to help!")}
      videoUrl={'https://firebasestorage.googleapis.com/v0/b/the-team-os.appspot.com/o/main_background%2Fvideo%20(2160p).mp4?alt=media&token=604ffba8-fedb-4db1-94cb-bcf38b60ef0a'}
    >
      <Box maxWidth={1280} margin={'auto'} pt={'60px'}>
        <HomeTitle text={t("Get in Touch")}/>
        <Typography variant="h3" padding={"0px 20px 40px"}>
          {t("The easiest way to contact us is through email. Our dedicated support team is available to assist you with any questions or concerns you may have.")}
        </Typography>
        <div style={{padding: '20px'}}>
          <Button
            size={'large'}
            variant={'contained'}
            color="primary"
            href="mailto:support@tobl.ai"
            children={t("support@tobl.ai")}
            // onClick={() => {
            //   setIsContactOpen(true);
            // }}
          />
        </div>
      </Box>

      <ContactView
        isOpen={isContactOpen}
        onClose={() => {
          setIsContactOpen(false);
        }}
      />
    </MainView>
  );
}

export default MainViewContactScreen;
