import React from 'react';
import {useParams} from "react-router-dom";
import {BlockTreeProvider} from "../../hooks/useBlockTree";
import BlockView from "../block/BlockView";

export default function AdminView() {
  const {blockId} = useParams();

  if (!blockId) {
    return null;
  }

  return <BlockTreeProvider blockId={blockId}>
    <BlockView blockId={blockId}/>
  </BlockTreeProvider>;
}
