import {httpsCallable} from "firebase/functions";
import {firebaseFunctions} from "../firebase/firebase";
import {
  CreateCronStringRequest,
  CreateCronStringResponse
} from "tobl-data-schema/dist/api/input/createCronTextAndDescription";

// Create a reference to the cloud function
const createCronTextCloudFunction = httpsCallable(
  firebaseFunctions,
  "input-createCronString"
);

// Define the function to create cron text
export async function createCronText(request: CreateCronStringRequest): Promise<CreateCronStringResponse> {
  const response = await createCronTextCloudFunction(request);
  return response.data as CreateCronStringResponse;
}
