import React from 'react';
import {Box, Typography} from '@mui/material';
import {SchemaBlock} from "tobl-data-schema/dist/schema/block/block";
import {
  AddEditMode
} from "../../components/block/menu/BlockMenuAddOrEditDialog";
import BlockPartialEditor from "../../components/block/menu/BlockPartialEditor";
import BlockMenuDeleteButton
  from "../../components/block/menu/BlockMenuDeleteButton";
import BlockMenuCopyBlockIdButton
  from "../../components/block/menu/BlockMenuCopyBlockIdButton";
import {
  TeamSubscriptionButton
} from "../../components/block/team/TeamSubscriptionButton";
import {
  BlockEditButtonContext
} from "../../components/block/menu/BlockEditButtonContext";

interface ButtonProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  buttonTitle: string;
  onClick: (e: React.MouseEvent) => void;
}

export const ManageButton: React.FC<ButtonProps> = ({
                                                      icon,
                                                      title,
                                                      description,
                                                      buttonTitle,
                                                      onClick
                                                    }) => (
  <Box display="flex" alignItems="start" padding="10px 20px 30px"
       onClick={(event) => event.stopPropagation()}

  >
    <Box padding="0px 20px 0px 0px">
      {icon}
    </Box>
    <Box width="100%">
      <Typography variant="subtitle1" mb="15px"
                  onClick={(event) => event.stopPropagation()}
      >
        {title}
      </Typography>
      <Typography variant="body2" mb="5px"
                  onClick={(event) => event.stopPropagation()}

      >
        {description}
      </Typography>
      <Typography
        variant="body2"
        color="info.main"
        onClick={onClick}
        sx={{cursor: 'pointer'}}
      >
        {buttonTitle}
      </Typography>
    </Box>
  </Box>
);

interface BlockContextMenuProps {
  block: SchemaBlock;
}

const ManageBlockSettingView: React.FC<BlockContextMenuProps> = (
  {
    block,
  }) => {

  return (
    <>
      <BlockPartialEditor
        addEditMode={AddEditMode.EDIT}
        existingData={block}
        blockId={block.id}
        isTeamBlock
        buttonContext={BlockEditButtonContext.TITLE_CHANGE}
      />
      <TeamSubscriptionButton teamBlock={block}/>
      <BlockPartialEditor
        addEditMode={AddEditMode.EDIT}
        existingData={block}
        blockId={block.id}
        isTeamBlock
        buttonContext={BlockEditButtonContext.API_KEY_CHANGE}
      />
      <BlockMenuCopyBlockIdButton block={block} isTeamBlock={true}/>
      <BlockMenuDeleteButton block={block} isTeamBlock={true}/>
    </>
  );
};

export default ManageBlockSettingView;
