import React, {useState} from 'react';
import {
  Box,
  Grid,
  LinearProgress,
  Paper,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import BlockPartialEditor from '../menu/BlockPartialEditor';
import ProjectButton from "./ProjectButton";
import {useTranslation} from 'react-i18next';
import {BlockType, SchemaBlock} from "tobl-data-schema/dist/schema/block/block";
import {useGlobalContext} from '../../../hooks/GlobalContext';
import {AddEditMode} from "../menu/BlockMenuAddOrEditDialog";
import {BlockEditButtonContext} from "../menu/BlockEditButtonContext";
import TeamBlockContextMenu from "../../top_bar/TeamBlockContextMenu";

interface SelectorProps {
  block: SchemaBlock;
  defaultOpen?: boolean;
}

export default function TeamView({block}: SelectorProps) {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const {projectBlocks} = useGlobalContext();
  const {t} = useTranslation();
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  if (block.blockType !== BlockType.team || !projectBlocks || !projectBlocks[block.id]) {
    return <Box style={{padding: 20}}><LinearProgress/></Box>;
  }

  const blocks = projectBlocks[block.id];

  const placeholders =
    <Grid item lg={3} md={3} sm={6} xs={12} key={`placeholder`}>
      <BlockPartialEditor
        addEditMode={AddEditMode.ADD}
        blockType={BlockType.project}
        blockId={block.id}
        isTeamBlock={true}
        buttonContext={BlockEditButtonContext.TITLE_CHANGE}
      >
        <Paper
          aria-haspopup="true"
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          style={{
            aspectRatio: '1/1',
            width: '100%',
            height: '100%',
            cursor: 'pointer',
            border: '1.5px dashed',
            borderColor: isHovered ? theme.palette.text.secondary : theme.palette.divider,
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <Box style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            fontSize: '40px',
            color: theme.palette.text.secondary
          }}>
            +
          </Box>
        </Paper>
      </BlockPartialEditor>
    </Grid>;

  return (
    <Box padding="10px">
      <Box display={'flex'} alignItems={'center'} margin={'0px 10px 10px'}>
        <Typography variant={'h3'} width={'100%'}>
          {block?.title || t('Projects')}
        </Typography>
        <TeamBlockContextMenu block={block}/>
      </Box>
      <Grid container spacing={2} sx={{padding: '10px 10px 20px'}}>
        {blocks?.map((block) => (
          <Grid item lg={3} md={3} sm={6} xs={12} key={block.id}>
            <ProjectButton block={block}/>
          </Grid>
        ))}
        {!smallScreen && placeholders}
      </Grid>
    </Box>
  );
}
