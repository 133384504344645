import {httpsCallable} from "firebase/functions";
import {firebaseFunctions} from "../firebase/firebase";
import {
  DeleteBlockRequest,
  DeleteBlockResponse
} from "tobl-data-schema/dist/api/block/block/deleteBlock";


// Create a reference to the cloud function
const deleteBlockCloudFunction = httpsCallable(
  firebaseFunctions,
  "block-deleteBlock"
);

// Define the function to add a block
export async function deleteBlock(request: DeleteBlockRequest): Promise<DeleteBlockResponse> {
  const response = await deleteBlockCloudFunction(request);
  return response.data as DeleteBlockResponse;
}
