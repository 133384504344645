import React from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  SwipeableDrawer,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
  body: React.ReactNode;
  appBar: React.ReactNode;
  open: boolean;
  onClose: () => void;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  fullWidth?: boolean;
  fullScreen?: boolean;
}

const ToblBottomSheet: React.FC<Props> = ({
                                            body,
                                            appBar,
                                            open,
                                            onClose,
                                            maxWidth,
                                            fullWidth,
                                            fullScreen,
                                          }) => {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up(maxWidth || 'md'));
  return (
    <Box>
      {largeScreen ? (
        <Dialog
          open={open}
          onClose={onClose}
          maxWidth={maxWidth}
          fullWidth={fullWidth}
          fullScreen={fullScreen}
          sx={{zIndex: 5000}}
        >
          {appBar}
          <DialogContent style={{padding: 0}} onClick={(event) => event.stopPropagation()}>
            {body}
          </DialogContent>
        </Dialog>
      ) : (
        <SwipeableDrawer
          anchor="bottom"
          open={open}
          onClose={onClose}
          onOpen={() => {
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            'zIndex': 5000,
            '& .MuiDrawer-paper': {
              borderRadius: '20px 20px 0px 0px',
              backgroundColor: 'transparent',
              overflow: 'hidden',
              boxShadow: 'none',
              border: 'none',
            },
          }}
        >
          <Box
            margin={'auto'}
            mt={'10px'}
            mb={'10px'}
            padding={'3px'}
            width={'60px'}
            borderRadius={'10px'}
            bgcolor={'#D4D4D8'}
            onClick={onClose}
          />
          <Box
            sx={{
              borderRadius: '20px 20px 0px 0px',
              backgroundColor: theme.palette.background.paper,
            }}
          >
            {appBar}
            <DialogContent
              style={{padding: 0, paddingBottom: '100px', overflow: 'auto', height: '100vh'}}
              onClick={(event) => event.stopPropagation()}
            >
              {body}
            </DialogContent>
          </Box>
        </SwipeableDrawer>
      )}
    </Box>
  );
};

export default ToblBottomSheet;
