import React, {useEffect} from 'react';
import {
  BlockType_Input,
  SchemaBlock_Input_Config
} from "tobl-data-schema/dist/schema/block/block_type/input";
import {AddEditMode} from "../menu/BlockMenuAddOrEditDialog";
import {BlockTypeSelector} from "./BlockTypeSelector";
import {BlockType} from "tobl-data-schema/dist/schema/block/block";
import SchedulerInput from "../projects/documents/input/SchedulerInput";
import {useBlockEditorContext} from "../menu/BlockEditorContext";

interface Props {
  configuration: SchemaBlock_Input_Config;
}

const EditFormInput: React.FC<Props> = ({
                                          configuration,
                                        }) => {

  const {
    addEditMode,
    updateConfigData
  } = useBlockEditorContext();

  useEffect(
    () => {
      if (!configuration.blockType_Input) {
        updateConfigData('triggerBlockType', BlockType_Input.file_uploader_pdf)
      }
    },
    [configuration, updateConfigData]
  )

  return (
    <>
      {addEditMode === AddEditMode.ADD &&
          <BlockTypeSelector
              selectedType={configuration.blockType_Input}
              updateConfigData={updateConfigData}
              blockType={BlockType.input}
          />
      }
      {
        configuration.blockType_Input === BlockType_Input.scheduler &&
          <SchedulerInput configuration={configuration}/>
      }
    </>
  );
};

export default EditFormInput;
