import React from 'react';
import {ListItemIcon, ListItemText, MenuItem} from '@mui/material';
import {doc, updateDoc} from "firebase/firestore";
import {COLLECTION_BLOCKS} from "../../../models/block/block";
import {
  BlockActiveStatus,
  SchemaBlock
} from "tobl-data-schema/dist/schema/block/block";
import {useTranslation} from "react-i18next";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {useBlockTree} from "../../../hooks/useBlockTree";

interface ToggleActiveStatusMenuOptionProps {
  block: SchemaBlock;
}

const BlockMenuActiveStatusButton: React.FC<ToggleActiveStatusMenuOptionProps> = ({block}) => {
  const {t} = useTranslation();

  const {refreshBlockTree} = useBlockTree();

  const handleToggleActiveStatus = async () => {
    try {
      const blockDocRef = doc(COLLECTION_BLOCKS, block.id);
      const newStatus = block.activeStatus === BlockActiveStatus.active ? BlockActiveStatus.inactive : BlockActiveStatus.active;
      await updateDoc(blockDocRef, {
        activeStatus: newStatus
      });
      console.log(`Successfully updated activeStatus to ${newStatus}`);
      refreshBlockTree();
    } catch (err) {
      console.error('Failed to update activeStatus:', err);
    }
  };

  return (
    <MenuItem onClick={handleToggleActiveStatus}>
      <ListItemIcon>
        {block.activeStatus === BlockActiveStatus.active ?
          <CheckCircleIcon color={'primary'}/> :
          <RemoveCircleOutlineIcon color={'primary'}/>}
      </ListItemIcon>
      <ListItemText>
        {t('Active Status')}
      </ListItemText>
    </MenuItem>
  );
};

export default BlockMenuActiveStatusButton;
