const letterSpacing = '-0.02em';
const bold = 500;
export const typography = {
  fontWeightLight: 200,
  fontWeightRegular: 300,
  fontWeightMedium: 400,
  fontWeightBold: bold,
  htmlFontSize: 16,
  letterSpacing: letterSpacing,
  // fontFamily: 'SUIT',
  // fontFamily: 'Lexend, sans-serif',
  fontFamily: 'Noto Sans KR, sans-serif',
  h1: {
    fontSize: '3rem',
    fontWeight: bold,
    lineHeight: 1.6,
    letterSpacing: letterSpacing,
  },
  h2: {
    fontSize: '2rem',
    fontWeight: bold,
    lineHeight: 1.6,
    letterSpacing: letterSpacing,
  },
  h3: {
    fontSize: '1.75rem',
    fontWeight: bold,
    lineHeight: 1.6,
    letterSpacing: letterSpacing,
  },
  h4: {
    fontSize: '1.6rem',
    fontWeight: bold,
    lineHeight: 1.6,
    letterSpacing: letterSpacing,
  },
  h5: {
    fontSize: '1.25rem',
    fontWeight: bold,
    lineHeight: 1.6,
    letterSpacing: letterSpacing,
  },
  h6: {
    fontSize: '1.15rem',
    fontWeight: bold,
    lineHeight: 1.6,
    letterSpacing: letterSpacing,
  },
  body1: {
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: 1.6,
    letterSpacing: letterSpacing,
  },
  body2: {
    fontSize: '0.85rem',
    fontWeight: 400,
    lineHeight: 1.6,
    letterSpacing: letterSpacing,
  },
  button: {
    fontSize: '1rem',
    fontWeight: bold,
    lineHeight: 1.6,
    letterSpacing: letterSpacing,
  },
  subtitle1: {
    fontSize: '1rem',
    fontWeight: bold,
    lineHeight: 1.6,
    letterSpacing: letterSpacing,
  },
  subtitle2: {
    fontSize: '0.85rem',
    fontWeight: bold,
    lineHeight: 1.6,
    letterSpacing: letterSpacing,
  },
};
