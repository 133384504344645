import React, {useState} from 'react';
import AppBar from '@mui/material/AppBar';
import {useNavigate} from "react-router-dom";
import {Box, Toolbar, useMediaQuery, useTheme} from '@mui/material';
import IconButton from "@mui/material/IconButton";
import MenuIcon from '@mui/icons-material/Menu';
import {TopBarLanguageButton} from "./TopBarLanguageButton";
import {TopBarProfileButton} from "./TopBarProfileButton";
import Logo from "./Logo";
import {useGlobalContext} from "../../hooks/GlobalContext";
import MainDrawer from './MainDrawer';
import TopBarScreenModeButton from "./TopBarScreenModeButton";

interface TopBarProps {
  isMobile: boolean;
}

export function TopBar({
                         isMobile
                       }: TopBarProps) {
  const theme = useTheme();
  const init = useGlobalContext();
  const user = init.user;
  const navigate = useNavigate();
  const fullScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const [drawerOpen, setDrawerOpen] = useState(false);


  const handleDrawerOpen = () => {
    if (isMobile) {
      setDrawerOpen(true);
    }
  };

  const handleDrawerClose = () => {
    if (isMobile) {
      setDrawerOpen(false);
    }
  };

  const homeHandleClick = () => {
    navigate(`/app`);
  };

  return (
    <>
      <AppBar position="sticky" color="transparent" sx={{border: 'none'}}>
        <Toolbar style={{
          justifyContent: 'space-between',
          padding: fullScreen ? '30px 40px 0px 25px' : '10px 15px 10px 10px',
          background: theme.palette.background.default
        }}>
          <Box>
            {!user ? (
              <Box onClick={homeHandleClick} ml={'5px'}>
                <Logo size={fullScreen ? 28 : 22}/>
              </Box>
            ) : isMobile && (
              <IconButton color="inherit" onClick={handleDrawerOpen}>
                <MenuIcon sx={{fontSize: '28px'}}/>
              </IconButton>
            )}
          </Box>
          <Box sx={{display: 'flex', alignItems: 'center'}}>
            <TopBarScreenModeButton/>
            <TopBarLanguageButton/>
            <TopBarProfileButton/>
          </Box>
        </Toolbar>
      </AppBar>
      {user && (drawerOpen || fullScreen) && (<MainDrawer
        drawerOpen={drawerOpen}
        handleDrawerClose={handleDrawerClose}
        isMobile={isMobile}
        homeHandleClick={homeHandleClick}
      />)}
    </>
  );
}
