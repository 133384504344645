import React, {useEffect, useState} from 'react';
import {Box, Card, Checkbox, Grid, Typography, useTheme} from '@mui/material';
import {DocumentTraceProgress, ProgressType} from "./DocumentTraceProgress";
import {DocumentsViewDetailDialog} from '../DocumentsViewDetailDialog';
import moment from 'moment';
import {
  SchemaTrace,
  SchemaTraceBlockDetails
} from "tobl-data-schema/dist/schema/trace";
import TitleChangeDialog from "../../../../../views/block/TitleChangeDialog";
import {doc, updateDoc} from "firebase/firestore";
import {COLLECTION_TRACES} from "../../../../../models/trace";

interface DocumentPreviewCardProps {
  trace: SchemaTrace,
  onCheckboxChange?: (runId: string) => void;
  isSelected?: boolean;
}

export function DocumentPreviewCard(
  {
    trace,
    onCheckboxChange,
    isSelected
  }: DocumentPreviewCardProps) {
  const theme = useTheme();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [resultBlocks, setResultBlocks] = useState<SchemaTraceBlockDetails[]>([]);
  const [isHovered, setIsHovered] = useState(false);
  const [title, setTitle] = useState(trace.title); // Add state for title
  const [titleDialogOpen, setTitleDialogOpen] = useState(false); // Add state for title dialog

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    // Compute resultBlocks when trace.blocks changes
    const newResultBlocks: SchemaTraceBlockDetails[] = [];
    Object.values(trace.blocks).forEach(block => {
      if (block.isResultBlock) {
        newResultBlocks.push(block);
      }
    });
    setResultBlocks(newResultBlocks);
  }, [trace.blocks]); // Depend on trace.blocks

  const handleCardClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target !== event.currentTarget) {
      return;
    }
    setIsDialogOpen(true);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();

    if (onCheckboxChange) {
      onCheckboxChange(trace.id);
    }
  };


  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };


  const handleTitleClick = () => {
    setTitleDialogOpen(true); // Open the title dialog when the title is clicked
  };

  const handleTitleClose = (newTitle?: string) => {
    if (newTitle) {
      updateDoc(doc(COLLECTION_TRACES, trace.id), {title: newTitle}) // Update the title in Firestore
      setTitle(newTitle); // Update the title when the dialog is closed
    }
    setTitleDialogOpen(false);
  };

  const width = '150px';
  return (
    <Grid item xs={6} sm={4} md={3} lg={12 / 5} marginTop={1} key={trace.id}>
      <Box
        style={{
          position: 'relative',
          maxWidth: resultBlocks.length > 1 ? '153px' : '150px',
          height: 150 * 1.2941,
          margin: 'auto',
        }}
      >
        {resultBlocks.length > 1 && <Card
            style={{
              position: 'absolute',
              left: 1.5,
              top: 0,
              maxWidth: width,
              width: '100%',
              height: 150 * 1.2941,
              margin: 'auto',
              borderRadius: 0,
              backgroundColor: theme.palette.background.paper,
              transform: `${isHovered ? 'scale(1.05)' : 'scale(1)'} rotate(2deg)`,
              transition: 'transform 0.1s ease',
            }}
        />}
        <Card
          onClick={handleCardClick}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          style={{
            position: 'relative',
            maxWidth: '150px',
            width: '100%',
            height: 150 * 1.2941,
            margin: 'auto',
            borderRadius: 0,
            padding: 10,
            cursor: 'pointer',
            backgroundColor: theme.palette.background.paper,
            transform: isHovered ? 'scale(1.05)' : 'scale(1)',
            transition: 'transform 0.1s ease',
          }}
        >
          <Box sx={{pointerEvents: 'none'}}>
            <DocumentTraceProgress
              trace={trace}
              progressType={ProgressType.LINEAR}
            />
          </Box>
          <Box
            onClick={handleCardClick}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            style={{
              fontSize: '0.3rem',
              lineHeight: 1.2,
              height: 150 * 1.2941 - 20,
              textAlign: 'left',
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word',
              overflow: 'hidden',
            }}>
            {resultBlocks.map((block, index) => (
              <div
                key={block.runId || index}
                style={{overflow: 'hidden', pointerEvents: 'none'}}
              >
                {block.preview}
              </div>
            ))}
          </Box>
          <Box
            style={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              zIndex: 1,
              width: '30px',
              height: '30px',
              backgroundColor: theme.palette.background.paper,
            }}
          />
          <Checkbox
            checked={isSelected}
            onChange={handleCheckboxChange}
            inputProps={{'aria-label': 'Select document'}}
            style={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              zIndex: 1
            }}
          />
        </Card>
      </Box>
      <Typography
        variant="body2"
        align='center'
        maxWidth='200px'
        margin='auto'
        mt={2}
        sx={{
          wordWrap: 'break-word',
          display: '-webkit-box',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          cursor: 'pointer' // Make the title clickable
        }}
        onClick={handleTitleClick} // Open the dialog when the title is clicked
      >
        {title}
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        align='center' maxWidth='200px' margin='auto'
        style={{fontSize: '0.75rem'}}
      >
        {moment(trace.createdAt.toDate()).fromNow()}
      </Typography>
      {isDialogOpen && <DocumentsViewDetailDialog
          key={trace.id}
          trace={trace}
          onClose={handleCloseDialog}
          isOpen={isDialogOpen}
      />}
      <TitleChangeDialog currentTitle={title} open={titleDialogOpen}
                         handleClose={handleTitleClose}/>
    </Grid>
  );
}
