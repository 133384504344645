import React, {useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  useMediaQuery,
  useTheme
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {useTranslation} from 'react-i18next';
import {AIProvider} from "tobl-data-schema/dist/enums/ai_provider";
import {
  addAiApiKeyRequest
} from "tobl-data-schema/dist/api/block/ai/addAiApiKey";
import {addApiKey} from "../../cloud_functions/addApiKey";
import {
  LimitedApiKeyInformation
} from "tobl-data-schema/dist/api/block/ai/getAvailbleApiKeys";
import {getAvailableApiKeys} from "../../cloud_functions/getAvailableApiKeys";
import {BlockEditFormRow} from "../block/edit/BlockEditFormRow";

interface Props {
  setAvailableApiKeys: React.Dispatch<React.SetStateAction<LimitedApiKeyInformation[]>>;
  teamBlockId: string;
}

export default function AddApiKeyButton(
  {
    setAvailableApiKeys,
    teamBlockId
  }: Props) {
  const theme = useTheme();
  const [isKeyDialogOpen, setKeyDialogOpen] = useState(false);
  const [newApiKey, setNewApiKey] = useState({
    apiKey: '',
    provider: AIProvider.OpenAI.toString()
  });
  const [displayName, setDisplayName] = useState('');
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const {t} = useTranslation();

  const addAiApiKey = async (e: React.MouseEvent) => {
    e.stopPropagation();
    const request: addAiApiKeyRequest = {
      apiKey: newApiKey.apiKey,
      aiProvider: newApiKey.provider as AIProvider,
      displayName: displayName,
      teamBlockId,
    };

    try {
      await addApiKey(request);
      const updatedKeys =
        await getAvailableApiKeys(
          {
            addToblKeys: false,
            teamBlockId
          });
      setAvailableApiKeys(updatedKeys.keys);
      setNewApiKey({apiKey: '', provider: AIProvider.OpenAI.toString()});
      setDisplayName('');
    } catch (error) {
      console.error('Error adding API Key:', error);
    }

    setKeyDialogOpen(false);
  };

  return (
    <>
      <Paper sx={{border: "2px dashed", borderColor: theme.palette.divider}}>
        <MenuItem onClick={(e) => {
          e.stopPropagation();
          setKeyDialogOpen(true);
        }} sx={{padding: "20px 20px", margin: 0}}>
          <ListItemIcon>
            <AddIcon color="inherit"/>
          </ListItemIcon>
          <ListItemText primary={t('add_new_api_key')}/>
        </MenuItem>
      </Paper>

      {isKeyDialogOpen && (
        <Dialog
          open={isKeyDialogOpen}
          onClose={(e: React.MouseEvent) => {
            e.stopPropagation();
            setKeyDialogOpen(false);
          }}
          fullWidth
          maxWidth="md"
          fullScreen={fullScreen}
        >
          <DialogTitle>{t("add_new_api_key")}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("please_enter_the_api_key_and_select_provider")}
            </DialogContentText>
            <BlockEditFormRow
              label={t("display_name")}
              children={<TextField
                onKeyDown={e => e.stopPropagation()}
                onKeyUp={e => e.stopPropagation()}
                required
                fullWidth
                autoFocus
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
              />}
            />
            <BlockEditFormRow
              label={t("api_key")}
              children={<TextField
                onKeyDown={e => e.stopPropagation()}
                onKeyUp={e => e.stopPropagation()}
                required
                fullWidth
                value={newApiKey.apiKey}
                onChange={(e) => setNewApiKey({
                  ...newApiKey,
                  apiKey: e.target.value
                })}
              />}
            />
            <BlockEditFormRow
              label={t("provider")}
              children={<Select
                label={t("provider")}
                value={newApiKey.provider}
                onChange={(e) => setNewApiKey({
                  ...newApiKey,
                  provider: e.target.value as string
                })}
                fullWidth
              >
                <MenuItem
                  value={AIProvider.Google}>{AIProvider.Google}</MenuItem>
                <MenuItem
                  value={AIProvider.OpenAI}>{AIProvider.OpenAI}</MenuItem>
              </Select>}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                setKeyDialogOpen(false);
              }}
              color="inherit"
            >
              {t("cancel")}
            </Button>
            <Button onClick={addAiApiKey} color="inherit">
              {t("add")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
