import React, {useState} from "react";
import {Box, Card, Typography, useTheme} from "@mui/material";
import RunDetailsDialog from "../../../../views/block/run/RunDetailsDialog";
import {useGetBlock} from "../../../../hooks/useGetBlock";
import {SchemaTraceBlockDetails} from "tobl-data-schema/dist/schema/trace";

interface DocumentItemProps {
  traceBlockDetails: SchemaTraceBlockDetails;
  blockId: string;
}

export function DocumentItem({
  traceBlockDetails,
  blockId
}: DocumentItemProps) {
  const theme = useTheme();
  const {block} = useGetBlock(blockId);
  const [isDiagramOpen, setDiagramOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  const toggleDiagram = () => {
    setDiagramOpen(!isDiagramOpen);
  };

  return (
    <Box mt={4}>
      <Card
        key={traceBlockDetails.runId}
        onClick={toggleDiagram}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        style={{
          maxWidth: '250px',
          width: '100%',
          height: 250 * 1.2941,
          margin: 'auto',
          borderRadius: 0,
          padding: 15,
          cursor: 'pointer',
          backgroundColor: theme.palette.background.paper,
          transform: isHovered ? 'scale(1.05)' : 'scale(1)',
          transition: 'transform 0.1s ease',
        }}
      >
        <Box
          onClick={toggleDiagram}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          style={{
            fontSize: '0.4rem',
            height: 250 * 1.2941 - 30,
            lineHeight: 1.5,
            textAlign: 'left',
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflow: 'hidden',
          }}>
          {traceBlockDetails.preview}
        </Box>
      </Card>
      <Typography
        mb={2}
        variant="subtitle1"
        align='center'
        maxWidth='250px'
        margin='auto'
        mt={2}
        sx={{
          wordWrap: 'break-word',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {block?.title || blockId}
      </Typography>

      {traceBlockDetails.runId && (
        <RunDetailsDialog
          key={"dialog-" + traceBlockDetails.runId}
          runId={traceBlockDetails.runId}
          onClose={toggleDiagram}
          isDiagramOpen={isDiagramOpen}
          blockId={blockId}
        />
      )}
    </Box>
  );
}
