import {collection, FieldValue, Timestamp} from "firebase/firestore";
import {db} from "../firebase/firebase";

export const TABLE_APPS = "apps";
export const COLLECTION_APPS = collection(db, TABLE_APPS);

export enum AppType {
  EMAIL_SENDER = "email_sender",
  XLSX_AGGREGATOR = "xlsx_aggregator",
  TEXT_DIFF_INPUTS = "text_diff_inputs",
}

export interface SchemaAppBase {
  id: string;
  title: string;
  logoImageUrl: string;
  description: string;
  groupId: string;
  appType: AppType;
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

export interface SchemaAppEmailSender extends SchemaAppBase {
  appType: AppType.EMAIL_SENDER;
  voiceRecognitionBlockId: string;
  contentProcessingBlockId: string;
  senderReceiptProcessingBlockId: string;
}

export interface SchemaAppXlsxAggregator extends SchemaAppBase {
  appType: AppType.XLSX_AGGREGATOR;
  xlsxFileBlockId: string;
}

export interface SchemaAppTwoTextInputs extends SchemaAppBase {
  appType: AppType.TEXT_DIFF_INPUTS;
  textInputBlockId: string;
}

export type SchemaApp = SchemaAppEmailSender
  | SchemaAppXlsxAggregator
  | SchemaAppTwoTextInputs;

export interface SchemaAppCreate extends Omit<SchemaApp, "id" | "createdAt" | "updatedAt"> {
  createdAt: FieldValue;
  updatedAt: FieldValue;
}

export interface SchemaAppUpdate extends Partial<Omit<SchemaAppCreate, "id" | "createdAt" | "updatedAt">> {
  createdAt: FieldValue;
  updatedAt: FieldValue;
}
