import React from 'react';
import {Box, Button} from '@mui/material';
import {addRun} from "../../../cloud_functions/addRun";
import {v4 as uuidv4} from "uuid";

interface TriggerBoxProps {
  blockId: string;
  setProgress: React.Dispatch<React.SetStateAction<number>>;
  handleClose: () => void;
}

const TriggerBox: React.FC<TriggerBoxProps> = ({
                                                 blockId,
                                                 setProgress,
                                                 handleClose
                                               }) => {

  const handleButtonClick = async () => {
    const traceId = uuidv4();
    setProgress(50);
    await addRun({
                   blockId,
                   traceId,
                   clientInputData: {
                     title: new Date().toLocaleString(),
                   }
                 });
    setProgress(100); // Assuming the progress is set to 100 when the task is triggered
    handleClose();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: 2,
        width: '100%',
        height: '100%',
      }}
    >
      <Button variant="contained" color="primary" onClick={handleButtonClick}>
        Trigger Task
      </Button>
    </Box>
  );
};

export default TriggerBox;
