import React, {useState} from 'react';
import {Checkbox, ListItem, ListItemText, Typography} from '@mui/material';
import {DocumentsViewDetailDialog} from "../DocumentsViewDetailDialog";
import {DocumentTraceProgress, ProgressType} from "./DocumentTraceProgress";
import moment from "moment/moment";
import {SchemaTrace} from "tobl-data-schema/dist/schema/trace";

interface DocumentPreviewCardProps {
  trace: SchemaTrace,
  onCheckboxChange: (runId: string) => void;
  isSelected: boolean;
}

export function DocumentPreviewListItem(
  {
    trace,
    onCheckboxChange,
    isSelected,
  }: DocumentPreviewCardProps) {

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const handleCardClick = () => {
    setIsDialogOpen(true);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    onCheckboxChange(trace.id);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };


  return (
    <>
      <ListItem style={{cursor: 'pointer', padding: '10px 0'}}>
        <Checkbox
          checked={isSelected}
          onChange={handleCheckboxChange}
          inputProps={{'aria-label': 'Select document'}}
          style={{left: -10}}
        />
        <ListItemText
          onClick={handleCardClick}
          primary={
            <Typography variant="subtitle1">
              {trace.title.slice(0, 150) || trace.createdAt.toDate().toLocaleString()}
            </Typography>
          }
          secondary={
            <Typography
              variant="body2"
              color="textSecondary"
              style={{fontSize: '0.8rem'}}
            >
              {moment(trace.createdAt.toDate()).fromNow()}
            </Typography>
          }
        />

        <DocumentTraceProgress trace={trace}
          progressType={ProgressType.CIRCULAR}/>
      </ListItem>
      <DocumentsViewDetailDialog
        trace={trace}
        onClose={handleCloseDialog}
        isOpen={isDialogOpen}
      />
    </>
  );
}
