import React, {useEffect, useState} from 'react';
import {Box, Divider, Typography, useTheme} from '@mui/material';
import {useTranslation} from "react-i18next";

interface Props {
  title: string;
  features: string[];
  price?: number;
  button: React.ReactNode;
  height?: string;
  isPromo?: boolean;
  isYearly?: boolean;
}

const HomePricingBox: React.FC<Props> = ({title, price, button, features, height, isPromo, isYearly}) => {
  const theme = useTheme();
  const {t} = useTranslation();
  const [priceString, setPriceString] = useState('');

  useEffect(() => {
    let adjustedPrice = price || 0;
    if (isYearly) adjustedPrice *= 10;
    if (isPromo) adjustedPrice *= 0.5; // apply 50% discount
    setPriceString(adjustedPrice.toFixed(0)); // assuming you want to keep it to 2 decimal places
  }, [price, isPromo, isYearly]);

  return (
    <Box
      style={{
        padding: '20px',
        border: '1px solid',
        height: height || 'auto',
        borderColor: theme.palette.text.primary,
      }}
    >
      <Typography variant="h3" gutterBottom>
        {t(title)}
      </Typography>
      <Typography variant="h6" height={'50px'} gutterBottom>
        {(price || price === 0) && ("$" + priceString + " / " + t(isYearly ? "year" : "month"))}
      </Typography>
      {button}
      <Box height={'20px'}/>
      {features.map((feature, idx) => (
        <React.Fragment key={idx}>
          <Divider
            color={idx === 0 ? theme.palette.text.primary : theme.palette.secondary.light}
            style={{marginBottom: '10px'}}/>
          <Typography variant="body1" gutterBottom mb={'10px'}>
            {t(feature)}
          </Typography>
        </React.Fragment>
      ))}
    </Box>
  );
};

export default HomePricingBox;
