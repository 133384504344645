import {useEffect, useState} from 'react';
import {doc, getDoc} from 'firebase/firestore';
import {APP_ALIAS_COLLECTION, SchemaAppAlias} from "../models/app_alias";
import {parseDoc} from "../firebase/firestore/parseDoc";

const useAliasToAppId = (alias: string | undefined) => {
  const [appId, setAppId] = useState<string>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<unknown>();

  useEffect(() => {
    if (!alias) return;
    const fetchGroupId = async () => {
      try {
        const docRef = doc(
          APP_ALIAS_COLLECTION,
          alias
        );
        const groupAlias = await getDoc(docRef).then(parseDoc<SchemaAppAlias>)

        if (groupAlias) {
          setAppId(groupAlias.appId);
        } else {
          console.log("No such group alias!");
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchGroupId();
  }, [alias]);

  return {appId, loading, error};
};

export default useAliasToAppId;
