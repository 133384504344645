import React, {useState} from 'react';
import {Box, Paper, Typography, useTheme} from '@mui/material';
import {useTranslation} from "react-i18next";
import {EmojiForBlockType} from "../../../views/block/BlockTypeIcon";

interface BlockRowProps {
  type: string;
  onClick: () => void;
}

const BlockTypeButton: React.FC<BlockRowProps> = (
  {
    type,
    onClick
  }) => {
  const theme = useTheme();
  const [isHovered, setIsHovered] = useState(false);
  const {t} = useTranslation();

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  return (
    <Paper
      aria-controls={type}
      aria-haspopup="true"
      onClick={onClick}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      style={{
        aspectRatio: '1/1',
        width: '100%',
        height: '100%',
        cursor: 'pointer',
        border: '1px solid',
        borderRadius: '1px',
        borderColor: isHovered ? theme.palette.text.secondary : theme.palette.divider,
        backgroundColor: theme.palette.background.paper,
        transform: isHovered ? 'scale(1.05)' : 'scale(1)',
        transition: 'transform 0.1s ease',
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100%"
        fontSize={'2.5rem'}
      >
        {EmojiForBlockType(type)}
        <Typography
          variant="subtitle1"
          textAlign={'center'}
          padding={2}
        >
          {t(type)}
        </Typography>
      </Box>
    </Paper>
  );
};

export default BlockTypeButton;
