import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import {useSnackbar} from "../../../hooks/useSnackbar";
import {useBlockTree} from "../../../hooks/useBlockTree";
import {copyBlockTree} from "../../../cloud_functions/copyBlockTree";
import {
  CopyBlockTreeRequest
} from "tobl-data-schema/dist/api/block/block/copyBlockTree";
import {SchemaBlock} from "tobl-data-schema/dist/schema/block/block";

interface CopyBlockTreeMenuItemProps {
  block: SchemaBlock;
  handleClose: () => void;
}

const CopyBlockTreeMenuItem: React.FC<CopyBlockTreeMenuItemProps> = ({
                                                                       block,
                                                                       handleClose
                                                                     }) => {
  const {refreshBlockTree} = useBlockTree();
  const {showSnackbar} = useSnackbar();

  const handleCopyBlockTree = async () => {
    try {
      const request: CopyBlockTreeRequest = {
        sourceBlockId: block.id,
      };
      showSnackbar("Making a copy of the block.");

      const response = await copyBlockTree(request);
      showSnackbar("Copied block tree.");
      refreshBlockTree();
    } catch (error) {
      console.error(error);
      showSnackbar("Failed to copy block tree.");
    }
  };

  return (
    <MenuItem onClick={() => {
      handleCopyBlockTree();
      handleClose();
    }}>
      <FileCopyIcon fontSize={'small'} sx={{mr: 1}}/>
      Copy Block Tree
    </MenuItem>
  );
};

export default CopyBlockTreeMenuItem;
