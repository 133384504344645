import React, {useState} from 'react';
import {Box, Grid, Paper, Typography, useMediaQuery, useTheme} from '@mui/material';
import {useTranslation} from "react-i18next";

type Item = {
  title: string;
  description: string;
  imageUrl?: string;
};

const tasks: Item[] = [
  {
    title: "customerService.title",
    description: "customerService.description",
  },
  {
    title: "dataAnalysis.title",
    description: "dataAnalysis.description",
  },
  {
    title: "marketing.title",
    description: "marketing.description",
  },
  {
    title: "hrManagement.title",
    description: "hrManagement.description",
  },
  {
    title: "supplyChain.title",
    description: "supplyChain.description",
  },
  {
    title: "riskManagement.title",
    description: "riskManagement.description",
  },
  {
    title: "healthcare.title",
    description: "healthcare.description",
  },
  {
    title: "manufacturing.title",
    description: "manufacturing.description",
  },
  {
    title: "education.title",
    description: "education.description",
  },
  {
    title: "customerInsights.title",
    description: "customerInsights.description",
  },
  {
    title: "financialServices.title",
    description: "financialServices.description",
  },
  {
    title: "researchAndDevelopment.title",
    description: "researchAndDevelopment.description",
  }
];

const HomeUseCase: React.FC = () => {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const mediumScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const {t} = useTranslation();

  const TaskItem: React.FC<{ item: Item, t: any }> = ({item, t}) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseOver = () => {
      setIsHovered(true);
    };

    const handleMouseOut = () => {
      setIsHovered(false);
    };

    return (
      <Paper
        sx={{
          padding: '20px',
          aspectRatio: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderColor: isHovered ? theme.palette.background.default : theme.palette.divider,
          background: isHovered ? 'linear-gradient(45deg, #60BA49 10%, #3A996B 30%, #636FB5 80%)' : theme.palette.background.paper,
        }}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onClick={handleMouseOver}
      >
        {isHovered ? (
          <Typography variant="subtitle1" align={"center"}
            color={isHovered ? theme.palette.background.default : theme.palette.primary.main}>
            {t(item.description)}
          </Typography>
        ) : (
          <Typography variant="h4" component="div" align={"center"}>
            {t(item.title)}
          </Typography>
        )}
      </Paper>
    );
  };

  return (
    <>
      <Box padding={'0px 20px'}>

        <Grid container spacing={3} mb={"100px"}>
          {tasks.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={item.title}>
              <TaskItem item={item} t={t}/>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default HomeUseCase;
