import React from 'react';
import {Divider, Typography, useMediaQuery, useTheme} from "@mui/material";
import {useInView} from 'react-intersection-observer';

interface Props {
  text?: string;
  textAlign?: 'center' | 'left' | 'right';
  divider?: boolean;
}

const HomeTitle: React.FC<Props> = ({text, textAlign, divider = true}) => {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const mediumScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const textGradient = {
    background: 'linear-gradient(45deg, #60BA49 30%, #3A996B 50%, #636FB5 90%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    backgroundClip: 'text',
    textFillColor: 'transparent'
  };

  // 애니메이션 스타일
  const charAnimation = {
    opacity: inView ? 1 : 0,
    transition: `opacity 0.5s ease-in-out`,
  };

  return (
    <>
      {divider && <Divider style={{margin: '0px 20px'}}/>}
      <Typography
        variant={largeScreen ? "h1" : mediumScreen ? "h2" : "h3"}
        gutterBottom
        padding={divider ? '20px' : 0}
        textAlign={textAlign}
        color={theme.palette.text.primary}
        sx={{
          lineHeight: 1.2,
          ...textGradient,
          display: 'inline-block', // span이 inline으로 작동하도록 설정
        }}
        ref={ref}
      >
        {text?.split('').map((char, index) => (
          <span key={index} style={{
            ...charAnimation,
            transitionDelay: `${index * 0.05}s`, // 각 글자가 순차적으로 나타나도록
          }}>
            {char}
          </span>
        ))}
      </Typography>
    </>
  );
};

export default HomeTitle;
