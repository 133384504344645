import {useEffect, useState} from "react";
import {doc, onSnapshot} from "firebase/firestore";
import {db} from "../firebase/firebase";
import {parseDoc} from "../firebase/firestore/parseDoc";
import {
  SchemaBlock,
  TABLE_BLOCKS
} from "tobl-data-schema/dist/schema/block/block";

export const useBlockSubscription = (blockId: string | undefined) => {
  const [block, setBlock] = useState<SchemaBlock>();
  const [blockLoading, setBlockLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    if (!blockId) {
      return;
    }
    setBlockLoading(true);
    setError(undefined);

    // Setting up Firestore snapshot listener
    const unsubscribe = onSnapshot(
      doc(db, TABLE_BLOCKS, blockId),
      (snapshot) => {
        if (snapshot.exists()) {
          setBlock(parseDoc<SchemaBlock>(snapshot));
        } else {
          setError(new Error("Block not found"));
        }
        setBlockLoading(false);
      },
      (err) => {
        setError(err);
        setBlockLoading(false);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [blockId]);

  return {block, blockLoading, error};
};
