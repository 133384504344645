import {httpsCallable, HttpsCallableResult} from "firebase/functions";
import {firebaseFunctions} from "../firebase/firebase";
import {AddRunRequest} from "tobl-data-schema/dist/api/block/block/run/addRun";

// Create a reference to the cloud function
const cloudFunction = httpsCallable(
  firebaseFunctions,
  "run-addRun"
);

// Define the function to add a run
export async function addRun(request: AddRunRequest): Promise<HttpsCallableResult> {
  return await cloudFunction(request);
}
