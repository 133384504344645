import TextField from "@mui/material/TextField";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {
  BlockType_Processor,
  SchemaBlock_Processor_Config
} from "tobl-data-schema/dist/schema/block/block_type/processor";
import {BlockEditFormRow} from "./BlockEditFormRow";
import Select from "@mui/material/Select";
import {AiModel} from "tobl-data-schema/dist/enums/AiModel";
import MenuItem from "@mui/material/MenuItem";

export function EditFormProcessorAudio({
                                         configuration,
                                         updateConfigData,
                                       }: {
  configuration: SchemaBlock_Processor_Config;
  updateConfigData: (key: string, value: any) => void;
}) {
  const defaultProcessorType = Object.values(BlockType_Processor)[0];
  const {t} = useTranslation();

  useEffect(() => {
    if (!configuration.blockType_Processor) {
      updateConfigData('blockType_Processor', defaultProcessorType);
    }
  }, [configuration, updateConfigData, defaultProcessorType]);

  return (
    <>
      {
        configuration.blockType_Processor === BlockType_Processor.audio_to_text_ai && (
          <BlockEditFormRow label={t("AI Model")}>
            <Select
              value={configuration.aiModel || AiModel.WHISPER}
              onChange={(e) => updateConfigData('aiModel', e.target.value)}
              fullWidth
              onClick={e => e.stopPropagation()}
            >
              <MenuItem value={AiModel.WHISPER}>Whisper</MenuItem>
              <MenuItem value={AiModel.ASSEMBLY_AI}>Assembly AI</MenuItem>
            </Select>
          </BlockEditFormRow>
        )
      }
      {configuration.blockType_Processor === BlockType_Processor.audio_chunker && (
        <BlockEditFormRow label={t("Chunk Size (MB)")}>
          <TextField
            onKeyDown={e => e.stopPropagation()}
            onKeyUp={e => e.stopPropagation()}
            type="number"
            value={configuration.chunkSizeMb || 0}
            onChange={(e) => updateConfigData('chunkSizeMb', Number(e.target.value))}
            fullWidth
          />
        </BlockEditFormRow>
      )}
    </>
  );
}
