import {createContext, useContext, useEffect, useMemo, useState} from 'react';
import {useGlobalContext} from "./GlobalContext";
import {getBlockTree} from '../cloud_functions/getBlockTree';
import {
  TypeBlockLookup,
  TypeBlockTree
} from "tobl-data-schema/dist/api/block/block/getBlockTree";
import {BlockType, SchemaBlock} from "tobl-data-schema/dist/schema/block/block";
import {LinearProgress} from "@mui/material"; // Import your getBlockTree function

export function findAllInputBlocks(tree: TypeBlockTree, blockLookup: TypeBlockLookup): SchemaBlock[] {
  // Initialize the queue with the root of the tree
  const queue = Object.keys(tree);
  const inputBlocks: SchemaBlock[] = [];

  while (queue.length > 0) {
    const blockId = queue.shift()!; // Dequeue a block from the queue
    const block = blockLookup[blockId];

    // If the blockType of the block is 'input', add it to the inputBlocks array
    if (block.blockType === BlockType.input) {
      inputBlocks.push(block);
    }

    // Enqueue all children of the current block
    const childrenBlockIds = getChildrenBlockIdsFromTree(tree, blockId);
    queue.push(...childrenBlockIds);
  }

  // Return the array of input blocks
  return inputBlocks;
}

export function getSubtreeFromBlockId(rootTree: TypeBlockTree, targetBlockId: string): TypeBlockTree | undefined {
  const queue = [{blockId: targetBlockId, tree: rootTree}];

  while (queue.length > 0) {
    const {blockId, tree} = queue.shift()!; // Dequeue

    if (blockId in tree) {
      // Create a new tree that starts with the targetBlockId as the root and includes its subtree
      return {[targetBlockId]: tree[blockId]};
    }

    for (const childBlockId in tree) {
      queue.push({blockId, tree: tree[childBlockId]});
    }
  }

  return undefined;
}

export function getChildrenBlockIdsFromTree(tree: TypeBlockTree, targetBlockId: string): string[] {
  const subtree = getSubtreeFromBlockId(tree, targetBlockId);
  if (!subtree) {
    return [];
  }

  return Object.keys(subtree[targetBlockId]);
}

const BlockTreeContext = createContext<{
  blockTree: TypeBlockTree | undefined;
  refreshBlockTree: () => void;
  blockTreeLoading: boolean;
  setBlockTreeLoading: (loading: boolean) => void;
  blockLookup: TypeBlockLookup | undefined
  inputBlocks: SchemaBlock[] | undefined
  isBlockTreeContextProvided: boolean
}>({
     blockTree: undefined,
     refreshBlockTree: () => {
       console.log("No function provided for refreshBlockTree.")
     },
     blockTreeLoading: false,
     setBlockTreeLoading: () => {
     },
     blockLookup: undefined,
     inputBlocks: undefined,
     isBlockTreeContextProvided: false
   });

export const useBlockTree = () => {
  return useContext(BlockTreeContext);
};

interface BlockTreeProviderProps {
  children: React.ReactNode;
  blockId: string
}

export const BlockTreeProvider: React.FC<BlockTreeProviderProps> = ({
                                                                      children,
                                                                      blockId
                                                                    }) => {
  const user = useGlobalContext().user;
  const [blockTree, setBlockTree] = useState<TypeBlockTree | undefined>({});
  const [blockTreeLoading, setBlockTreeLoading] = useState(false)
  const [blockLookup, setBlockLookup] = useState<{
    [blockId: string]: SchemaBlock
  }>()

  const inputBlocks = useMemo(() => {
    if (!blockTree || !blockLookup) return [];
    return findAllInputBlocks(blockTree, blockLookup)
  }, [
                                blockTree,
                                blockLookup
                              ]);

  const refreshBlockTree = () => {
    if (user) {
      setBlockTreeLoading(true)
      getBlockTree({blockId}).then(({blockTree, blockLookup}) => {
        setBlockTree(blockTree);
        setBlockTreeLoading(false)
        setBlockLookup(blockLookup)
      }).catch(error => {
        console.error("Error fetching block tree:", error);
        setBlockTreeLoading(false);
      });
    }
  };

  useEffect(() => {
    if (!user) return;
    refreshBlockTree();
  }, [user, blockId]);

  if (blockTreeLoading) {
    return <LinearProgress/>
  }

  return (
    <BlockTreeContext.Provider
      value={{
        blockTree,
        refreshBlockTree,
        blockTreeLoading: blockTreeLoading,
        setBlockTreeLoading: setBlockTreeLoading,
        blockLookup,
        inputBlocks,
        isBlockTreeContextProvided: true
      }}>
      {children}
    </BlockTreeContext.Provider>
  );
};
