import React, {useCallback, useMemo} from 'react';
import {
  SchemaTraceBlockDetailsWithChildren
} from "./SchemaTraceBlockDetailsWithChildren";
import {TreeNode} from './BlockTreeMapView';
import {SchemaTrace} from "tobl-data-schema/dist/schema/trace";
import BlockTreeMapFlow from "./BlockTreeMapFlow";

interface MindMapProps {
  trace: SchemaTrace
}

const BlockTreeMap: React.FC<MindMapProps> = ({trace}) => {

  const convertToTreeData = useCallback((node: SchemaTraceBlockDetailsWithChildren): TreeNode => {
    return {
      blockId: node.blockId,
      runId: node.runId,
      children: node.children.map(convertToTreeData)
    };
  }, []);

  const buildTreeRecursively = useCallback((nodeId: string, lookup: {
    [key: string]: SchemaTraceBlockDetailsWithChildren
  }): SchemaTraceBlockDetailsWithChildren | null => {
    const node = lookup[nodeId];
    if (!node) return null;

    node.children = [];

    const sortedBlockIds = Object.keys(lookup).sort((a, b) => a.localeCompare(b));
    for (const blockId of sortedBlockIds) {
      if (lookup[blockId].previousBlockId === nodeId) {
        const childNode = buildTreeRecursively(blockId, lookup);
        if (childNode) {
          node.children.push(childNode);
        }
      }
    }

    return node;
  }, []);

  const blockTree = useMemo(() => {
    if (!trace) return null;

    const lookup: { [key: string]: SchemaTraceBlockDetailsWithChildren } = {};
    for (const key in trace.blocks) {
      lookup[key] = {...trace.blocks[key], children: [], blockId: key};
    }

    let rootId = '';
    for (const key in lookup) {
      if (lookup[key].previousBlockId === 'triggered') {
        rootId = key;
        break;
      }
    }
    return buildTreeRecursively(rootId, lookup);
  }, [trace, buildTreeRecursively]);

  const treeData = useMemo(() => {
    if (!blockTree) return null;
    return convertToTreeData(blockTree);
  }, [blockTree, convertToTreeData]);

  if (!trace || !treeData) {
    return null;
  }

  return <BlockTreeMapFlow treeData={treeData}/>;
};

export default BlockTreeMap;
