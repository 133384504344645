import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog'; // Changed from Drawer to Dialog
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TuneIcon from '@mui/icons-material/Tune';
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme
} from "@mui/material";
import {useGetBlock} from "../../../hooks/useGetBlock";
import ManageBlockUsersView from "../../../views/block/ManageBlockUsersView";
import DialogContent from "@mui/material/DialogContent";
import {useTranslation} from "react-i18next";
import ManageBlockSettingView
  from "../../../views/block/ManageBlockSettingView";

interface Props {
  blockId: string;
}

const ManageTeamMenuItem: React.FC<Props> = ({blockId}) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const {t} = useTranslation();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    event.stopPropagation();
    setSelectedTab(newValue);
  };

  const handleClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    setOpen(false);
  };

  const {block} = useGetBlock(blockId);

  const handleClick = (event: React.MouseEvent) => {
    setOpen(true);
    event.stopPropagation();
  };

  return (
    <>
      <MenuItem
        color="inherit"
        onClick={handleClick}
      >
        <ListItemIcon>
          <TuneIcon fontSize={'small'}/>
        </ListItemIcon>
        <ListItemText>
          Manage Team
        </ListItemText>
      </MenuItem>
      {open && <Dialog
          open={open}
          fullWidth
          maxWidth="sm"
          onClose={handleClose}
          fullScreen={fullScreen}
      >
          <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'end',
                padding: '15px 10px 0'
              }}
          >
              <IconButton
                  color="primary"
                  aria-label="close"
                  onClick={handleClose}
                  style={{
                    color: theme.palette.text.primary,
                    width: '40px',
                    height: '40px',
                  }}
              >
                  <CloseIcon fontSize={'medium'}/>
              </IconButton>
          </Box>

          <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              textColor="inherit"
              variant={'fullWidth'}
              sx={{
                '& .MuiTabs-indicator': {
                  height: '1px',
                  backgroundColor: theme.palette.text.primary,
                },
              }}
          >
            {/*<Tab label={t('Blocks')}/>*/}
              <Tab label={t('settings')}/>
              <Tab label={t('Members')}/>
          </Tabs>

          <DialogContent sx={{margin: 0, padding: 0}}>
              <Box height={'100vh'} maxHeight={'750px'} mt={2}
              >
                {/*{selectedTab === 0 && (*/}
                {/*  <Box mt={'15px'} pl={'10px'} pb={'20px'}>*/}
                {/*    {block && <ManageBlockTreeView*/}
                {/*      parentBlockId={block.parentBlockIds[0]}*/}
                {/*      block={block}*/}
                {/*      defaultOpen={true}*/}
                {/*    />}*/}
                {/*  </Box>*/}
                {/*)}*/}

                {selectedTab === 0 && (
                  <Box>
                    {block && <ManageBlockSettingView block={block}/>}
                  </Box>
                )}

                {selectedTab === 1 && (
                  <Box>
                    <ManageBlockUsersView parentBlockId={blockId}/>
                  </Box>
                )}

              </Box>
          </DialogContent>
      </Dialog>}
    </>
  );
};

export default ManageTeamMenuItem;
