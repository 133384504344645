// Define the schema for the generateInvite request
import {firebaseFunctions} from "../firebase/firebase";
import {httpsCallable} from "firebase/functions";

export type GenerateInviteRequest = {
  blockId: string;
};

// Define the schema for the generateInvite response
export interface GenerateInviteResponse {
  inviteToken: string;
}

// Define the schema for the verifyInvite request
export type VerifyInviteRequest = {
  token: string;
};

// Define the schema for the verifyInvite response
export interface VerifyInviteResponse {
  message: string;
  error?: string;
}

// Create a reference to the generateInvite cloud function
const generateInviteFunction = httpsCallable(
  firebaseFunctions,
  "block-generateInvite"
);

// Create a reference to the verifyInvite cloud function
const verifyInviteFunction = httpsCallable(
  firebaseFunctions,
  "block-verifyInvite"
);

// Define the function to generate an invite
export async function generateInvite(request: GenerateInviteRequest): Promise<GenerateInviteResponse> {
  const response = await generateInviteFunction(request);
  return response.data as GenerateInviteResponse;
}

// Define the function to verify an invite
export async function verifyInvite(request: VerifyInviteRequest): Promise<VerifyInviteResponse> {
  const response = await verifyInviteFunction(request);
  return response.data as VerifyInviteResponse;
}
