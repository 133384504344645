import React from 'react';
import {Box, Button, Typography} from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import Logo from "../../components/top_bar/Logo";
import {useGlobalContext} from "../../hooks/GlobalContext";
import {useTranslation} from 'react-i18next';

export function LoginView() {
  const handleLogin = useGlobalContext().handleLogin;
  const {t} = useTranslation();

  return (
    <div style={{height: '100vh', width: '100vw'}}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        height="100%"
        margin={"auto"}
        maxWidth={600}
      >
        <Logo size={36}/>
        <Box padding={"100px 20px"}>
          <Box
            sx={{
              direction: "column",
              alignItems: "center",
              justifyContent: "center",
              maxWidth: 600,
              margin: "auto",
            }}
          >
            <Typography
              variant="h2"
              align="center"
            >
              {t('welcomeMessage')}
            </Typography>
            <Typography
              variant="h5"
              align="center"
              sx={{margin: '40px 0px 100px'}}
            >
              {t("Welcome to tobl.ai, where the future of automation is at your fingertips!")}
            </Typography>
            <Button
              variant="outlined"
              size={'large'}
              startIcon={<GoogleIcon/>}
              onClick={handleLogin}
              sx={{width: '100%'}}
            >
              {t('login_with_google')}
            </Button>
          </Box>
        </Box>
      </Box>
    </div>
  );
}
