import TextField from "@mui/material/TextField";
import React from "react";
import {useTranslation} from "react-i18next";
import {
  BlockType_Processor,
  SchemaBlock_Processor_Config,
} from "tobl-data-schema/dist/schema/block/block_type/processor";
import {BlockEditFormRow} from "./BlockEditFormRow";

export function EditFormProcessorText({
                                        configuration,
                                        updateConfigData,
                                      }: {
  configuration: SchemaBlock_Processor_Config,
  updateConfigData: (key: string, value: any) => void
}) {
  const {t} = useTranslation();

  return (
    <>
      {configuration.blockType_Processor === BlockType_Processor.text_slicer && (
        <>
          <BlockEditFormRow label={t("First Characters")}>
            <TextField
              onKeyDown={e => e.stopPropagation()}
              onKeyUp={e => e.stopPropagation()}
              type="text"
              value={configuration.firstCharacters || 0}
              onChange={(e) => updateConfigData('firstCharacters', Number(e.target.value))}
              fullWidth
            />
          </BlockEditFormRow>

          <BlockEditFormRow label={t("Last Characters")}>
            <TextField
              onKeyDown={e => e.stopPropagation()}
              onKeyUp={e => e.stopPropagation()}
              type="text"
              value={configuration.lastCharacters || 0}
              onChange={(e) => updateConfigData('lastCharacters', Number(e.target.value))}
              fullWidth
            />
          </BlockEditFormRow>
        </>
      )}

      {configuration.blockType_Processor === BlockType_Processor.text_chunker && (
        <BlockEditFormRow label={t("Chunk Size")}>
          <TextField
            onKeyDown={e => e.stopPropagation()}
            onKeyUp={e => e.stopPropagation()}
            type="number"
            value={configuration.chunkSize || 0}
            onChange={(e) => updateConfigData('chunkSize', Number(e.target.value))}
            fullWidth
          />
        </BlockEditFormRow>
      )}

      {configuration.blockType_Processor === BlockType_Processor.text_splitter && (
        <BlockEditFormRow label={t("Delimiter")}>
          <TextField
            onKeyDown={e => e.stopPropagation()}
            onKeyUp={e => e.stopPropagation()}
            type="text"
            value={configuration.delimiter || ''}
            onChange={(e) => updateConfigData('delimiter', e.target.value)}
            fullWidth
          />
        </BlockEditFormRow>
      )}
    </>
  );
}
