import React from 'react';
import {Box, Button, Typography, useTheme} from '@mui/material';
import {useTranslation} from "react-i18next";

interface Props {
  isYearly: boolean;
  setIsYearly: (isYearly: boolean) => void;
}

export function HomePricingYearlyButton({isYearly, setIsYearly}: Props) {
  const theme = useTheme();
  const {t} = useTranslation();

  return (
    <Box display="flex" justifyContent="start" marginBottom={2}>
      <Button
        size={'small'}
        variant={"outlined"}
        sx={{
          color: isYearly ? theme.palette.text.primary : theme.palette.background.default,
          backgroundColor: isYearly ? 'transparent' : theme.palette.text.primary
        }}
        onClick={(e) => {
          e.stopPropagation();
          setIsYearly(false);
        }}
      >
        {t("monthly")}
      </Button>
      <Button
        size={'small'}
        variant={"outlined"}
        sx={{
          marginLeft: '-1px',
          color: isYearly ? theme.palette.background.default : theme.palette.text.primary,
          backgroundColor: isYearly ? theme.palette.text.primary : 'transparent'
        }}
        onClick={(e) => {
          e.stopPropagation();
          setIsYearly(true);
        }}
      >
        {t("yearly")}
      </Button>
      <Typography
        color={theme.palette.error.main}
        ml={'5px'}
      >
        {"← " + t("2 months on us!")}
      </Typography>
    </Box>
  );
}
