import {collection, doc, getDocs, limit, orderBy, query, where} from "firebase/firestore";
import * as XLSX from 'xlsx';
import {COLLECTION_BLOCKS} from "../../../../models/block/block";
import {db} from "../../../../firebase/firebase";
import {getDocsFromSnapshot} from "../../../../firebase/firestore/getDocsFromSnapshot";
import {
  SchemaBlockDocument,
  SchemaBlockDocument_Json,
  TABLE_DOCUMENTS
} from "tobl-data-schema/dist/schema/block/document/document";
import {SchemaTrace} from "tobl-data-schema/dist/schema/trace";

const FILTER_OUT_VALUES = [
  "",
  "null",
  "Unknown",
  "unknown",
  "undefined",
  "NaN",
  "nan",
  "N/A",
  "n/a",
  "Null",
]
const flattenObject = (obj: any, prefix = ''): any => {
  const flatObj: any = {};
  for (const key in obj) {
    if (FILTER_OUT_VALUES.includes(obj[key])) {
      continue;
    }
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      const newPrefix = prefix ? `${prefix}-${key}` : key;
      const subFlatObj = flattenObject(obj[key], newPrefix);
      Object.assign(flatObj, subFlatObj);
    } else {
      const newKey = prefix ? `${prefix}-${key}` : key;
      flatObj[newKey] = obj[key];
    }
  }
  return flatObj;
};


const fetchAndMergeData = async (
  blockId: string,
  traceId: string
  , uniqueDataKeys: Set<string>
) => {
  const mergedDataForTrace = {};

  const documentSnapshot = await getDocs(
    query(
      collection(db, `blocks/${blockId}/documents`),
      where("traceId", "==", traceId),
      orderBy("updatedAt", "desc"),
      limit(1)
    )
  );

  const blockDocs = getDocsFromSnapshot<SchemaBlockDocument_Json>(documentSnapshot);

  blockDocs.forEach(doc => {
    const {data, ...baseDoc} = doc;

    const parsedData = JSON.parse(data.json || '{}');
    const flatData = flattenObject(parsedData);
    Object.keys(flatData).forEach(key => uniqueDataKeys.add(key));
    Object.assign(mergedDataForTrace, {...baseDoc, ...flatData});
  });

  return mergedDataForTrace;
};

const deepMerge = (obj1: any, obj2: any) => {
  const output = {...obj1};
  Object.keys(obj2).forEach((key) => {
    if (obj2[key] && typeof obj2[key] === 'object') {
      output[key] = obj1[key] ? deepMerge(obj1[key], obj2[key]) : obj2[key];
    } else {
      output[key] = obj2[key];
    }
  });
  return output;
};

const exportDataToXLSX = async (
  traceIds: string[],
  traces: SchemaTrace[],
  updateProgress: (progress: number) => void,
): Promise<void> => {
  const allDocuments: any[] = [];
  const uniqueDataKeys = new Set<string>();

  const allResultBlockIdsSet: Set<string> = new Set();

  const triggerBlockId = traces[0].triggerBlockId;

  traces.forEach(trace => {
    Object.entries(trace.blocks).forEach(([blockId, blockDetails]) => {
      if (blockDetails.isResultBlock) {
        allResultBlockIdsSet.add(blockId);
      }
    });
  });

  const resultBlockIds = Array.from(allResultBlockIdsSet);


  let i = 0;
  for (const traceId of traceIds) {
    i += 1;
    const trace = traces.find(t => t.id === traceId);
    if (!trace) continue;  // Skip if trace not found

    const allPromises = resultBlockIds.map(
      (blockId) =>
        fetchAndMergeData(blockId, traceId, uniqueDataKeys).then(flatData => {
          updateProgress((i / traces.length) * 100);
          return flatData;
        })
    );

    const allFlatData = await Promise.all(allPromises);
    const mergedDataForTrace = allFlatData.reduce((acc, curr) => deepMerge(acc, curr), {});
    allDocuments.push(mergedDataForTrace);
  }


  const ws_data: any[][] = [
    [
      'Document ID',
      'Trace ID',
      'Run ID',
      'File Name',
      'DocumentType',
      ...Array.from(uniqueDataKeys),
    ],
  ];

  // Populate the worksheet data using the flattened documents
  for (const document of allDocuments) {
    // get document in blocks/triggerBlockId/documents where traceId = traceId and runId = runId
    const triggerBlockDocumentQuery =
      query(
        collection(
          doc(COLLECTION_BLOCKS, triggerBlockId),
          TABLE_DOCUMENTS,
        ),
        where("traceId", "==", document.traceId),
        orderBy("updatedAt", "desc"),
        limit(1)
      )

    const triggerBlockDocument =
      (await getDocs(triggerBlockDocumentQuery)
        .then(getDocsFromSnapshot<SchemaBlockDocument>))[0];

    const rowData = [
      document.id,
      document.traceId,
      document.runId,
      triggerBlockDocument.title ?? '',
      document.documentType,
    ];

    Array.from(uniqueDataKeys).forEach((key) => {
      rowData.push(document[key] || '');  // Removed JSON.stringify
    });

    ws_data.push(rowData);
  }

  const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(ws_data);
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  XLSX.writeFile(wb, `exported_brainy_blocks.xlsx`);
};

export default exportDataToXLSX;
