import React, {useState} from 'react';
import {Box, Button, LinearProgress, useTheme} from '@mui/material';
import {useGlobalContext} from "../../hooks/GlobalContext";
import HomePricing from '../../components/home/HomePricing';
import HomeTitle from '../../components/home/HomeTitle';
import {useTranslation} from "react-i18next";
import ContactView from "../app/ContactView";
import MainView from "./MainView";

const MainViewPricingScreen: React.FC = () => {
  const theme = useTheme();
  const globalContext = useGlobalContext();
  const userTopBlocks = globalContext?.userTopBlocks || [];
  const {t} = useTranslation();
  const [isContactOpen, setIsContactOpen] = useState(false);

  if (!userTopBlocks) {
    return <Box style={{padding: 20}}><LinearProgress/></Box>;
  }

  return (
    <MainView
      title={t('Pricing')}
      videoUrl={'https://firebasestorage.googleapis.com/v0/b/the-team-os.appspot.com/o/main_background%2Fpexels-yauheni-vasilevich-7101912%20(1440p).mp4?alt=media&token=4da8117d-2221-407e-ae6f-527e4a2329b9'}
    >
      <Box maxWidth={1280} margin={'auto'} pt={'60px'}>
        <HomePricing/>
        <HomeTitle text={t('Get started with tobl.ai')}/>
        <div style={{padding: '20px'}}>
          <Button
            size={'large'}
            variant={'contained'}
            children={t("Get Started")}
            onClick={() => {
              setIsContactOpen(true);
            }}
          />
        </div>
      </Box>

      <ContactView
        isOpen={isContactOpen}
        onClose={() => {
          setIsContactOpen(false);
        }}
      />
    </MainView>
  );
}

export default MainViewPricingScreen;
