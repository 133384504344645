import React from 'react';
import {ListItemIcon, ListItemText, MenuItem} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {BlockType, SchemaBlock} from "tobl-data-schema/dist/schema/block/block";
import {useTranslation} from "react-i18next";
import {deleteBlock} from "../../../cloud_functions/deleteBlock";
import {useBlockTree} from "../../../hooks/useBlockTree";
import {ManageButton} from "../../../views/block/ManageBlockSettingView";
import {arrayRemove, doc, updateDoc} from "firebase/firestore";
import {COLLECTION_USER_TOP_BLOCKS} from "../../../models/user_top_blocks";
import {useGlobalContext} from "../../../hooks/GlobalContext";
import {useNavigate} from "react-router-dom";

interface Props {
  block: SchemaBlock;
  isTeamBlock?: boolean;
}

const BlockMenuDeleteButton: React.FC<Props> = ({block, isTeamBlock}) => {
  const {t} = useTranslation();
  const {refreshBlockTree} = useBlockTree();
  const init = useGlobalContext();
  const userId = init.user?.uid;
  const navigate = useNavigate();

  const handleDeleteBlock = async (event: React.MouseEvent) => {
    event.stopPropagation();
    const userConfirmed = window.confirm(t('Are you sure you want to delete this block?'));

    if (!userConfirmed) {
      return;
    }

    try {
      if (block.blockType === BlockType.team || block.blockType === BlockType.project) {
        const userTopBlockRef = doc(COLLECTION_USER_TOP_BLOCKS, userId);
        navigate('/admin');
        updateDoc(userTopBlockRef, {
          topBlockIds: arrayRemove(block.id)
        });
      }
      await deleteBlock({blockId: block.id});
      console.log(`Block with ID: ${block.id} was deleted successfully.`);
      refreshBlockTree();
    } catch (error) {
      console.error('Error occurred while deleting block:', error);
    }
  };

  return (
    <>
      {(isTeamBlock
        ? <ManageButton
          icon={<DeleteIcon/>}
          title={t('Delete Team')}
          description={t("If you delete the team, you cannot recover it.")}
          buttonTitle={t('Delete')}
          onClick={(event) => handleDeleteBlock(event)}
        />
        : <MenuItem
          onClick={(event) => handleDeleteBlock(event)}
        >
          <ListItemIcon>
            <DeleteIcon color={'primary'}/>
          </ListItemIcon>
          <ListItemText primary={t('Delete Block')}/>
        </MenuItem>)
      }
    </>
  );
};

export default BlockMenuDeleteButton;
