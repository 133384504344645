import React, {useEffect, useState} from 'react';
import {
  Box,
  Button,
  LinearProgress,
  useMediaQuery,
  useTheme
} from '@mui/material';
import HomeWelcome from "../../components/home/HomeWelcome";
import {useGlobalContext} from "../../hooks/GlobalContext";
import Teams from "../../components/block/team/TeamView";
import {BlockTreeProvider} from '../../hooks/useBlockTree';
import HomePricing from '../../components/home/HomePricing';
import HomeTitle from '../../components/home/HomeTitle';
import {useTranslation} from "react-i18next";
import ContactView from "./ContactView";
import HomeIntroduce from '../../components/home/HomeIntroduce';
import HomeUseCase from '../../components/home/HomeUseCase';
import {copyBlockTree} from "../../cloud_functions/copyBlockTree";
import {useSnackbar} from "../../hooks/useSnackbar";

const SAMPLE_BLOCK_ID = "ZnYNqqy76ED1WBrmJ7S3";
const HomeView: React.FC = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const {user, loading, userTopBlocks, handleLogin} = useGlobalContext();
  const {t} = useTranslation();
  const [isContactOpen, setIsContactOpen] = useState(false);

  const {showSnackbar} = useSnackbar();


  useEffect(
    () => {
      if (user && userTopBlocks && !loading && userTopBlocks.length === 0) {
        showSnackbar("Welcome to tobl.ai! We're creating a sample project for" +
                       " you to get started with.");
        copyBlockTree({
                        sourceBlockId: SAMPLE_BLOCK_ID
                      });
      }
    },
    [user, userTopBlocks, loading]
  )

  useEffect(() => {
    if (!user) {
      handleLogin();
    }
  }, [user]);


  if (!userTopBlocks) {
    return <Box style={{padding: 20}}><LinearProgress/></Box>;
  }

  return (
    <Box
      maxWidth={1440}
      margin="auto"
      padding={fullScreen ? '0 20px' : '40px 0px 0px'}
    >
      <Box mb={'100px'}>
        <HomeWelcome/>
        {userTopBlocks.length < 1
          ? (
            <>
              <HomeIntroduce/>
              <div style={{padding: '0px 20px 100px'}}>
                <Button
                  size={'large'}
                  variant={'outlined'}
                  children={t("Get Started")}
                  onClick={() => {
                    setIsContactOpen(true);
                  }}
                />
              </div>
              <HomeUseCase/>
              <HomePricing/>
            </>
          ) : (
            <Box
              sx={{padding: fullScreen ? '0px 0px 40px' : '10px 0 40px'}}>
              <HomeTitle text={t('teams')}/>
              {userTopBlocks.map((block) => (
                block && (
                  <BlockTreeProvider key={block.id} blockId={block.id}>
                    <Teams block={block} key={block.id}/>
                  </BlockTreeProvider>
                )
              ))}
            </Box>
          )
        }
      </Box>
      {userTopBlocks.length < 1 && (
        <>
          <HomeTitle text={t('Get started with tobl.ai')}/>
          <div style={{padding: '20px'}}>
            <Button
              size={'large'}
              variant={'contained'}
              children={t("Get Started")}
              onClick={() => {
                setIsContactOpen(true);
              }}
            />
          </div>
        </>
      )}
      <Box mt={'100px'}></Box>
      <ContactView
        isOpen={isContactOpen}
        onClose={() => {
          setIsContactOpen(false);
        }}
      />
    </Box>
  );
}

export default HomeView;
