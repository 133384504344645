import {useEffect, useState} from "react";
import {doc, getDoc} from "firebase/firestore";
import {parseDoc} from "../firebase/firestore/parseDoc";
import {SchemaBlock} from "tobl-data-schema/dist/schema/block/block";
import {COLLECTION_BLOCKS} from "../models/block/block";

export const useGetBlock = (blockId: string | undefined) => {
  const [block, setBlock] = useState<SchemaBlock>();
  const [blockLoading, setBlockLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    if (!blockId) {
      return;
    }
    setBlockLoading(true);
    setError(undefined);

    getDoc(doc(COLLECTION_BLOCKS, blockId))
      .then(parseDoc<SchemaBlock>)
      .then(setBlock)
      .catch(setError)
      .catch(console.error)
      .finally(() => setBlockLoading(false));
  }, [blockId]);

  return {block, blockLoading, error};
};
