import React, {useState} from 'react';
import {Box, CircularProgress, Dialog, useMediaQuery, useTheme} from '@mui/material';
import {useTranslation} from "react-i18next";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import {SchemaBlock} from "tobl-data-schema/dist/schema/block/block";
import {CreatePortalLinkRequest} from "tobl-data-schema/dist/api/stripe/createPortalLink";
import {useStripeSubscription} from "../../../hooks/useStripeSubscription";
import {createPortalLink} from "../../../cloud_functions/createPortalLink";
import {ManageButton} from "../../../views/block/ManageBlockSettingView";
import {TeamSubscriptionDetail} from './TeamSubscriptionDetail';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export function TeamSubscriptionButton({teamBlock}: { teamBlock: SchemaBlock }) {
  const theme = useTheme();
  const subscriptionStatus = useStripeSubscription(teamBlock.id);
  const {t} = useTranslation();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isOpeningStripePortal, setIsOpeningStripePortal] = useState(false);

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsDialogOpen(false);
  };

  const handleOpenStripePortal = async () => {
    setIsOpeningStripePortal(true);

    const request: CreatePortalLinkRequest = {
      teamBlockId: teamBlock.id,
      returnUrl: window.location.origin,
      locale: "auto",
    };

    try {
      const {url} = await createPortalLink(request);
      window.location.href = url;
    } catch (error) {
      console.error("Error opening Stripe portal:", error);
    } finally {
      setIsOpeningStripePortal(false);
    }
  };

  return (
    <>
      <ManageButton
        icon={isOpeningStripePortal ?
          <CircularProgress size={22}/> : subscriptionStatus ?
            <CheckCircleIcon/> :
            <MonetizationOnIcon/>}
        title={t("Plan and Billing")}
        description={subscriptionStatus?.cancel_at_period_end ? `${t("will cancel at")} ${subscriptionStatus.cancel_at.toDate().toLocaleDateString()}` : t("Manage your plan, billing details, and more.")}
        buttonTitle={subscriptionStatus ? t("Go to Billing") : t("start_subscription")}
        onClick={(e) => {
          e.stopPropagation();
          if (subscriptionStatus) {
            handleOpenStripePortal();
          } else {
            handleDialogOpen();
          }
        }}
      />
      <Dialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        fullWidth
        maxWidth={'xs'}
        fullScreen={fullScreen}
        aria-labelledby="subscription-dialog-title"
        aria-describedby="subscription-dialog-description"
      >
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
            padding: '15px 10px 0'
          }}
        >
          <IconButton
            color="primary"
            aria-label="close"
            onClick={handleDialogClose}
            style={{
              width: '40px',
              height: '40px',
            }}
          >
            <CloseIcon fontSize={'medium'} color={"primary"}/>
          </IconButton>
        </Box>
        <TeamSubscriptionDetail
          teamBlock={teamBlock}
          isDialogOpen={isDialogOpen}
          handleDialogClose={handleDialogClose}
        />
      </Dialog>
    </>
  );
}
