import React from 'react';
import {Box, Typography} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {useNavigate, useParams} from "react-router-dom";
import {SchemaBlock} from "tobl-data-schema/dist/schema/block/block";
import {useGlobalContext} from "../../hooks/GlobalContext";

interface MainDrawerBlockListProps {
  parentBlockId: string;
}

export default function MainDrawerProjectList({parentBlockId}: MainDrawerBlockListProps) {
  const {projectBlocks} = useGlobalContext();
  const navigate = useNavigate();
  const {blockId} = useParams<{ blockId: string }>();

  if (!projectBlocks || !projectBlocks[parentBlockId]) {
    return null;
  }

  const blocks = projectBlocks[parentBlockId];

  function item(block: SchemaBlock) {
    if (!block) return null;

    const handleClick = () => {
      navigate(`/admin/${block.id}`);
    };

    return (
      <MenuItem
        key={block.id}
        onClick={handleClick}
        selected={blockId === block.id}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: '20px 5px 20px 55px',
          margin: '0px',
          height: '40px'
        }}
      >
        <Typography
          variant={'subtitle2'}
          color={blockId !== block.id ? 'inherit' : 'primary'}
          textAlign={'left'}
          flexGrow={1}
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {block.title}
        </Typography>

      </MenuItem>
    );
  }

  const sortedBlocks = [...blocks].sort((a, b) => a.createdAt.toMillis() - b.createdAt.toMillis());

  return (
    <Box>
      {sortedBlocks?.map((block) => item(block))}
    </Box>
  );
}
