import React from 'react';
import {Box, CardMedia, Grid, Typography} from '@mui/material';
import {useInView} from 'react-intersection-observer';
import {useTranslation} from "react-i18next";
import {SectionsItem} from "./HomeIntroduce";

type SectionItemProps = {
  item: SectionsItem;
  index: number;
  largeScreen: boolean;
};

export const HomeIntroduceItem: React.FC<SectionItemProps> = ({item, index, largeScreen}) => {
  const {t} = useTranslation();

  const textGradient = {
    background: 'linear-gradient(45deg, #FF6B6B 30%, #FF8E53 50%, #FFC107 90%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    backgroundClip: 'text',
    textFillColor: 'transparent'
  };

  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '0px -50px',
  });

  const style = inView ? {
    opacity: 1,
    transform: 'translateY(0)',
    transition: 'opacity 1.5s ease-out, transform 1.5s ease-out'
  } : {
    opacity: 0,
    transform: 'translateY(50px)',
  };

  return (
    <Grid container spacing={4} mb={"60px"}
      alignItems={"center"}
      direction={index % 2 === 0 ? "row" : "row-reverse"}
    >
      <Grid item xs={12} sm={12} md={6}>
        <Box sx={{padding: '0px 40px'}}>
          <CardMedia
            component="img"
            image={item.imageUrl}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={6} ref={ref} style={style}>
        <Box sx={{padding: largeScreen ? '0px 0px' : 0}}>
          <Typography
            variant="h3"
            mb={"20px"}
            sx={{
              lineHeight: 1.2,
              ...textGradient
            }}
          >
            {t(item.title)}
          </Typography>
          <Typography variant="body1">
            {t(item.description)}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
