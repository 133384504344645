import React from 'react';
import {useThemeContext} from "../../theme/ThemeContextProvider";
import {IconButton, ListItemIcon, Menu, MenuItem, Typography, useTheme} from "@mui/material";
import {useTranslation} from "react-i18next";
import NightsStayIcon from '@mui/icons-material/NightsStay';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import BrightnessAutoIcon from '@mui/icons-material/BrightnessAuto';
import {GRAY} from "../../theme/colors";

interface Props {
  isDarkMode?: boolean;
}

const TopBarScreenModeButton = ({isDarkMode}: Props) => {
  const theme = useTheme();
  const {t} = useTranslation();
  const {mode, setMode, effectiveMode, setEffectiveMode} = useThemeContext(); // effectiveMode와 setEffectiveMode 가져오기
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleModeChange = (newMode: 'dark' | 'light' | 'auto') => {
    setMode(newMode);
    if (newMode !== 'auto') {
      setEffectiveMode(newMode); // 'auto'가 아닌 경우에만 effectiveMode를 업데이트
    }
    handleClose();
  };


  return (
    <div>
      <IconButton
        aria-label="theme"
        aria-controls="theme-menu"
        aria-haspopup="true"
        onClick={handleClick}
        sx={{marginRight: '15px', color: isDarkMode ? GRAY.w100 : theme.palette.text.primary}}
      >
        {mode === 'auto' ? (
          <BrightnessAutoIcon/>
        ) : effectiveMode === 'dark' ? (
          <NightsStayIcon/>
        ) : (
          <WbSunnyIcon/>
        )}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: '20ch',
          },
        }}
      >
        <MenuItem selected={mode === 'light'} onClick={() => handleModeChange('light')}>
          <ListItemIcon children={<WbSunnyIcon
            sx={{color: mode === 'light' ? theme.palette.primary.main : theme.palette.text.secondary}}
          />}/>
          <Typography color={mode === 'light' ? theme.palette.primary.main : theme.palette.text.secondary}>
            {t('light_mode')}
          </Typography>
        </MenuItem>
        <MenuItem selected={mode === 'dark'} onClick={() => handleModeChange('dark')}>
          <ListItemIcon children={<NightsStayIcon
            sx={{color: mode === 'dark' ? theme.palette.primary.main : theme.palette.text.secondary}}
          />}/>
          <Typography color={mode === 'dark' ? theme.palette.primary.main : theme.palette.text.secondary}>
            {t('dark_mode')}
          </Typography>
        </MenuItem>
        <MenuItem selected={mode === 'auto'} onClick={() => handleModeChange('auto')}>
          <ListItemIcon children={<BrightnessAutoIcon
            sx={{color: mode === 'auto' ? theme.palette.primary.main : theme.palette.text.secondary}}
          />}/>
          <Typography color={mode === 'auto' ? theme.palette.primary.main : theme.palette.text.secondary}>
            {t('auto_mode')}
          </Typography>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default TopBarScreenModeButton;
