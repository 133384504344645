import React from "react";
import TextField from "@mui/material/TextField";
import {IsResultBlockSwitch} from "./IsResultBlockSwitch";
import EditFormProject from "./EditFormProject";
import EditFormInput from "./EditFormInput";
import {Box} from "@mui/material";
import {
  BlockType,
  SchemaBlockEdit
} from "tobl-data-schema/dist/schema/block/block";
import {
  SchemaBlock_Project_Config
} from "tobl-data-schema/dist/schema/block/block_type/project";
import {
  SchemaBlock_Input_Config
} from "tobl-data-schema/dist/schema/block/block_type/input";
import {EditFormProcessor} from "./EditFormProcessor";
import EditFormTeam from "./EditFormTeam";
import {
  SchemaBlock_Team_Config
} from "tobl-data-schema/dist/schema/block/block_type/team";
import {
  SchemaBlock_Processor_Config
} from "tobl-data-schema/dist/schema/block/block_type/processor";
import {AddEditMode} from "../menu/BlockMenuAddOrEditDialog";
import {
  BlockDocumentType
} from "tobl-data-schema/dist/schema/block/document/document";
import {BlockEditFormRow} from "./BlockEditFormRow";
import {useBlockEditorContext} from "../menu/BlockEditorContext";

interface BlockEditFormProps {
  parentOutputType?: BlockDocumentType;
  updateBlockData: (key: string, value: any) => void;
}

function renderBlockComponent(
  blockId: string,
  blockData: SchemaBlockEdit,
  updateBlockData: any,
  updateConfigData: any,
  addEditMode: AddEditMode,
  parentOutputType?: BlockDocumentType,
): JSX.Element {
  switch (blockData.blockType) {
    case BlockType.team:
      return (
        <EditFormTeam
          configuration={blockData.configuration as SchemaBlock_Team_Config}
          updateConfigData={updateConfigData}
          addEditMode={addEditMode}
        />
      );
    case BlockType.project:
      return (
        <EditFormProject
          configuration={blockData.configuration as SchemaBlock_Project_Config}
          updateConfigData={updateConfigData}
          addEditMode={addEditMode}
        />
      );
    case BlockType.input:
      return (
        <EditFormInput
          configuration={blockData.configuration as SchemaBlock_Input_Config}
        />
      );
    case BlockType.processor:
      return (
        <EditFormProcessor
          blockData={blockData}
          configuration={blockData.configuration as SchemaBlock_Processor_Config}
          updateBlockData={updateBlockData}
          updateConfigData={updateConfigData}
          addEditMode={addEditMode}
          parentOutputType={parentOutputType}
        />
      );
    default:
      return <></>;
  }
}

export function BlockEditForm(
  {
    parentOutputType,
    updateBlockData,
  }: BlockEditFormProps) {

  const {
    blockData,
    addEditMode,
    blockId,
    updateConfigData
  } = useBlockEditorContext();

  return (
    <>
      <BlockEditFormRow key="Name" label="Name">
        <TextField
          value={blockData.title}
          onChange={e => {
            e.stopPropagation();
            updateBlockData("title", e.target.value)
          }}
          onKeyDown={e => e.stopPropagation()}
          onKeyUp={e => e.stopPropagation()}
          fullWidth
          onClick={e => e.stopPropagation()}  // Stop propagation here
        />
      </BlockEditFormRow>
      {blockData.blockType === BlockType.processor && (
        <BlockEditFormRow key="resultBlock" label="Result Block">
          <Box padding={'10px 15px'} onClick={e => e.stopPropagation()}
          >
            <IsResultBlockSwitch
              isResultBlock={blockData.isResultBlock}
              onChange={value => updateBlockData("isResultBlock", value)}
            />
          </Box>
        </BlockEditFormRow>
      )}

      {renderBlockComponent(blockId, blockData, updateBlockData, updateConfigData, addEditMode, parentOutputType)}

    </>
  );
}
