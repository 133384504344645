import {useParams} from "react-router-dom";
import useAliasToAppId from "../../hooks/useAliasToAppId";
import {AppType} from "../../models/apps";
import React, {useEffect} from "react";
import EmailSenderAppView from "./app_views/EmailSenderAppView";
import {Box} from "@mui/material";
import useGetApp from "../../hooks/useGetApp";
import {useGlobalContext} from "../../hooks/GlobalContext";
import {auth} from "../../firebase/firebase";
import {signInAnonymously} from "@firebase/auth";
import XlsxAggregatorAppView from "./app_views/XlsxAggregatorAppView";
import DiffInputAppView from "./app_views/DiffInputAppView";

export default function AppView() {
  const {appId} = useParams();

  const {appId: appIdByAlias, loading} = useAliasToAppId(appId);
  const resolvedAppId = appIdByAlias || appId

  const {app, loading: appLoading} = useGetApp(resolvedAppId);

  const globalContext = useGlobalContext();

  useEffect(() => {
    if (!globalContext.user) {
      signInAnonymously(auth);
    }

  }, [globalContext.user]);

  if (!appId || loading || appLoading || !globalContext.user) {
    return null;
  }

  switch (app?.appType) {
    case AppType.EMAIL_SENDER:
      return <EmailSenderAppView app={app}/>;
    case AppType.XLSX_AGGREGATOR:
      return <XlsxAggregatorAppView app={app}/>;

    case AppType.TEXT_DIFF_INPUTS:
      return <DiffInputAppView app={app}/>;
    default:
      return <Box>Unknown app type</Box>;
  }
}
