import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {Typography, useTheme} from "@mui/material";
import ManageBlockTreeView from "../../../views/block/ManageBlockTreeView";
import {useTranslation} from "react-i18next";
import {SchemaBlock} from "tobl-data-schema/dist/schema/block/block";

interface Props {
  block: SchemaBlock;
  open: boolean;
  setOpen: (open: boolean) => void;
}

const ManageBlockModal: React.FC<Props> = ({block, open, setOpen}) => {
  const theme = useTheme();
  const {t} = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  if (!open) {
    return null;
  }

  return (
    <Box
      width={'100%'}
      height={'100%'}
      bgcolor={theme.palette.background.default}
    >
      <Box
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        height='60px'
        padding={'10px'}
        pl={'20px'}
      >
        <Typography variant={'h6'}>
          {t('Tree of Blocks')}
        </Typography>
        <IconButton
          onClick={handleClose}
        >
          <CloseIcon/>
        </IconButton>
      </Box>

      <Box
        width={'100%'}
        height={'100%'}
        margin={'auto'}
        sx={{p: 5, pt: 0, pb: 10}}
        overflow={'auto'}
      >
        <ManageBlockTreeView
          blockId={block.id}
        />
      </Box>
    </Box>

  );
};

export default ManageBlockModal;
