// src/views/app/VoiceInputAppView.tsx
import React, {useEffect, useState} from 'react';
import {SchemaAppXlsxAggregator} from "../../../models/apps";
import {Box, LinearProgress, Typography} from '@mui/material';
import UploadFiles from "../../../components/block/inputs/UploadFiles";
import {COLLECTION_TRACES} from "../../../models/trace";
import {doc, onSnapshot} from "firebase/firestore";
import {SchemaTrace} from "tobl-data-schema/dist/schema/trace";
import {parseDoc} from "../../../firebase/firestore/parseDoc";
import PreviewTable from "./PreviewTable";

interface XlsxAggregatorViewProps {
  app: SchemaAppXlsxAggregator;
}

/**
 * Represents the view of the Voice Input App.
 *
 * @component
 * @param {Object} app - The app object containing information about the app.
 * @returns {JSX.Element} - The Voice Input App view.
 */
const XlsxAggregatorAppView: React.FC<XlsxAggregatorViewProps> = ({app}) => {
  const [uploadProgress, setUploadProgress] = useState<number>(0);

  const [trace, setTrace] = useState<SchemaTrace>()
  const handleUploadClose = () => {
  }

  const [traceId, setTraceId] = useState<string>();

  useEffect(() => {
    if (!traceId) {
      return;
    }
    const unsubscribe =
      onSnapshot(
        doc(COLLECTION_TRACES, traceId),
        (doc) => {
          const trace = parseDoc<SchemaTrace>(doc);
          setTrace(trace);
        }
      );

    return () => {
      unsubscribe();
    }
  }, [traceId]);


  console.log(uploadProgress);
  console.log(trace);


  return (
    <Box
      sx={{
        p: 2,
        borderRadius: 1,
        boxShadow: 1,
        height: '100vh',
        marginLeft: 'auto', // set left margin to auto
        marginRight: 'auto', // set right margin to auto
      }}
    >
      <Box
        sx={{
          maxWidth: 600,
          maxHeight: 700,
          margin: 'auto',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 2,
            width: '100%',
          }}
        >
          <img
            src={app.logoImageUrl}
            alt={app.title}
            style={{
              maxWidth: 40,
              maxHeight: 40,
              margin: 20,
            }}
          />
          <Typography variant="h5" component="div">{app.title}</Typography>
        </Box>

        <UploadFiles
          blockId={app.xlsxFileBlockId}
          setProgress={setUploadProgress}
          handleClose={handleUploadClose}
          setTraceId={setTraceId}
        />
        <LinearProgress variant="determinate" value={uploadProgress}/>
        {
          trace && trace.blocks && Object.entries(trace.blocks)
            .filter(([key, block]) => block.isResultBlock)
            .map(([key, block]) => {
              return <PreviewTable blockId={key}
                                   documentIds={block.documentIds}
              />
            })
        }
      </Box>
    </Box>
  );
};

export default XlsxAggregatorAppView;
