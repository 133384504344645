import React, {useState} from 'react';
import {Box, Button, LinearProgress, useTheme} from '@mui/material';
import {useGlobalContext} from "../../hooks/GlobalContext";
import {useTranslation} from "react-i18next";
import ContactView from "../app/ContactView";
import HomeUseCase from '../../components/home/HomeUseCase';
import MainView from "./MainView";
import HomeTitle from "../../components/home/HomeTitle";

const MainViewProductScreen: React.FC = () => {
  const theme = useTheme();
  const globalContext = useGlobalContext();
  const userTopBlocks = globalContext?.userTopBlocks || [];
  const {t} = useTranslation();
  const [isContactOpen, setIsContactOpen] = useState(false);

  if (!userTopBlocks) {
    return <Box style={{padding: 20}}><LinearProgress/></Box>;
  }

  return (
    <MainView
      title={t("Solutions by use case")}
      description={t("AI in Action Across Different Sectors")}
      videoUrl={'https://firebasestorage.googleapis.com/v0/b/the-team-os.appspot.com/o/main_background%2Fhome_welcome.mp4?alt=media&token=578a2fca-4572-4623-bb24-378a2ef52ba8'}
    >
      <Box maxWidth={1280} margin={'auto'} pt={'60px'}>
        <HomeUseCase/>
        <HomeTitle text={t('Get started with tobl.ai')}/>
        <div style={{padding: '20px'}}>
          <Button
            size={'large'}
            variant={'contained'}
            children={t("Get Started")}
            onClick={() => {
              setIsContactOpen(true);
            }}
          />
        </div>
      </Box>

      <ContactView
        isOpen={isContactOpen}
        onClose={() => {
          setIsContactOpen(false);
        }}
      />
    </MainView>
  );
}

export default MainViewProductScreen;
