import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import {
  getDownloadURL,
  getStorage,
  ref,
  StorageReference,
  uploadBytesResumable
} from "firebase/storage";
import Button from '@mui/material/Button';
import {addRun} from "../../../cloud_functions/addRun";
import {Box, SvgIcon, Typography, useTheme} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {v4 as uuidv4} from "uuid";
import {useTranslation} from 'react-i18next';

interface UploadFilesProps {
  blockId: string;
  setProgress: React.Dispatch<React.SetStateAction<number>>;
  handleClose: () => void;
  setTraceId?: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const UploadFiles: React.FC<UploadFilesProps> = (
  {
    blockId,
    setProgress,
    handleClose,
    setTraceId
  }) => {
  const theme = useTheme();
  const [dragging, setDragging] = useState(false);
  const [fileProgress, setFileProgress] = useState<number[]>([]);
  const {t} = useTranslation();

  useEffect(() => {
    const total = fileProgress.reduce((acc, val) => acc + val, 0);
    const average = total / fileProgress.length;
    setProgress(average);
    if (average > 98) handleClose();
  }, [
              fileProgress,
              handleClose,
              setProgress
            ]);

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      handleFileUpload({target: {files}} as ChangeEvent<HTMLInputElement>);
    }
  };

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files) return;

    setFileProgress(new Array(files.length).fill(0));

    const storage = getStorage();

    // Create an array to hold all the upload promises
    const uploadPromises = Array.from(files).map((file, index) => {
      const traceId = uuidv4();
      const storagePath = `${blockId}/files/${traceId}--${file.name}`;
      const storageRef = ref(storage, storagePath);

      if (setTraceId) {
        setTraceId(traceId);
      }

      // Start the upload and add the promise to the array
      return uploadFile(storageRef, file, index)
        .then(uploadMetadata => addRun({
                                         blockId,
                                         traceId,
                                         clientInputData: {
                                           ...uploadMetadata,
                                           title: file.name,
                                         }
                                       }));
    });

    // Wait for all the uploads to complete
    await Promise.all(uploadPromises);
  };

  const uploadFile = (storageRef: StorageReference, file: File, index: number): Promise<{
    fileUrl: string,
    storagePath: string
  }> => {
    return new Promise((resolve, reject) => {
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const currentProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setFileProgress(prevFileProgress => {
            const newProgress = [...prevFileProgress];
            newProgress[index] = currentProgress;
            return newProgress;
          });
        },
        (error) => {
          console.error(error);
          reject(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          console.log('File available at', downloadURL);
          resolve({fileUrl: downloadURL, storagePath: storageRef.fullPath});
        }
      );
    });
  };


  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleBrowseFilesClick = () => {
    // Programmatically inputs the hidden file inputs
    fileInputRef.current?.click();
  };

  return (
    <Box
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      sx={{
        border: `4px dashed ${theme.palette.divider}`,
        backgroundColor: dragging ? theme.palette.secondary.light : 'transparent',
        padding: 6,
        textAlign: 'center',
        maxWidth: 300,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <input
        multiple
        style={{display: 'none'}}
        id="raised-button-file"
        type="file"
        onChange={handleFileUpload}
        ref={fileInputRef}

        // based on the upload type accept different file types
      />
      <SvgIcon component={CloudUploadIcon}
               style={{color: theme.palette.divider, fontSize: 55}}/>
      <Typography variant="h6" marginBottom='10px' marginTop='10px'>
        {t('drag_and_drop')}
      </Typography>
      <Typography variant="body2" marginBottom='20px'
                  color={theme.palette.text.secondary}>
        {t('or')}
      </Typography>
      <Button variant="contained" component="span"
              onClick={handleBrowseFilesClick}>
        {t('browse_files')}
      </Button>
    </Box>
  );
};

export default UploadFiles;
