import {db} from "../firebase/firebase";
import {collection, FieldValue, Timestamp} from "firebase/firestore";

export const APP_ALIAS = "app_alias";
export const APP_ALIAS_COLLECTION = collection(db, APP_ALIAS);

export interface SchemaAppAlias {
  id: string; // alias
  appId: string;
  alias: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

export interface SchemaAppAliasCreate extends Omit<SchemaAppAlias, "createdAt" | "updatedAt"> {
  createdAt: FieldValue;
  updatedAt: FieldValue;
}
