import React from "react";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import {useTranslation} from "react-i18next";

interface IsResultBlockSwitchProps {
  isResultBlock: boolean;
  onChange: (value: boolean) => void;
}

export const IsResultBlockSwitch: React.FC<IsResultBlockSwitchProps> = (
  {
    isResultBlock,
    onChange
  }) => {
  const {t} = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  return (
    <FormControlLabel
      control={
        <Switch
          checked={isResultBlock}
          onChange={handleChange}
          color="primary"
        />
      }
      label={
        isResultBlock ? t("This block generates a final output.") : t("This block generates an intermediate step.")
      }
    />
  );
};
