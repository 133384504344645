// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function removeNullValues(obj: any): any {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === "object") {
      removeNullValues(obj[key]);
    } else if (obj[key] === null || obj[key] === undefined) {
      delete obj[key];
    }
  });

  return obj;
}
