import React from 'react';
import {
  Box,
  Divider,
  LinearProgress,
  List,
  MenuItem,
  Typography,
  useTheme
} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import WidgetsIcon from '@mui/icons-material/Widgets';
import {useGlobalContext} from "../../hooks/GlobalContext";
import MainDrawerProjectList from "./MainDrawerProjectList";
import {BlockType} from "tobl-data-schema/dist/schema/block/block";
import {useTranslation} from "react-i18next";
import TeamBlockContextMenu from "./TeamBlockContextMenu";

export default function MainDrawerTeamList() {
  const theme = useTheme();
  const {
    isAdmin,
    userTopBlocks,
    blockUserRelations,
  } = useGlobalContext();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {blockId} = useParams<{ blockId: string }>();

  if (!userTopBlocks) {
    return <Box style={{padding: 20}}><LinearProgress/></Box>;
  }

  const sortedUserTopBlocks = [...userTopBlocks].sort((a, b) => {
    if (a.blockType === BlockType.project && b.blockType === BlockType.team) {
      return -1; // a comes first
    }
    if (a.blockType === BlockType.team && b.blockType === BlockType.project) {
      return 1; // b comes first
    }
    return 0; // no change in order if block types are the same
  });

  const firstTeamIndex = sortedUserTopBlocks.findIndex(block => block.blockType === BlockType.team);

  return (
    <List sx={{padding: '0 0px'}}>
      {sortedUserTopBlocks.map(
        (block, index) => {
          if (!block) return null;

          const isTeam = block.blockType === BlockType.team;

          const handleClick = () => {
            navigate(`/admin/${block.id}`);
          };

          return (block.blockType === BlockType.team &&
              <Box key={block.id}>
                {isTeam && index === firstTeamIndex && (
                  <Box sx={{padding: '10px 0px 15px'}}>
                    <Divider/>
                    <Typography variant={'subtitle2'} mt={'20px'} ml={'20px'}>
                      {t('teams')}
                    </Typography>
                  </Box>
                )}
                  <MenuItem
                      key={block.id}
                      onClick={handleClick}
                      selected={blockId === block.id}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: isTeam ? '0px 5px 0px 20px' : '0px 20px',
                        margin: '0px',
                        height: '40px'
                      }}
                  >
                      <Box width={'22px'} height={'22px'} display={'flex'}
                           alignItems={'center'}>
                        {isTeam && <WidgetsIcon
                            sx={{width: '18px', height: '18px'}}
                            color={blockId !== block.id ? 'inherit' : 'primary'}
                        />}
                      </Box>
                      <Typography
                          variant={'subtitle2'}
                          color={blockId !== block.id ? 'inherit' : 'primary'}
                          width={'100%'}
                          ml={2}
                      >
                        {block.title}
                      </Typography>
                      <TeamBlockContextMenu block={block}/>

                  </MenuItem>
                  <MainDrawerProjectList parentBlockId={block.id}/>
              </Box>
          );
        }
      )}
    </List>
  );
}
