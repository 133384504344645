import React from 'react';
import IconButton from "@mui/material/IconButton";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Menu,
  Typography,
  useTheme
} from "@mui/material";
import SignOutButton from "../user/SignoutButton";
import EditProfileButton from "../user/EditProfileButton";
import {useGlobalContext} from "../../hooks/GlobalContext";
import {useTranslation} from "react-i18next";
import {useNavigate} from 'react-router-dom';

export function TopBarProfileButton() {
  const theme = useTheme();
  const {user, userInfo, handleLogin} = useGlobalContext();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const {t} = useTranslation();

  const navigate = useNavigate();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);


  return (
    <div>
      {!user
        ? (
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              navigate("/app")
            }}
            sx={{
              marginLeft: '15px',
              borderRadius: '100px',
              background: 'linear-gradient(90deg,#F17F4A 10%, #E44D73 70%)',
              border: 'none',
              color: 'white',
              height: 40,
              padding: '0 30px',
              '&:hover': {
                background: 'linear-gradient(90deg, #E44D73 10%, #F17F4A 70%)',
                border: 'none',
              },
            }}
          >
            {t("sign_in")}
          </Button>
        )
        : (
          <IconButton onClick={handleClick}
                      sx={{marginLeft: '15px', width: '40px', height: '40px'}}>
            <Avatar
              sx={{
                width: '38px',
                height: '38px',
                border: '2px solid',
                borderColor: theme.palette.divider,
                backgroundColor: 'transparent',
              }}
            >
              <Avatar src={userInfo?.photoURL || user?.photoURL || ''}
                      sx={{width: '30px', height: '30px'}}/>
            </Avatar>
          </IconButton>
        )
      }

      {anchorEl && (
        <Menu
          id="language-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}>
          {user?.photoURL &&
              <Avatar
                  sx={{
                    width: '80px',
                    height: '80px',
                    border: '2px solid',
                    borderColor: theme.palette.divider,
                    backgroundColor: 'transparent',
                    margin: '15px 40px 0px 26px',
                  }}
              >
                  <Avatar src={userInfo?.photoURL || user.photoURL}
                          sx={{width: '70px', height: '70px'}}/>
              </Avatar>
          }
          <Box padding={'10px 40px 0px 26px'}
               sx={{display: 'flex', alignItems: 'center'}}>
            <Typography variant={'subtitle1'}>
              {userInfo?.displayName || userInfo?.displayName !== '' ? userInfo?.displayName : user?.displayName}
            </Typography>
            {userInfo?.privileges && (
              <Typography variant={'body2'} ml={'10px'} color='primary'>
                {` ${userInfo.privileges}`}
              </Typography>
            )}
          </Box>
          <Typography variant={'body1'} padding={'0px 40px 15px 26px'}
                      color={theme.palette.text.secondary}>
            {user?.email}
          </Typography>
          <Divider sx={{marginBottom: '10px'}}/>
          <EditProfileButton/>
          {/*<ApiKeyButton/>*/}
          <Divider sx={{paddingTop: '3px'}}/>
          <SignOutButton key="signout-button"/>
        </Menu>
      )
      }
    </div>
  );
}
