import React, {useEffect, useState} from 'react';
import {Box, Button, MenuItem, TextField} from '@mui/material';
import {
  CreateCronStringRequest
} from "tobl-data-schema/dist/api/input/createCronTextAndDescription";
import {createCronText} from "../../../../../cloud_functions/createCronString";
import moment from 'moment-timezone';
import {
  SchemaBlock_Input_Scheduler_Config
} from "tobl-data-schema/dist/schema/block/block_type/input";
import {useBlockEditorContext} from "../../../menu/BlockEditorContext";
import {BlockEditFormRow} from "../../../edit/BlockEditFormRow";
import {useSnackbar} from "../../../../../hooks/useSnackbar";

interface SchedulerInputProps {
  configuration: SchemaBlock_Input_Scheduler_Config;
}

const SchedulerInput: React.FC<SchedulerInputProps> = ({
                                                         configuration,
                                                       }) => {
  const [scheduleText, setScheduleText] = useState(configuration.scheduleTextInput);
  const [cronExpression, setCronExpression] = useState('');
  const [isTextFieldModified, setIsTextFieldModified] = useState(false);
  const [timezone, setTimezone] = useState(configuration.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC');
  const timezones = moment.tz.names();
  const {showSnackbar} = useSnackbar();

  const {
    blockId,
    updateConfigData,
    setIsSubmitReady
  } = useBlockEditorContext();

  const handleCreateCronString = async () => {
    setIsTextFieldModified(false);

    const request: CreateCronStringRequest = {
      scheduleTextInput: scheduleText,
      blockId,
    };
    try {
      const response = await createCronText(request);
      setCronExpression(response.cronExpression);
      setIsSubmitReady(true);

      updateConfigData('scheduleTextInput', scheduleText);
      updateConfigData('cronExpression', response.cronExpression);
      updateConfigData('timezone', timezone);

      showSnackbar(`Created schedule successfully. ${response.cronDescription}`, 'success');

    } catch (e) {
      showSnackbar('Failed to create cron string. Please provide a' +
                     ' legitimate schedule text. Example: At 2 pm every day.', 'error');
    }
  };

  useEffect(() => {
    setIsSubmitReady(false)
  }, []);

  return (
    <>
      <BlockEditFormRow key="schedule_timezone" label="Schedule TimeZone">
        <TextField
          select
          value={timezone}
          onChange={(e) => setTimezone(e.target.value)}
          onKeyDown={e => e.stopPropagation()}
          onKeyUp={e => e.stopPropagation()}
          onClick={e => e.stopPropagation()}
          fullWidth
          variant="outlined"
          sx={{width: '100%'}}
        >
          {timezones.map((zone) => {
            const offset = moment.tz.zone(zone)?.utcOffset(Date.now());
            if (offset == undefined) {
              return null;
            }
            return (
              <MenuItem key={zone} value={zone}>
                {zone} (UTC {offset >= 0 ? '+' : ''}{offset / 60})
              </MenuItem>
            );
          })}
        </TextField>
      </BlockEditFormRow>

      <BlockEditFormRow key="schedule_text" label="Schedule Text">

        <TextField
          value={scheduleText}
          onChange={(e) => {
            e.stopPropagation();
            setScheduleText(e.target.value);
            setIsSubmitReady(false);
            setIsTextFieldModified(true);
          }}
          onKeyDown={e => e.stopPropagation()}
          onKeyUp={e => e.stopPropagation()}
          onClick={e => e.stopPropagation()}
          multiline
          rows={4}
          fullWidth
          placeholder={'At 2 pm every day'}
          variant="outlined"
          sx={{
            width: '100%',
          }}
        />
        <Box display={'flex'} flexDirection={'column'}
             justifyContent="flex-end">
          <Button
            onClick={handleCreateCronString} disabled={!isTextFieldModified}>
            Validate Schedule
          </Button>
        </Box>
      </BlockEditFormRow>
    </>
  );
};

export default SchedulerInput;
