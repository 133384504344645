import React from 'react';
import {BlockType, SchemaBlock} from "tobl-data-schema/dist/schema/block/block";
import {
  BlockType_Project
} from "tobl-data-schema/dist/schema/block/block_type/project";
import {
  BlockType_Input
} from "tobl-data-schema/dist/schema/block/block_type/input";
import {
  BlockType_Processor
} from "tobl-data-schema/dist/schema/block/block_type/processor";

export const EmojiForBlockType = (type: string) => {
  switch (type) {
    case BlockType.team:
      return '💡';
    case BlockType_Project.documents:
      return '📑';
    case BlockType_Input.text:
      return '✏️';
    case BlockType_Input.file_uploader_pdf:
      return '📄';
    case BlockType_Input.file_uploader_audio:
      return '🎙️';
    case BlockType_Input.file_uploader_image:
      return '🏞️';
    case BlockType_Input.file_uploader_video:
      return '🎬';
    case BlockType_Input.youtube_link:
      return '📺';
    case BlockType_Input.file_uploader_text:
      return '🔠';
    case BlockType_Input.file_uploader_xlsx:
      return '📊';
    case BlockType_Input.scheduler:
      return '⏰';
    case BlockType_Processor.text_to_json_ai:
      return '{}';
    case BlockType_Processor.text_to_text_ai:
      return '🤖';
    case BlockType_Processor.texts_to_text_ai:
      return '🗒️';
    case BlockType_Processor.google_document_ai:
      return '📖';
    case BlockType_Processor.audio_to_text_ai:
      return '🗣️';
    case BlockType_Processor.text_file_loader:
      return '📤';
    case BlockType_Processor.file_pdf_extractor:
      return '📃';
    case BlockType_Processor.audio_compressor:
      return '🎛️';
    case BlockType_Processor.api_google_news:
      return '📰';
    case BlockType_Processor.api_google_news_multi:
      return '📰';
    case BlockType_Processor.send_email:
      return '📧';
    case BlockType_Processor.audio_chunker:
      return '🔊';
    case BlockType_Processor.text_chunker:
      return '✂️';
    case BlockType_Processor.text_slicer:
      return '🔪';
    case BlockType_Processor.text_splitter:
      return '🗡️';
    case BlockType_Processor.text_merger:
      return '⛙';
    case BlockType_Processor.texts_merger:
      return '⛙';
    case BlockType_Processor.link_youtube_extractor:
      return '📺';
    case BlockType_Processor.search_google_with_ai:
      return '🔎';
    case BlockType_Processor.search_wikipedia_with_ai:
      return '🌐';
    case BlockType_Processor.xlsx_file_loader:
      return '📊';
    case BlockType_Processor.table_summarizer:
      return '📝';
    case BlockType_Processor.triggered_text_document:
      return '📝';

    case BlockType_Processor.triggered_get_business_news:
      return '📰';
    default:
      return '❓';
  }
};
export default function BlockTypeIcon({block}: { block: SchemaBlock }) {
  const getEmojiForBlockType = (block: SchemaBlock) => {
    if (block.blockType === BlockType.team) {
      return EmojiForBlockType(BlockType.team);
    } else if (block.blockType === BlockType.project) {
      return EmojiForBlockType(block.configuration.blockType_Project);
    } else if (block.blockType === BlockType.input) {
      return EmojiForBlockType(block.configuration.blockType_Input);
    } else if (block.blockType === BlockType.processor) {
      return EmojiForBlockType(block.configuration.blockType_Processor);
    }
  };

  return (
    <span role="img" aria-label={block.blockType}>
      {getEmojiForBlockType(block)}
    </span>
  );
}
