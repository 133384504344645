import React from 'react';
import {Grid, InputLabel, MenuItem} from "@mui/material";
import {useBlockTree} from "../../../hooks/useBlockTree";
import Select from "@mui/material/Select";

export default function InputBlockSelector({label, value, onUpdate}: {
  label: string
  value: string
  onUpdate: (blocKId: string) => void
}) {
  const {inputBlocks} = useBlockTree()

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <InputLabel sx={{pt: '4px'}}>{label}</InputLabel>
        </Grid>
        <Grid item xs={8}>
          <Select
            value={value}
            onChange={(e) => onUpdate(e.target.value as string)}
            variant="standard"
            fullWidth
          >
            <MenuItem value={''}>Select a block</MenuItem>
            {inputBlocks?.map((block) => (
              <MenuItem key={block.id} value={block.id}>{block.title}</MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
    </Grid>
  );
}
