import {httpsCallable} from "firebase/functions";
import {firebaseFunctions} from "../firebase/firebase";
import {
  CopyBlockTreeRequest,
  CopyBlockTreeResponse
} from "tobl-data-schema/dist/api/block/block/copyBlockTree";


// Create a reference to the cloud function
const copyBlockTreeCloudFunction = httpsCallable(
  firebaseFunctions,
  "block-copyBlockTree"
);

// Define the function to add a block
export async function copyBlockTree(request: CopyBlockTreeRequest): Promise<CopyBlockTreeResponse> {
  const response = await copyBlockTreeCloudFunction(request);
  return response.data as CopyBlockTreeResponse;
}
