import React, {useState} from 'react';
import Menu from '@mui/material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {BlockType, SchemaBlock} from "tobl-data-schema/dist/schema/block/block";
import IconButton from "@mui/material/IconButton";
import ManageTeamMenuItem from "../block/team/ManageTeamMenuItem";
import {AddEditMode} from "../block/menu/BlockMenuAddOrEditDialog";
import {BlockEditButtonContext} from "../block/menu/BlockEditButtonContext";
import BlockPartialEditor from "../block/menu/BlockPartialEditor";

interface ContextMenuProps {
  block: SchemaBlock;
}

const TeamBlockContextMenu: React.FC<ContextMenuProps> = ({block}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="more"
        size={'small'}
        onClick={handleClick}
      >
        <MoreVertIcon fontSize={'small'}/>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <ManageTeamMenuItem blockId={block.id}/>
        <BlockPartialEditor addEditMode={AddEditMode.ADD}
                            blockType={BlockType.project}
                            blockId={block.id}
                            buttonContext={BlockEditButtonContext.TITLE_CHANGE}
        />
      </Menu>
    </>
  );
};

export default TeamBlockContextMenu;
