import {httpsCallable, HttpsCallableResult} from "firebase/functions";
import {firebaseFunctions} from "../firebase/firebase";

export type RunTaskRequest = {
  blockId: string;
  runId: string;
};

const rerunTaskCloudFunction = httpsCallable(
  firebaseFunctions,
  'run-rerunBlock' // this is the name of your cloud function
);

export async function rerunTask(request: RunTaskRequest): Promise<HttpsCallableResult<unknown>> {
  return await rerunTaskCloudFunction(request);
}
