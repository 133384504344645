import React from 'react';
import {CssBaseline, LinearProgress, useTheme} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {routes} from "./Routes";
import {QueryClient, QueryClientProvider} from 'react-query';
import {SnackbarProvider} from "./hooks/useSnackbar";
import {useGlobalContext} from "./hooks/GlobalContext";

const useStyles = makeStyles(() => ({
  loading: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 9999,
    width: '50%',
  },
}));

export function App() {
  const theme = useTheme();
  const init = useGlobalContext();

  const classes = useStyles();
  const queryClient = new QueryClient();

  if (init.loading) {
    return (
      <div className={classes.loading}
        style={{
          backgroundColor: theme.palette.background.default,
          width: '100%',
          height: '100%',
          margin: 0,
          padding: 0
        }}>
        <LinearProgress color="primary"/>
      </div>
    );
  }

  const router = createBrowserRouter(routes);

  return (
    <React.Fragment>
      <CssBaseline/>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider>
          <RouterProvider router={router}/>
        </SnackbarProvider>
      </QueryClientProvider>
    </React.Fragment>
  );
}
