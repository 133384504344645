// src/views/app/VoiceInputAppView.tsx
import React, {useEffect, useRef, useState} from 'react';
import {SchemaAppTwoTextInputs} from "../../../models/apps";
import {Box, Button, TextField, Typography} from '@mui/material';
import {COLLECTION_TRACES} from "../../../models/trace";
import {doc, onSnapshot} from "firebase/firestore";
import {SchemaTrace} from "tobl-data-schema/dist/schema/trace";
import {parseDoc} from "../../../firebase/firestore/parseDoc";
import {createPatch} from 'diff';
import {useSnackbar} from "../../../hooks/useSnackbar";
import {addRun} from "../../../cloud_functions/addRun";
import {v4 as uuidv4} from "uuid";
import {AddRunRequest} from "tobl-data-schema/dist/api/block/block/run/addRun";
import {
  DocumentsViewDetailDialog
} from "../../../components/block/projects/documents/DocumentsViewDetailDialog";
import {BlockTreeProvider} from "../../../hooks/useBlockTree";

interface DiffInputAppViewProps {
  app: SchemaAppTwoTextInputs;
}

const DiffInputAppView: React.FC<DiffInputAppViewProps> = ({app}) => {
  const [trace, setTrace] = useState<SchemaTrace>()

  const [traceId, setTraceId] = useState<string>();

  const text1Ref = useRef<HTMLInputElement>(null);
  const text2Ref = useRef<HTMLInputElement>(null);
  const titleRef = useRef<HTMLInputElement>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const {showSnackbar} = useSnackbar();

  const handleSubmit = () => {
    if (text1Ref.current && text2Ref.current && titleRef.current) {
      const text1 = text1Ref.current.value;
      const text2 = text2Ref.current.value;
      const title = titleRef.current.value; // Get the value of the title field

      const diff = createPatch('changes', text1, text2);

      const prompt = text1 + '\n\n------\n' + diff;
      // Copy to clipboard
      navigator.clipboard.writeText(prompt).then(() => {
        showSnackbar('Prompt copied to clipboard');
      });

      const traceId = uuidv4();

      setTraceId(traceId);

      showSnackbar('Initiating AI');
      const request: AddRunRequest = {
        blockId: app.textInputBlockId,
        traceId: traceId,
        clientInputData: {
          text: prompt,
          title
        },
      };
      console.log("request", request)
      addRun(request).then((response) => {
        console.log(response);
        showSnackbar('AI Task added');
        setIsDialogOpen(true);
      }).catch((error) => {
        console.error("Error adding run", error);
        showSnackbar('Error adding AI task');
      });
    }
  }

  useEffect(() => {
    if (!traceId) {
      return;
    }
    const unsubscribe =
      onSnapshot(
        doc(COLLECTION_TRACES, traceId),
        (doc) => {
          const trace = parseDoc<SchemaTrace>(doc);
          setTrace(trace);
        }
      );

    return () => {
      unsubscribe();
    }
  }, [traceId]);

  return (
    <Box
      sx={{
        p: 2,
        borderRadius: 1,
        height: '100vh',
        marginLeft: 'auto', // set left margin to auto
        marginRight: 'auto', // set right margin to auto
        paddingBottom: '2rem', // Add padding to the bottom
      }}
    >
      <Box
        sx={{
          width: '100%',
          margin: 'auto',
          height: '90%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 2,
            width: '100%',
          }}
        >
          <img
            src={app.logoImageUrl}
            alt={app.title}
            style={{
              maxWidth: 40,
              maxHeight: 40,
              margin: 20,
            }}
          />
          <Typography variant="h5" component="div">{app.title}</Typography>
        </Box>

        <TextField
          id="title-field"
          label="Title"
          fullWidth
          sx={{
            border: '1px solid #000', // Add a border
            borderRadius: '4px', // Optional: Add border radius
            width: '90%', // Optional: Set the width
          }}

          inputRef={titleRef}
        />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: 2,
            width: '100%',
          }}
        >
          <TextField
            id="outlined-multiline-static-1"
            label="Text 1"
            multiline
            defaultValue=""
            fullWidth
            minRows={20}
            maxRows={30}
            sx={{
              border: '1px solid #000', // Add a border
              borderRadius: '4px', // Optional: Add border radius
              marginRight: '1rem', // Optional: Add some margin to the right

            }}
            inputRef={text1Ref}
          />

          <TextField
            id="outlined-multiline-static-2"
            label="Text 2"
            multiline
            defaultValue=""
            fullWidth
            minRows={20}
            maxRows={30}
            sx={{
              border: '1px solid #000', // Add a border
              borderRadius: '4px', // Optional: Add border radius
            }}
            inputRef={text2Ref}
          />
        </Box>

        <Box sx={{p: '40px'}}>
          {
            trace ? (
                trace.blocks === undefined
                  ?
                  <div>Processing... The prompt has been copied to the clipboard.
                    Use Claude 3 Opus to interact with it.</div>
                  : <Button
                    variant="contained"
                    sx={{
                      backgroundColor: 'darkgreen',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: 'green',
                      },
                    }}
                    onClick={() => setIsDialogOpen(true)}
                  >
                    Open Results
                  </Button>
              )
              : <Button variant="contained" color="primary"
                        onClick={handleSubmit}>
                Submit
              </Button>
          }

        </Box>

      </Box>
      {trace && trace.blocks &&
          <BlockTreeProvider blockId={app.textInputBlockId}>
              <DocumentsViewDetailDialog
                  trace={trace}
                  onClose={() => setIsDialogOpen(false)} // Close the dialog when onClose is called
                  isOpen={isDialogOpen}
              />
          </BlockTreeProvider>}
    </Box>
  );
};

export default DiffInputAppView;
