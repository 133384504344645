import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import GetAppIcon from '@mui/icons-material/GetApp';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import IconButton from "@mui/material/IconButton";
import {Badge, Box, CircularProgress, Tooltip, Typography} from "@mui/material";
import TitleIcon from '@mui/icons-material/Title';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {SchemaBlock} from "tobl-data-schema/dist/schema/block/block";

export enum ViewMode {
  LIST = 'list',
  PREVIEW = 'preview',
  // More modes can be added here in the future
}

type Props = {
  selectedItems: Set<string>,
  handleSelectAll: () => void,
  handleDeleteSelected: () => void,
  handleExportSelected: () => void,
  block: SchemaBlock,
  handleViewChange: (mode: ViewMode) => void;
  currentView: ViewMode;
  sortOption: 'title' | 'createdAt';
  setSortOption: (option: 'title' | 'createdAt') => void;
  exportProgress: number;
};

const DocumentsViewActionButtons: React.FC<Props> = (
  {
    selectedItems,
    handleSelectAll,
    handleDeleteSelected,
    handleExportSelected,
    block,
    handleViewChange,
    currentView,
    sortOption,
    setSortOption,
    exportProgress,
  }) => {

  return (
    <Box style={{display: 'flex', alignItems: 'center'}}>
      {selectedItems.size !== 0 && (
        <>
          {exportProgress > 0 ? (
            <Tooltip title="Exporting...">
              <Box style={{margin: '5px', marginLeft: '0px'}}>
                <CircularProgress variant="determinate" value={exportProgress}/>
                <Typography variant="caption" component="div"
                            color="textSecondary">
                  {`${Math.round(exportProgress)}%`}
                </Typography>
              </Box>
            </Tooltip>
          ) : (
            <Tooltip title="Export Selected">
              <IconButton
                onClick={handleExportSelected}
                disabled={selectedItems.size === 0}
                style={{margin: '5px'}}
              >
                <GetAppIcon fontSize={'small'}/>
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Delete Selected">
            <IconButton
              onClick={handleDeleteSelected}
              disabled={selectedItems.size === 0}
              style={{margin: '5px'}}
            >
              <DeleteIcon fontSize={'small'}/>
            </IconButton>
          </Tooltip>
        </>
      )}
      <Tooltip title="Select All">
        <IconButton
          onClick={handleSelectAll}
          style={{margin: 1}}
        >
          <Badge badgeContent={selectedItems.size} color="primary" max={999}>
            <CheckIcon fontSize={'small'}/>
          </Badge>
        </IconButton>
      </Tooltip>
      <Tooltip
        title={`Sort by ${sortOption === 'title' ? 'Creation Time' : 'Title'}`}>
        <IconButton
          onClick={() => {
            if (sortOption === 'title') {
              setSortOption('createdAt');
            } else {
              setSortOption('title');
            }
          }}
          style={{margin: 1}}
        >
          {sortOption === 'title' ? <TitleIcon fontSize={'small'}/> :
            <AccessTimeIcon fontSize={'small'}/>}
        </IconButton>
      </Tooltip>
      <Tooltip title="Change View">
        <IconButton
          onClick={() => {
            if (currentView === ViewMode.LIST) {
              handleViewChange(ViewMode.PREVIEW);
            } else {
              handleViewChange(ViewMode.LIST);
            }
          }}
          style={{margin: 1, marginRight: '0px'}}
        >
          {currentView === ViewMode.LIST ?
            <ViewModuleIcon fontSize={'small'}/> :
            <ViewListIcon fontSize={'small'}/>}
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default DocumentsViewActionButtons;
