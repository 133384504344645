import React from "react";
import {SchemaBlock_Processor_Config} from "tobl-data-schema/dist/schema/block/block_type/processor";
import {AddEditMode} from "../menu/BlockMenuAddOrEditDialog";
import {renderProcessorForms} from "./RenderProcessorForms";
import {BlockTypeSelector} from "./BlockTypeSelector";
import {BlockType, SchemaBlockEdit} from "tobl-data-schema/dist/schema/block/block";
import {BlockDocumentType} from "tobl-data-schema/dist/schema/block/document/document";

interface EditFormProcessorProps {
  blockData: SchemaBlockEdit;
  configuration: SchemaBlock_Processor_Config;
  updateBlockData: (key: string, value: any) => void;
  updateConfigData: (key: string, value: any) => void;
  addEditMode: AddEditMode;
  parentOutputType?: BlockDocumentType;
}

export function EditFormProcessor(
  {
    blockData,
    configuration,
    updateBlockData,
    updateConfigData,
    addEditMode,
    parentOutputType
  }: EditFormProcessorProps) {
  return (
    <>
      {addEditMode === AddEditMode.ADD && (
        <BlockTypeSelector
          selectedType={configuration.blockType_Processor}
          updateConfigData={updateConfigData}
          blockType={BlockType.processor}
          parentOutputType={parentOutputType}
        />
      )}

      {renderProcessorForms(blockData, configuration, updateBlockData, updateConfigData)}
    </>
  );
}
