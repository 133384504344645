import {httpsCallable} from "firebase/functions";
import {firebaseFunctions} from "../firebase/firebase";


export type DeleteForTraceIdsRequest = {
  traceIds: string[];
};


// Create a reference to the cloud function
const cloudFunction = httpsCallable(
  firebaseFunctions,
  "run-deleteForTraceIds"
);

// Define the function to add a run
export async function deleteForTraceIds(request: DeleteForTraceIdsRequest): Promise<void> {
  await cloudFunction(request);
}
