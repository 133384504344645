import React, {useState} from 'react';
import MenuItem from '@mui/material/MenuItem';
import AppsIcon from '@mui/icons-material/Apps';
import AppFormDialog from "../app_setting/AppFormDialog";
import useGetApp from "../../../hooks/useGetApp";

interface ManageProjectAppMenuItemProps {
  handleClose: () => void;
  blockId: string;
}

const ManageProjectAppMenuItem: React.FC<ManageProjectAppMenuItemProps> = ({
                                                                             handleClose,
                                                                             blockId
                                                                           }) => {

  const {app, update} = useGetApp(blockId);

  const [isDialogOpen, setDialogOpen] = useState(false);

  const handleManageProjectApp = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.stopPropagation();
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    update();
  };

  return (
    <>
      <MenuItem onClick={handleManageProjectApp}>
        <AppsIcon fontSize={'small'} sx={{mr: 1}}/>
        Manage Project App
      </MenuItem>
      {isDialogOpen && <AppFormDialog
          open={isDialogOpen} onClose={handleDialogClose}
          app={app}
          blockId={blockId}
      />}
    </>
  );
};

export default ManageProjectAppMenuItem;
