import React, {MouseEvent, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {Box, LinearProgress, useTheme} from "@mui/material";
import UploadFiles from "../../inputs/UploadFiles";
import AddIcon from '@mui/icons-material/Add';
import {BlockType, SchemaBlock} from "tobl-data-schema/dist/schema/block/block";
import {useTranslation} from 'react-i18next';
import {
  BlockType_Input
} from "tobl-data-schema/dist/schema/block/block_type/input";
import YoutubeLinkUploader from "./input/YoutubeLinkUploader";
import IconButton from "@mui/material/IconButton";
import TextInput from "../../inputs/TextInput";
import TriggerBox from "../../inputs/TriggerBox";

interface AddDocumentButtonProps {
  block: SchemaBlock;
  children?: React.ReactNode;
}

const AddDocumentButton: React.FC<AddDocumentButtonProps> = ({
                                                               block,
                                                               children
                                                             }) => {
  const theme = useTheme();
  const [open, setOpen] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const {t} = useTranslation();

  const handleClose = () => {
    setProgress(0);
    setOpen(false);
  };

  const handleRunClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (block.blockType === BlockType.input) {
      e.stopPropagation();
      setOpen(true);
    }
  };

  return (
    <>
      {children ? (
        <Box component="span" onClick={handleRunClick}>
          {children}
        </Box>
      ) : (
        <IconButton
          aria-label="add"
          size={'small'}
          onClick={handleRunClick}
        >
          <AddIcon fontSize={'small'}/>
        </IconButton>
      )}

      {block.blockType === BlockType.input && (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle variant="h5" textAlign={'center'}>
            {t(block.title)}
          </DialogTitle>
          <DialogContent>
            {
              ((block.configuration.blockType_Input === BlockType_Input.file_uploader_pdf
                || block.configuration.blockType_Input === BlockType_Input.file_uploader_audio
                || block.configuration.blockType_Input === BlockType_Input.file_uploader_video
                || block.configuration.blockType_Input === BlockType_Input.file_uploader_image
                || block.configuration.blockType_Input === BlockType_Input.file_uploader_text
                || block.configuration.blockType_Input === BlockType_Input.file_uploader_xlsx
              )
                ? <UploadFiles blockId={block.id}
                               setProgress={setProgress}
                               handleClose={handleClose}
                />
                : block.configuration.blockType_Input === BlockType_Input.scheduler ? (
                    <TriggerBox
                      blockId={block.id}
                      setProgress={setProgress}
                      handleClose={handleClose}/>) :
                  block.configuration.blockType_Input === BlockType_Input.text ? (
                      <TextInput
                        blockId={block.id}
                        setProgress={setProgress}
                        handleClose={handleClose}
                      />
                    ) :
                    block.configuration.blockType_Input === BlockType_Input.youtube_link ?
                      <YoutubeLinkUploader
                        handleClose={handleClose}
                        blockId={block.id}
                      />
                      : "Unsupported block type.")
            }
          </DialogContent>
          {progress > 0 &&
              <LinearProgress variant="determinate" value={progress}/>}
        </Dialog>
      )}
    </>
  );
};

export default AddDocumentButton;
