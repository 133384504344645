import {RunResult, RunStatus} from "tobl-data-schema/dist/schema/block/run/run";
import {Theme} from "@mui/material/styles";

export const GREEN = {
  w100: '#DFF4EA', // Lightest shade of green
  w200: '#B8E7D8',
  w300: '#8CC6A6',
  w400: '#6FAE8A',
  w500: '#4DCF8E', // Default or main shade of green
  w600: '#3A996B',
  w700: '#2D6446',
  w800: '#1E422E',
  w900: '#11261F', // Darkest shade of green
};

export const GRAY = {
  w100: '#fff', // Lightest shade of background
  w200: '#F5F5F5', // Lightest shade of text color
  w300: '#E6E6E6',
  w400: '#BEC6C8',
  w500: '#A0A0A0',
  w600: '#616161', // Default or main shade of text color
  w700: '#232323',
  w800: '#1C1C1C',
  w900: '#121212', // Darkest shade of text color
};

export const getColorByResult = (
  result: RunResult | RunStatus,
  theme: Theme
): string => {
  switch (result) {
  case RunResult.success:
  case RunStatus.finished:
    return theme.palette.text.primary;
  case RunResult.failure:
  case RunStatus.archived:
    return theme.palette.error.main;
  case RunResult.pending:
  case RunStatus.pending:
  case RunStatus.running:
    return theme.palette.text.secondary;
  default:
    return theme.palette.text.secondary;
  }
};