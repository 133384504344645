import React, {createContext, useContext} from 'react';
import {AddEditMode} from "./BlockMenuAddOrEditDialog";
import {SchemaBlockEdit} from "tobl-data-schema/dist/schema/block/block";

interface BlockEditorContextProps {
  isSubmitReady: boolean;
  setIsSubmitReady: React.Dispatch<React.SetStateAction<boolean>>;
  blockId: string;
  updateConfigData: (key: string, value: any) => void;
  addEditMode: AddEditMode;
  blockData: SchemaBlockEdit;
}

export const BlockEditorContext = createContext<BlockEditorContextProps | undefined>(undefined);


export const useBlockEditorContext = () => {
  const context = useContext(BlockEditorContext);
  if (context === undefined) {
    throw new Error('useBlockEditorContext must be used within a BlockEditorProvider');
  }
  return context;
};
