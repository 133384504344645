import React from 'react';
import {SchemaAppCreate, SchemaAppTwoTextInputs} from "../../../models/apps";
import InputBlockSelector from "./InputBlockSelector";

export default function AppFormTextDiff({app, setApp}: {
  app: Partial<SchemaAppTwoTextInputs>
  setApp: React.Dispatch<React.SetStateAction<Partial<SchemaAppCreate>>>
}) {

  console.log(app);

  return (
    <>
      <InputBlockSelector
        label={'Text Input Block'}
        value={app.textInputBlockId || ""}
        onUpdate={(textInputBlockId) => {
          setApp((p) => ({
            ...p,
            textInputBlockId: textInputBlockId
          }))
        }}
      />
    </>
  );
}
