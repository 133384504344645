import React, {useEffect, useMemo, useState} from 'react';
import {doc, onSnapshot} from 'firebase/firestore';
import {COLLECTION_TRACES,} from "../../../../../models/trace";
import {parseDoc} from '../../../../../firebase/firestore/parseDoc';
import BlockTreeMapView from "./BlockTreeMapView";
import {SchemaTrace} from 'tobl-data-schema/dist/schema/trace';
import {
  SchemaTraceBlockDetailsWithChildren
} from "./SchemaTraceBlockDetailsWithChildren";

interface TraceStatusProps {
  blockId: string;
  traceId: string;
}


const BlockTreeMapForDocument: React.FC<TraceStatusProps> = ({
  blockId,
  traceId
}) => {
  const [traceData, setTraceData] = useState<SchemaTrace | null>(null);

  const buildTreeFromEndNode = (endNodeId: string, lookup: any): SchemaTraceBlockDetailsWithChildren | null => {
    let currentNodeId = endNodeId;
    let rootNode: SchemaTraceBlockDetailsWithChildren | null = null;

    while (currentNodeId && lookup[currentNodeId]) {
      rootNode = {
        ...lookup[currentNodeId],
        children: rootNode ? [rootNode] : [],
        blockId: currentNodeId
      };
      currentNodeId = lookup[currentNodeId].previousBlockId;
    }

    return rootNode;
  };

  const blockTree = useMemo(() => {
    if (!traceData) return null;

    const lookup: { [key: string]: SchemaTraceBlockDetailsWithChildren } = {};
    for (const key in traceData.blocks) {
      lookup[key] = {...traceData.blocks[key], children: [], blockId: key};
    }

    return buildTreeFromEndNode(blockId, lookup);
  }, [traceData, blockId]);

  useEffect(() => {
    const traceDoc = doc(COLLECTION_TRACES, traceId);
    const unsubscribe = onSnapshot(traceDoc, (traceSnapshot) => {
      if (traceSnapshot.exists()) {
        const trace = parseDoc<SchemaTrace>(traceSnapshot);
        setTraceData(trace);
      } else {
        console.warn(`Trace with ID ${traceId} does not exist.`);
      }
    }, (error) => {
      console.error('Error fetching trace:', error);
    });

    return () => {
      unsubscribe();
    };
  }, [traceId]);

  if (!traceId || !traceData) {
    return null;
  }

  return (
    <BlockTreeMapView key={blockId} treeData={blockTree}/>
  );
};

export default BlockTreeMapForDocument;
