import React from 'react';
import {Button} from '@mui/material';
import {useTranslation} from "react-i18next";
import {MessageView} from '../../../../views/app/MessageView';
import noDocumentsImage from '../../../../assets/images/noDocumentsImage.png';
import {SchemaBlock} from "tobl-data-schema/dist/schema/block/block";
import AddDocumentButton from "./AddDocumentButton";

interface Props {
  triggerBlock?: SchemaBlock | null;
}

const MessageNoDocuments: React.FC<Props> = ({triggerBlock}) => {
  const {t} = useTranslation();

  return (
    <MessageView
      photo={noDocumentsImage}
      title="noDocuments.title"
      message="noDocuments.message"
      button={triggerBlock && (
        <AddDocumentButton block={triggerBlock}>
          <Button variant={'outlined'}>
            {t("noDocuments.button")}
          </Button>
        </AddDocumentButton>
      )}
    />
  );
}

export default MessageNoDocuments;
