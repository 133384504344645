import {httpsCallable} from "firebase/functions";
import {firebaseFunctions} from "../firebase/firebase";
import {
  CreatePortalLinkRequest,
  CreatePortalLinkResponse
} from "tobl-data-schema/dist/api/stripe/createPortalLink";

// Create a reference to the cloud function
const createPortalLinkFunction = httpsCallable(
  firebaseFunctions,
  "stripe-createPortalLink"
);

// Define the function to create a portal link
export async function createPortalLink(request: CreatePortalLinkRequest): Promise<CreatePortalLinkResponse> {
  const response = await createPortalLinkFunction(request);
  return response.data as CreatePortalLinkResponse;
}
