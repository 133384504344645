import React, {useState} from 'react';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  generateInvite,
  GenerateInviteRequest
} from "../../../cloud_functions/invite";
import {
  DialogContentText,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography
} from "@mui/material";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {useTranslation} from "react-i18next";
import {useSnackbar} from "../../../hooks/useSnackbar";

interface BlockMenuInviteButtonProps {
  blockId: string;
}

const BlockMenuInviteButton: React.FC<BlockMenuInviteButtonProps> = ({blockId}) => {
  const [showDialog, setShowDialog] = useState(false);
  const [inviteToken, setInviteToken] = useState<string | null>(null);
  const {t} = useTranslation();
  const {showSnackbar} = useSnackbar();
  const handleGenerateInvite = async (e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      showSnackbar(t('Generating invite link...'));
      const request: GenerateInviteRequest = {blockId};
      const response = await generateInvite(request);
      setInviteToken(response.inviteToken);
      await navigator.clipboard.writeText(`https://tobl.ai/invite/${response.inviteToken}`);
      setShowDialog(true);
    } catch (err) {
      console.error('Failed to generate invite or copy to clipboard:', err);
    }
  };

  return (
    <>
      <MenuItem onClick={handleGenerateInvite}>
        <ListItemIcon>
          <PersonAddIcon color={'primary'}/>
        </ListItemIcon>
        <ListItemText>
          {t('Share Invite Link')}
        </ListItemText>
      </MenuItem>

      <Dialog
        open={showDialog}
        maxWidth={'xs'}
        onClose={() => setShowDialog(false)}
      >
        <DialogTitle>{t('Invite Link')}</DialogTitle>
        <DialogContent>
          <DialogContentText mb={2}>
            {t('Your invite link has been copied to the clipboard. Share this link to invite others. This link will expire in 24 hours:')}
          </DialogContentText>
          <Paper sx={{padding: 2}}>
            <Typography variant="subtitle1" sx={{
              wordBreak: 'break-all',
              overflowWrap: 'break-word'
            }}>
              {`https://tobl.ai/invite/${inviteToken}`}
            </Typography>
          </Paper>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default BlockMenuInviteButton;
