import React, {useState} from 'react';
import {Box, Button, CircularProgress, useMediaQuery, useTheme} from '@mui/material';
import {addDoc, collection, doc, onSnapshot} from "firebase/firestore";
import {useTranslation} from "react-i18next";
import {
  TOBL_AI_COUPON_EARLY_ADOPTER,
  TOBL_AI_PLUS_MONTHLY_PRICE,
  TOBL_AI_PLUS_YEARLY_PRICE
} from "../../../constants/stripe";
import {useSnackbar} from "../../../hooks/useSnackbar";
import {COLLECTION_STRIPE_TEAM_BLOCKS} from '../../../models/stripe';
import {BlockType, SchemaBlock} from "tobl-data-schema/dist/schema/block/block";
import {TeamSubscriptionPricing} from "tobl-data-schema/dist/schema/block/block_type/team";
import HomePricingBox from "../../home/HomePricingBox";
import {HomePricingYearlyButton} from '../../home/HomePricingYearlyButton';
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export function TeamSubscriptionDetail({teamBlock, isDialogOpen, handleDialogClose}: {
  teamBlock: SchemaBlock,
  isDialogOpen: boolean,
  handleDialogClose: (e: React.MouseEvent) => void
}) {
  const theme = useTheme();
  const {t} = useTranslation();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [isStartingSubscription, setIsStartingSubscription] = useState(false);
  const [isYearly, setIsYearly] = useState(false);

  const {showSnackbar} = useSnackbar();

  const handleStartSubscription = async (pricing: string) => {
    setIsStartingSubscription(true);

    if (teamBlock.blockType !== BlockType.team) {
      return;
    }

    const stripeTeamBlockDoc =
      doc(COLLECTION_STRIPE_TEAM_BLOCKS, teamBlock.id);

    try {
      const paymentData: {
        line_items: {
          price: string;
          quantity: number;
        }[],
        promotion_code?: string;
        success_url: string;
        cancel_url: string;
        allow_promotion_codes: boolean
      } = {
        line_items: [
          {
            price: pricing,
            quantity: 1,
          },
        ],
        success_url: window.location.origin,
        cancel_url: window.location.origin,
        allow_promotion_codes: true,
      };

      if (teamBlock.configuration.teamSubscriptionPricing === TeamSubscriptionPricing.discount_50_percent) {
        paymentData.promotion_code = TOBL_AI_COUPON_EARLY_ADOPTER;
      }

      const docRef = await addDoc(
        // stripe_customers refers to a team block
        collection(stripeTeamBlockDoc, 'checkout_sessions'),
        paymentData
      );

      onSnapshot(docRef, (snap) => {
        const data = snap.data();

        if (data) {
          const {error, url} = data;
          if (error) {
            setIsStartingSubscription(false);
            alert(`Error: ${error.message}`);
          }
          if (url) {
            setIsStartingSubscription(false);
            window.location.assign(url);
          }
        }
      });
    } catch (error) {
      setIsStartingSubscription(false);
      showSnackbar(t("Error starting subscription"));
      console.error("Error starting subscription:", error);
    }
  };

  const plan = {
    title: "plus.title",
    price: 400,
    buttonLabel: "plus.buttonLabel",
    features: [
      "✔ Unlimited project creation",
      "✔ Unlimited block creation",
      "✔ Add unlimited collaborators"
    ]
  };

  return (
    <Dialog
      open={isDialogOpen}
      onClose={handleDialogClose}
      fullWidth
      maxWidth={'xs'}
      fullScreen={fullScreen}
      aria-labelledby="subscription-dialog-title"
      aria-describedby="subscription-dialog-description"
    >
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
          padding: '15px 10px 0'
        }}
      >
        <IconButton
          color="primary"
          aria-label="close"
          onClick={handleDialogClose}
          style={{
            width: '40px',
            height: '40px',
          }}
        >
          <CloseIcon fontSize={'medium'} color={"primary"}/>
        </IconButton>
      </Box>
      <Box padding={'0px 20px 20px'}>
        <HomePricingYearlyButton isYearly={isYearly} setIsYearly={setIsYearly}/>
        <HomePricingBox
          title={plan.title}
          price={plan.price}
          features={plan.features}
          isPromo={teamBlock.blockType === BlockType.team && teamBlock.configuration.teamSubscriptionPricing === TeamSubscriptionPricing.discount_50_percent}
          isYearly={isYearly}
          button={(
            <Box display={"flex"}>
              {isStartingSubscription
                ? <CircularProgress size={29}/>
                : <Button
                  variant="outlined"
                  size={'small'}
                  onClick={(e) => {
                    e.stopPropagation();
                    const price = isYearly ? TOBL_AI_PLUS_YEARLY_PRICE : TOBL_AI_PLUS_MONTHLY_PRICE;
                    handleStartSubscription(price);
                  }}
                >
                  {t(plan.buttonLabel) + ' ↗'}
                </Button>}
            </Box>
          )}
        />
      </Box>
    </Dialog>
  );
}
