import React from 'react';
import {Handle, Position} from 'reactflow';
import {
  useGetBlockFromBlockTreeLookup
} from "../../../../../hooks/useGetBlockFromBlockTreeLookup";
import BlockRun from "../../../../../views/block/run/BlockRun";
import {Box} from "@mui/material";

interface CustomNodeComponentProps {
  data: {
    runId: string;
    blockId: string;
  };
}

const CustomNodeComponent: React.FC<CustomNodeComponentProps> = ({data}) => {
  const {block} = useGetBlockFromBlockTreeLookup(data.blockId);

  if (!block) {
    return null;
  }

  const hiddenHandleStyle = {opacity: 0, pointerEvents: 'none' as const};

  return (
    <div>
      <Handle type="target" position={Position.Left} style={hiddenHandleStyle}/>
      <Handle type="source" position={Position.Right}
        style={hiddenHandleStyle}/>

      <Box margin={'0px -5px'}>
        <BlockRun key={data.runId}
          blockId={data.blockId}
          runId={data.runId}/>
      </Box>

    </div>
  );
};

export default CustomNodeComponent;
