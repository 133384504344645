import React from "react";
import {useTranslation} from "react-i18next";
import {Box, Typography} from "@mui/material";
import {SchemaBlockEdit} from "tobl-data-schema/dist/schema/block/block";
import EditFormProcessorMergerItem from "./EditFormProcessorMergerItem";
import EditFormProcessorMergerItemAddButton from "./EditFormProcessorMergerItemAddButton";

export function EditFormProcessorMerger({
  blockData,
  updateBlockData,
}: {
  blockData: SchemaBlockEdit,
  updateBlockData: (key: string, value: any) => void,
}) {
  const {t} = useTranslation();

  return (
    <>
      <Typography variant={'body2'} mt={'20px'} mb={'5px'}>{t("Input Source")}</Typography>
      {blockData.parentBlockIds.map((parentBlockId, index) =>
        <Box mt={index === 0 ? 0 : '5px'}>
          <EditFormProcessorMergerItem
            blockId={parentBlockId}
            blockData={blockData}
            updateBlockData={updateBlockData}
            disabled={blockData.parentBlockIds.length <= 1}
          />
        </Box>
      )}
      <EditFormProcessorMergerItemAddButton
        blockData={blockData}
        updateBlockData={updateBlockData}
      />
    </>
  );
}
