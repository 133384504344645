// src/components/AppFormDialog.tsx
import React, {useCallback, useState} from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import {
  AppType,
  COLLECTION_APPS,
  SchemaApp,
  SchemaAppCreate,
  SchemaAppEmailSender,
  SchemaAppTwoTextInputs,
  SchemaAppXlsxAggregator
} from '../../../models/apps';
import {storage} from "../../../firebase/firebase";
import {getDownloadURL, ref, uploadBytesResumable} from "firebase/storage";
import {doc, setDoc} from "firebase/firestore";
import {v4 as uuidv4} from 'uuid';
import AppFormVoiceInput from "./AppFormVoiceInput";
import {removeNullValues} from "../../../utils/removeNullValues";
import {BlockTreeProvider} from "../../../hooks/useBlockTree";
import AppAliasMenuItem from "../settings/AppAliasMenuItem";
import AppFormXlsxAggregator from "./AppFormXlsxAggregator";
import AppFormTextDiff from "./AppFormTextDiff";

interface AppFormDialogProps {
  open: boolean;
  onClose: () => void;
  app?: SchemaApp;
  blockId: string;
}

const AppFormDialog: React.FC<AppFormDialogProps> = (
  {
    open,
    onClose,
    app,
    blockId
  }) => {

  const [appData, setAppData] =
    useState<Partial<SchemaAppCreate>>(
      removeNullValues({
                         title: app?.title ?? '',
                         description: app?.description ?? '',
                         logoImageUrl: app?.logoImageUrl ?? '',
                         appType: app?.appType ?? AppType.EMAIL_SENDER,
                         groupId: blockId,
                         voiceRecognitionBlockId: (app && 'voiceRecognitionBlockId' in app) ? app?.voiceRecognitionBlockId : undefined,
                         contentProcessingBlockId: (app && 'contentProcessingBlockId' in app) ? app?.contentProcessingBlockId : undefined,
                         senderReceiptProcessingBlockId: (app && 'senderReceiptProcessingBlockId' in app) ? app?.senderReceiptProcessingBlockId : undefined,
                         xlsxFileBlockId: (app && 'xlsxFileBlockId' in app) ? app?.xlsxFileBlockId : undefined,
                         textInputBlockId: (app && 'textInputBlockId' in app) ? app?.textInputBlockId : undefined,
                       }));

  const [isUploading, setIsUploading] = useState(false); // Add this state variable to track the upload status
  const [uploadProgress, setUploadProgress] = useState(0);


  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) {
      return;
    }
    setIsUploading(true);
    const uniqueKey = uuidv4();
    const fileName = `${uniqueKey}-${file.name}`;
    const fileRef = ref(storage, `/${blockId}/app/${fileName}`);
    const uploadTask = uploadBytesResumable(fileRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Update the progress bar
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
      },
      (error) => {
        // Handle unsuccessful uploads
        console.log(error);
        setIsUploading(false); // Set isUploading to false if the upload fails
        setUploadProgress(0); // Reset upload progress
      },
      () => {
        // Handle successful uploads on complete
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setAppData(prevState => ({...prevState, logoImageUrl: downloadURL}));
          setIsUploading(false); // Set isUploading to false when the upload completes
          setUploadProgress(0); // Reset upload progress
        });
      }
    );
  };

  const handleSubmit = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    await setDoc(doc(COLLECTION_APPS, blockId), appData, {merge: true});

    onClose();
  }, [app, appData, blockId, onClose]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Add App</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          {app && <AppAliasMenuItem appId={app?.id}/>}
          <InputLabel>Title</InputLabel>
          <TextField
            fullWidth
            value={appData.title}
            onChange={(e) =>
              setAppData(prevState => ({
                ...prevState,
                title: e.target.value
              }))
            }
            required
            variant="standard"
          />

          <InputLabel sx={{mt: 4}}>Description</InputLabel>
          <TextField
            value={appData.description}
            onChange={(e) =>
              setAppData(prevState => ({
                ...prevState,
                description: e.target.value
              }))
            }
            required
            multiline
            rows={4}
            fullWidth
            variant="standard"
            sx={{mt: 2}}
          />

          <InputLabel sx={{mt: 4}}>Logo</InputLabel>
          <Button variant="outlined" component="label" sx={{mt: 2}}>
            Upload Logo
            <input type="file" hidden onChange={handleFileChange}/>
          </Button>
          <Box sx={{mt: 2}}>
            {isUploading &&
                <LinearProgress variant="determinate"
                                value={uploadProgress}
                                style={{
                                  maxWidth: 200,
                                }}
                />}
            {appData.logoImageUrl &&
                <img src={appData.logoImageUrl} alt="Preview"
                     style={{
                       marginTop: 10,
                       maxWidth: 200,
                       maxHeight: 200
                     }}/>}
          </Box>

          <InputLabel sx={{mt: 4}}>App Type</InputLabel>
          <FormControl fullWidth sx={{mt: 2}}>
            <Select
              value={appData.appType}
              onChange={(e) =>
                setAppData(prevState => ({
                  ...prevState,
                  appType: e.target.value as AppType
                }))}
              required
              variant="standard"
            >
              <MenuItem value={AppType.EMAIL_SENDER}>이메일 작성기</MenuItem>
              <MenuItem value={AppType.XLSX_AGGREGATOR}>엑셀 파일 계산기</MenuItem>
              <MenuItem value={AppType.TEXT_DIFF_INPUTS}>두 텍스트 비교기</MenuItem>
            </Select>
          </FormControl>

          {
            appData.appType === AppType.EMAIL_SENDER &&
              <Box sx={{ml: 4}}><BlockTreeProvider blockId={blockId}>
                  <AppFormVoiceInput
                      app={appData as Partial<SchemaAppEmailSender>}
                      setApp={setAppData}
                  />
              </BlockTreeProvider>
              </Box>
          }

          {
            appData.appType === AppType.XLSX_AGGREGATOR &&
              <Box sx={{ml: 4}}><BlockTreeProvider blockId={blockId}>
                  <AppFormXlsxAggregator
                      app={appData as Partial<SchemaAppXlsxAggregator>}
                      setApp={setAppData}
                  />
              </BlockTreeProvider>
              </Box>
          }

          {
            appData.appType === AppType.TEXT_DIFF_INPUTS &&
              <Box sx={{ml: 4}}><BlockTreeProvider blockId={blockId}>
                  <AppFormTextDiff
                      app={appData as Partial<SchemaAppTwoTextInputs>}
                      setApp={setAppData}
                  />
              </BlockTreeProvider>
              </Box>
          }

        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit"
                  disabled={isUploading || !appData.title || !appData.description || !appData.appType}>Submit</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AppFormDialog;
