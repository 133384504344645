import React, {useEffect, useState} from 'react';
import {Box, Typography, useTheme} from '@mui/material';
import RunDetailsDialog from "./RunDetailsDialog";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {useBlockRun} from '../../../hooks/useBlockRun';
import {RunResult} from "tobl-data-schema/dist/schema/block/run/run";
import {
  useGetBlockFromBlockTreeLookup
} from "../../../hooks/useGetBlockFromBlockTreeLookup";
import BlockTypeIcon from "../BlockTypeIcon";

interface BlockRunProps {
  runId: string;
  blockId: string;
}

const BlockRun: React.FC<BlockRunProps> = ({runId, blockId}) => {
  const theme = useTheme();
  const {run} = useBlockRun(blockId, runId, true);

  const [isDiagramOpen, setDiagramOpen] = useState(false);
  const [circleColor, setCircleColor] = useState(theme.palette.primary.light)
  const [isHovered, setIsHovered] = useState(false);

  // Effect that triggers on 'run' update
  useEffect(() => {
    setCircleColor(
      run?.runResult === RunResult.success ? theme.palette.text.primary :
        run?.runResult === RunResult.failure ? theme.palette.error.main : theme.palette.primary.light
    )
  }, [run]); // Dependency array includes 'run'

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  const {block} = useGetBlockFromBlockTreeLookup(blockId);

  const openRunDetailsDialog = () => {
    setDiagramOpen(true);
  };

  const closeRunDetailsDialog = () => {
    setDiagramOpen(false);
  };

  const size = '40px';

  if (!run) {
    return <Box
      onClick={openRunDetailsDialog}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      sx={{
        width: size,
        height: size,
        borderRadius: '1px',
        border: `0.5px solid ${theme.palette.text.primary}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <MoreHorizIcon fontSize={'small'}/>
    </Box>
  }

  return (
    <>
      <Box
        key={"run-" + run.id}
        onClick={openRunDetailsDialog}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        sx={{
          width: size,
          height: size,
          borderRadius: '2px',
          border: `0.5px ${run?.runResult === RunResult.pending ? "dashed" : "solid"} ${circleColor}`,
          backgroundColor: isHovered ? circleColor : theme.palette.background.default,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          overflow: 'hidden',
          fontSize: '22px',
          transform: isHovered ? 'scale(1.5)' : 'scale(1)',
          transition: 'transform 0.1s ease',
        }}
      >
        {block &&
          (isHovered
            ? <Typography
              width={80}
              variant="subtitle2"
              align="center"
              fontSize='0.38rem'
              color={theme.palette.background.default}
            >
              {block?.title || 'No Title'}
            </Typography>
            : <BlockTypeIcon block={block}/>
          )
        }


      </Box>

      <RunDetailsDialog
        key={run.id}
        runId={runId}
        onClose={closeRunDetailsDialog}
        isDiagramOpen={isDiagramOpen}
        blockId={blockId}
      />
    </>
  );
}


export default BlockRun;
