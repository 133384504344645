import React, {useCallback, useState} from 'react';
import {
  Alert,
  Box,
  Divider,
  Grid,
  LinearProgress,
  List,
  Snackbar,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import DocumentsViewActionButtons, {
  ViewMode
} from './DocumentsViewActionButtons';
import {deleteForTraceIds} from "../../../../cloud_functions/deleteForTraceIds";
import exportDataToXLSX from "./exportDataToXLSX";
import {DocumentPreviewCard} from "./preview/DocumentPreviewCard";
import {DocumentPreviewListItem} from "./preview/DocumentPreviewListItem";
import {SchemaBlock} from "tobl-data-schema/dist/schema/block/block";
import useFindTriggerBlock from "../../../../hooks/useFindTriggerBlock";
import MessageNoDocuments from "./MessageNoDocuments";
import InfiniteScroll from "react-infinite-scroll-component";
import {useTraces} from "../../../../hooks/useTraces";

interface Props {
  projectBlock: SchemaBlock;
}

const DocumentsView: React.FC<Props> = ({projectBlock}) => {
  const theme = useTheme();

  const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set());
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const [currentView, setCurrentView] = useState<ViewMode>(ViewMode.PREVIEW);
  const [areAllSelected, setAreAllSelected] = useState(false);

  const [page, setPage] = useState(1)
  const [exportProgress, setExportProgress] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [sortOption, setSortOption] = useState<'title' | 'createdAt'>('createdAt');
  const projectBlockId = projectBlock.id;

  const traces = useTraces(projectBlock.id, sortOption, page);
  const triggerBlock = useFindTriggerBlock(projectBlockId);

  const handleViewChange = (newView: ViewMode) => {
    setCurrentView(newView);
  };

  const handleCheckboxChange = useCallback((traceId: string) => {
    const newSelectedItems = new Set(selectedItems);
    if (!newSelectedItems.has(traceId)) {
      newSelectedItems.add(traceId);
    } else {
      newSelectedItems.delete(traceId);
    }
    setSelectedItems(newSelectedItems);
    if (traces && newSelectedItems.size === traces.length) {
      setAreAllSelected(true);
    }
  }, [selectedItems, traces]);

  const handleDeleteSelected = useCallback(async () => {
    if (!window.confirm(`Are you sure you want to delete ${selectedItems.size} items?`)) {
      return;
    }
    setSnackbarOpen(true);
    setAreAllSelected(false);
    try {
      await deleteForTraceIds({traceIds: Array.from(selectedItems)});
    } catch (error) {
      console.error("Error deleting items:", error);
    }
    setSelectedItems(new Set());
    setSnackbarOpen(false);
  }, [selectedItems]);

  const handleExportSelected = useCallback(() => {
    setExportProgress(0);
    if (!traces) {
      return;
    }
    exportDataToXLSX(
      Array.from(selectedItems),
      traces,
      setExportProgress
    ).then(() => {
      setExportProgress(0);
    });
  }, [selectedItems, traces]);

  const handleSelectAll = useCallback(() => {
    if (areAllSelected) {
      setSelectedItems(new Set());
    } else {
      if (traces) {
        const allTraceIds = new Set(traces.map(trace => trace.id));
        setSelectedItems(allTraceIds);
      }
    }
    setAreAllSelected(prev => !prev);
  }, [areAllSelected, traces]);

  return (
    <Box>
      <Box
        maxWidth={1280}
        margin="auto"
        display="flex"
        mt={isSmallScreen ? 0 : 1}
        pb={isSmallScreen ? 1 : 1}
        alignItems={'center'}
        justifyContent={'end'}
      >
        {projectBlock && (traces && traces.length !== 0) &&
            <DocumentsViewActionButtons
                selectedItems={selectedItems}
                handleSelectAll={handleSelectAll}
                handleDeleteSelected={handleDeleteSelected}
                handleExportSelected={handleExportSelected}
                block={projectBlock}
                handleViewChange={handleViewChange}
                currentView={currentView}
                setSortOption={setSortOption}
                sortOption={sortOption}
                exportProgress={exportProgress}
            />}
      </Box>
      {(traces ? traces.length === 0
            ? <MessageNoDocuments triggerBlock={triggerBlock}/>
            : <InfiniteScroll
              dataLength={traces.length} //This field should contain the total length of the data array
              next={() => setPage(prevPage => prevPage + 1)}
              hasMore={true} //Replace with a condition to stop fetching when you run out of data
              loader={<h4>Loading...</h4>}
            > {currentView === ViewMode.PREVIEW ?
              <Box padding={isSmallScreen ? "0" : "20px 0"}>
                <Grid container spacing={2}>
                  {traces.map(trace => (
                    <DocumentPreviewCard
                      key={trace.id}
                      trace={trace}
                      onCheckboxChange={handleCheckboxChange}
                      isSelected={selectedItems.has(trace.id)}
                    />
                  ))}
                </Grid>
              </Box>
              : <List>
                {traces.map((trace, index) => (
                  <Box key={trace.id}>
                    {index === 0 && <Divider color={theme.palette.text.primary}/>}
                    <DocumentPreviewListItem
                      key={trace.id}
                      trace={trace}
                      onCheckboxChange={handleCheckboxChange}
                      isSelected={selectedItems.has(trace.id)}
                    />
                    <Divider/>
                  </Box>
                ))}
              </List>}
            </InfiniteScroll>
          : <Box>
            <LinearProgress/>
          </Box>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="warning">
          Deleting selected items...
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default DocumentsView;
