import React from 'react';
import {Button} from '@mui/material';
import {useTranslation} from "react-i18next";
import {MessageView} from '../../../../views/app/MessageView';
import noBlocksImage from '../../../../assets/images/noBlocksImage2.png';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const MessageNoBlocks: React.FC<Props> = ({open, setOpen}) => {
  const {t} = useTranslation();

  return (
    <MessageView
      photo={noBlocksImage}
      title="noBlocks.title"
      message="noBlocks.message"
      button={(
        <Button variant={'outlined'} onClick={() => setOpen(!open)}>
          {t("noBlocks.button")}
        </Button>
      )}
    />
  );
}

export default MessageNoBlocks;
