import React, {useEffect, useState} from 'react';
import {Box, Card, Typography, useTheme} from '@mui/material';
import BlockMenuButton from "../../components/block/menu/BlockMenuButton";
import BlockTypeIcon from "./BlockTypeIcon";
import {useGetChildrenBlockIds} from "../../hooks/useGetChildrenBlockIds";
import {
  BlockActiveStatus,
  BlockType
} from "tobl-data-schema/dist/schema/block/block";
import {useTranslation} from "react-i18next";
import {useBlockSubscription} from "../../hooks/useBlockSubscription";

interface BlockRowProps {
  blockId: string;
}

const BlockRow: React.FC<BlockRowProps> = ({blockId}) => {
  const theme = useTheme();
  const [hasChildren, setHasChildren] = useState(true);
  const {block} = useBlockSubscription(blockId);
  const childrenBlockIds = useGetChildrenBlockIds(blockId);
  const {t} = useTranslation();
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    setHasChildren(childrenBlockIds.length !== 0);
  }, [childrenBlockIds]);

  if (!block) {
    return <Box/>;
  }

  const blockType = block.blockType === BlockType.project
    ? block.configuration.blockType_Project
    : block.blockType === BlockType.input
      ? block.configuration.blockType_Input
      : block.blockType === BlockType.processor
        ? block.configuration.blockType_Processor
        : block.blockType;

  return (
    <Box>
      <Box display="flex" flexDirection="row" alignItems="center">
        <BlockMenuButton block={block} parentBlockId={block.parentBlockIds[0]}>
          <Card
            elevation={0}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            style={{
              width: 250,
              height: 120,
              backgroundColor: isHovered ? theme.palette.secondary.light : theme.palette.background.default,
              boxShadow: 'none',
              border: '1px solid',
              borderColor: theme.palette.text.primary,
              padding: '10px 15px',
              marginLeft: 10,
              cursor: 'pointer',
              opacity: block.activeStatus !== BlockActiveStatus.active ? 0.4 : 1
            }}
          >

            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              fontSize={'18px'}
            >
              <BlockTypeIcon block={block}/>
              <Typography variant="body2" fontSize={'0.75rem'}
                          sx={{ml: 1}}
              >
                {t(blockType)}
              </Typography>
            </Box>
            <Typography variant="subtitle1" sx={{mt: 1}}>
              {block.title}
            </Typography>
          </Card>
        </BlockMenuButton>
      </Box>
    </Box>
  );
};

export default BlockRow;
