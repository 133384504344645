import {useEffect, useState} from 'react';
import {doc, getDoc, onSnapshot} from 'firebase/firestore';
import {db} from "../firebase/firebase";
import {parseDoc} from "../firebase/firestore/parseDoc";
import {SchemaBlockRun} from "tobl-data-schema/dist/schema/block/run/run";

export const useBlockRun = (blockId: string, runId: string, useRealTime = false) => {
  const [run, setRun] = useState<SchemaBlockRun | null>(null);

  useEffect(() => {
    const runDocRef = doc(db, `blocks/${blockId}/runs`, runId);

    if (useRealTime) {
      // Subscribe to real-time updates
      const unsubscribe = onSnapshot(runDocRef, (runDoc) => {
        if (runDoc.exists()) {
          setRun(parseDoc<SchemaBlockRun>(runDoc));
        } else {
          console.warn(`Run does not exist - onSnapshot ${blockId}/${runId}`);
          setRun(null);
        }
      }, (err) => {
        console.error("Error fetching run", err);
        setRun(null);
      });

      // Cleanup: Unsubscribe from real-time updates
      return () => {
        unsubscribe();
      };
    } else {
      // Fetch data once
      const fetchRun = async () => {
        const runDoc = await getDoc(runDocRef);
        if (runDoc.exists()) {
          setRun(parseDoc<SchemaBlockRun>(runDoc));
        } else {
          console.warn(`Run does not exist - getDoc ${blockId}/${runId}`);
        }
      };

      fetchRun();
    }
  }, [blockId, runId, useRealTime]);

  return {run};
};
