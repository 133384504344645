import React, {useEffect, useState} from 'react';
import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {useLocation, useNavigate} from "react-router-dom";
import {useGlobalContext} from "../../hooks/GlobalContext";
import {GRAY, GREEN} from "../../theme/colors";
import {useTranslation} from "react-i18next";
import Logo from "./Logo";
import {TopBarLanguageButton} from "./TopBarLanguageButton";
import {TopBarProfileButton} from "./TopBarProfileButton";
import TopBarScreenModeButton from "./TopBarScreenModeButton";

interface Props {
  children: React.ReactNode;
}

export function MainViewTopBar({children}: Props) {
  const theme = useTheme();
  const {user} = useGlobalContext();
  const navigate = useNavigate();
  const fullScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const {t} = useTranslation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(0);
  const location = useLocation();
  const menuItems = [
    {name: "intro", path: "/"},
    // { name: "features", path: "/features" },
    {name: "business", path: "/business"},
    {name: "pricing", path: "/pricing"},
    {name: "contact", path: "/contact"},
  ];

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
      setPrevScrollPos(currentScrollPos);
      setScrollPosition(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === 'keydown' && (['Tab', 'Shift'].includes((event as React.KeyboardEvent).key))) {
      return;
    }
    setDrawerOpen(open);
  };

  const homeHandleClick = () => navigate('/');

  const renderLogoOrMenuIcon = () => {
    return (
      <Box onClick={homeHandleClick} ml={'5px'}>
        <Logo size={fullScreen ? 28 : 22}/>
      </Box>
    );
  };

  const renderMenuItems = () => (
    <Box sx={{display: 'flex', flexGrow: 1, justifyContent: 'center'}}>
      {menuItems.map((text) => {
        const isCurrentPath = location.pathname === text.path;
        return (
          <Button key={text.name} variant={'text'}
                  onClick={() => navigate(text.path)}>
            <Typography
              variant={'h6'}
              color={isCurrentPath ? GREEN.w500 : GRAY.w100}
            >
              {t(text.name)}
            </Typography>
          </Button>
        );
      })}
    </Box>
  );

  const renderDrawerListItems = () => (
    menuItems.map((text) => {
      const isCurrentPath = location.pathname === text.path;
      return (
        <ListItem key={text.name} onClick={() => navigate(text.path)}>
          <ListItemText
            primary={t(text.name)}
            primaryTypographyProps={{
              color: isCurrentPath ? GREEN.w500 : GRAY.w100,
              variant: 'h6',
              padding: '10px 5px'
            }}
          />
          <Divider/>
        </ListItem>
      );
    })
  );

  const renderDrawer = () => (
    <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={toggleDrawer(false)}
      sx={{
        '& .MuiDrawer-paper': {
          borderRadius: 0,
          boxShadow: 'none',
          border: 'none',
        },
      }}
    >
      <Box
        width={300}
        height={'100%'}
        bgcolor={GRAY.w700}
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <Box display={'flex'} justifyContent={'end'} margin={'20px 15px'}>
          {renderTopBarButtons()}
        </Box>
        <List>{renderDrawerListItems()}</List>
      </Box>
    </Drawer>
  );

  const renderTopBarButtons = () => (
    <Box sx={{display: 'flex', alignItems: 'center'}}>
      <TopBarScreenModeButton isDarkMode={true}/>
      <TopBarLanguageButton/>
      {user
        ? <Button
          variant="outlined"
          size="small"
          onClick={() => navigate('/admin')}
          sx={{
            marginLeft: '15px',
            borderRadius: '100px',
            background: 'linear-gradient(90deg,#F17F4A 10%, #E44D73 70%)',
            border: 'none',
            color: 'white',
            padding: '10px 30px',
            '&:hover': {
              background: 'linear-gradient(90deg, #E44D73 10%, #F17F4A 70%)',
              border: 'none',
            },
          }}
        >
          {t("open_app")}
        </Button>
        : <TopBarProfileButton/>}
    </Box>
  );

  return (
    <>
      <AppBar
        position="fixed"
        color="transparent"
        sx={{
          border: 'none',
          transition: 'top 0.3s',
          top: visible ? 0 : '-100px',
        }}
      >
        <Toolbar style={{
          justifyContent: 'space-between',
          height: fullScreen ? '100px' : '60px',
          padding: fullScreen ? '0px 25px' : '0px 5px 0px 10px',
          background: scrollPosition <= 200 ? 'transparent' : 'rgba(0, 0, 0, 0.8)',
          backdropFilter: scrollPosition <= 200 ? 'none' : 'blur(5px)',
          transition: 'background 0.5s ease-in-out'
        }}>
          {fullScreen ? (
            <>
              {renderLogoOrMenuIcon()}
              {renderMenuItems()}
              {renderTopBarButtons()}
            </>
          ) : (
            <>
              {renderLogoOrMenuIcon()}
              <IconButton
                edge="start"
                aria-label="menu"
                onClick={toggleDrawer(true)}
              >
                <MenuIcon sx={{color: GRAY.w100, fontSize: '28px'}}/>
              </IconButton>
              {renderDrawer()}
            </>
          )}
        </Toolbar>
      </AppBar>
      {children}
    </>
  );
}
