import {doc, getDoc, updateDoc} from "firebase/firestore";
import {COLLECTION_BLOCKS} from "../models/block/block";
import {parseDoc} from "./firestore/parseDoc";
import {SchemaBlock} from "tobl-data-schema/dist/schema/block/block";

export async function updateBlockParent(
  blockId: string,
  oldParentBlockId: string,
  newParentBlockId: string
) {
  const blockDocRef = doc(COLLECTION_BLOCKS, blockId);

  // Fetch the current document to get the existing parentBlockIds
  const docSnap = await getDoc(blockDocRef);

  if (docSnap.exists()) {
    const blockData = parseDoc<SchemaBlock>(docSnap);
    const parentBlockIds = blockData?.parentBlockIds || [];

    // Remove the old parent ID and add the new one
    const updatedParentBlockIds = parentBlockIds.filter((id) => {
      return id !== oldParentBlockId;
    });
    updatedParentBlockIds.push(newParentBlockId);

    // Update the document with the modified array
    await updateDoc(blockDocRef, {
      parentBlockIds: updatedParentBlockIds
    });
  }
}
