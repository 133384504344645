import React, {useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  List,
  useMediaQuery,
  useTheme
} from '@mui/material';
import {useBlockTree} from "../../../hooks/useBlockTree";
import {useTranslation} from "react-i18next";
import {TypeBlockTree} from "tobl-data-schema/dist/api/block/block/getBlockTree";
import BlockMenuMoveButtonItem from "../menu/BlockMenuMoveButtonItem";
import AddIcon from "@mui/icons-material/Add";
import {BlockDocumentType} from "tobl-data-schema/dist/schema/block/document/document";
import {SchemaBlockEdit} from "tobl-data-schema/dist/schema/block/block";

interface BlockMenuMoveButtonProps {
  blockData: SchemaBlockEdit;
  updateBlockData: (key: string, value: any) => void;
}

const EditFormProcessorMergerItemAddButton: React.FC<BlockMenuMoveButtonProps> = (
  {
    blockData,
    updateBlockData
  }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const {blockTree, refreshBlockTree} = useBlockTree();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const {t} = useTranslation();
  const blockInputType = BlockDocumentType.json_document && BlockDocumentType.text_documents && BlockDocumentType.text_document;

  const renderBlockTree = (blockTree: TypeBlockTree, level = 0) => {
    return Object.keys(blockTree).map((blockId) => {
      if (blockData.parentBlockIds.includes(blockId)) return null;
      const subtree = blockTree[blockId];
      return (
        <React.Fragment key={blockId}>
          <BlockMenuMoveButtonItem
            level={level}
            blockId={blockId}
            movingBlockInputType={blockInputType}
            onClick={() => handleBlockItemClick(blockId)}
          />
          {subtree && renderBlockTree(subtree, level + 1)}
        </React.Fragment>
      );
    });
  };

  const handleBlockItemClick = (blockId: string) => {
    updateBlockData("parentBlockIds", [...blockData.parentBlockIds, blockId]);
    handleClose();
  };

  const handleOpen = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!blockTree) {
    return <LinearProgress/>;
  }

  return (
    <>
      <Button
        variant={"outlined"}
        fullWidth
        sx={{margin: "5px 0px 0px"}}
        startIcon={<AddIcon/>}
        onClick={handleOpen}
      >
        {t("Add Input Source")}
      </Button>
      {open && (
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth
          maxWidth={'sm'}
          fullScreen={fullScreen}
        >
          <DialogTitle>{t("Add Input Source")}</DialogTitle>
          <DialogContent>
            <List>
              {renderBlockTree(blockTree, 0)}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              {t('cancel')}
            </Button>
          </DialogActions>
        </Dialog>)}
    </>
  );
};

export default EditFormProcessorMergerItemAddButton;
