import React from "react";
import {Box, Button, CardMedia, Container, Grid, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

// Define the shape of props expected by the MessageView component.
interface MessageViewProps {
  photo?: string;
  title?: string;
  message?: string;
  buttonText?: string;
  handleClick?: () => void;
  button?: React.ReactNode;
}

export const MessageView: React.FC<MessageViewProps> = ({photo, title, message, buttonText, handleClick, button}) => {
  const {t} = useTranslation();

  return (
    <Container sx={{height: '80vh'}}>
      <Box
        sx={{
          margin: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          textAlign: 'center',
          maxWidth: '600px',
          padding: '30px'
        }}
      >
        {photo && <Grid item xs={12}>
          <CardMedia
            component="img"
            image={photo}
            alt="Error Image"
            sx={{width: '100%', height: '100%'}}
          />
        </Grid>}
        {title && <Typography variant="h3" margin={'40px 0 20px'}>
          {t(title)}
        </Typography>}
        {message && <Typography variant="body1" mb={'40px'}>
          {t(message)}
        </Typography>}
        {button ? button : buttonText && (
          <Button variant={'outlined'} onClick={handleClick}>
            {t(buttonText)}
          </Button>
        )}
      </Box>
    </Container>
  );
}
