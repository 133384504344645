// ContainerTableRow.tsx

import React from 'react';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {useTranslation} from "react-i18next";
import {
  SchemaBlock_Team_Config,
  TeamSubscriptionPricing
} from "tobl-data-schema/dist/schema/block/block_type/team";
import {AddEditMode} from "../menu/BlockMenuAddOrEditDialog";
import {useGlobalContext} from '../../../hooks/GlobalContext';
import {BlockEditFormRow} from "./BlockEditFormRow";

interface Props {
  configuration: SchemaBlock_Team_Config;
  updateConfigData: (key: string, value: any) => void;
  addEditMode: AddEditMode;
}

const EditFormTeam: React.FC<Props> = ({
                                         configuration,
                                         updateConfigData,
                                       }) => {
  const {t} = useTranslation();

  const globalContext = useGlobalContext();
  const isAdmin = globalContext.isAdmin;

  return (
    <>
      {isAdmin && <BlockEditFormRow label={t("Subscription Pricing")}>
          <Select
              value={configuration.teamSubscriptionPricing || ""}
              onChange={(e) => updateConfigData('teamSubscriptionPricing', e.target.value)}
              fullWidth
              onClick={e => e.stopPropagation()}
          >
              <MenuItem value={TeamSubscriptionPricing.full_price}>
                {t("Full Price")}
              </MenuItem>
              <MenuItem value={TeamSubscriptionPricing.discount_50_percent}>
                {t("50% Discount")}
              </MenuItem>
          </Select>
      </BlockEditFormRow>
      }
    </>
  );
};

export default EditFormTeam;
