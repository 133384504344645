// ContainerTableRow.tsx

import React from 'react';
import {
  SchemaBlock_Project_Config
} from "tobl-data-schema/dist/schema/block/block_type/project";
import {AddEditMode} from "../menu/BlockMenuAddOrEditDialog";
import {BlockTypeSelector} from './BlockTypeSelector';
import {BlockType} from "tobl-data-schema/dist/schema/block/block";

interface Props {
  configuration: SchemaBlock_Project_Config;
  updateConfigData: (key: string, value: any) => void;
  addEditMode: AddEditMode;
}

const EditFormProject: React.FC<Props> = ({
  configuration,
  updateConfigData,
  addEditMode
}) => {
  if (addEditMode !== AddEditMode.ADD) {
    return null;
  }
  return <BlockTypeSelector
    selectedType={configuration.blockType_Project}
    updateConfigData={updateConfigData}
    blockType={BlockType.project}
  />
};

export default EditFormProject;
