import {
  BlockType_Processor,
  SchemaBlock_Processor_Config
} from "tobl-data-schema/dist/schema/block/block_type/processor";
import {EditFormProcessorAi} from "./EditFormProcessorAi";
import {EditFormProcessorText} from "./EditFormProcessorText";
import {EditFormProcessorAudio} from "./EditFormProcessorAudio";
import React from "react";
import {SchemaBlockEdit} from "tobl-data-schema/dist/schema/block/block";
import {EditFormProcessorMerger} from "./EditFormProcessorMerger";
import {
  EditFormProcessorTableSummarizer
} from "./EditFormProcessorTableSummarizer";
import {EditFormProcessorFreeTextInput} from "./EditFormProcessorFreeTextInput";
import EditFormProcessorSendEmail from "./EditFormProcessorSendEmail";

export function renderProcessorForms(
  blockData: SchemaBlockEdit,
  configuration: SchemaBlock_Processor_Config,
  updateBlockData: any,
  updateConfigData: any
): JSX.Element {
  switch (configuration.blockType_Processor) {
    case BlockType_Processor.google_document_ai:
    case BlockType_Processor.text_to_json_ai:
    case BlockType_Processor.text_to_text_ai:
    case BlockType_Processor.texts_to_text_ai:
    case BlockType_Processor.search_google_with_ai:
    case BlockType_Processor.api_google_news:
    case BlockType_Processor.api_google_news_multi:
    case BlockType_Processor.search_wikipedia_with_ai:
      return (
        <EditFormProcessorAi
          configuration={configuration}
          updateConfigData={updateConfigData}
        />
      );
    case BlockType_Processor.table_summarizer:
      return (
        <EditFormProcessorTableSummarizer
          configuration={configuration}
          updateConfigData={updateConfigData}
        />
      );
    case BlockType_Processor.text_chunker:
      return (
        <EditFormProcessorText
          configuration={configuration}
          updateConfigData={updateConfigData}
        />
      );
    case BlockType_Processor.text_slicer:
    case BlockType_Processor.text_splitter:
      return (
        <EditFormProcessorText
          configuration={configuration}
          updateConfigData={updateConfigData}
        />
      );
    case BlockType_Processor.triggered_text_document:
      return <EditFormProcessorFreeTextInput
        configuration={configuration}
        updateConfigData={updateConfigData}
      />;
    case BlockType_Processor.text_merger:
    case BlockType_Processor.texts_merger:
      return (
        <EditFormProcessorMerger
          blockData={blockData}
          updateBlockData={updateBlockData}
        />
      );
    case BlockType_Processor.audio_chunker:
    case BlockType_Processor.audio_to_text_ai:
      return (
        <EditFormProcessorAudio configuration={configuration}
                                updateConfigData={updateConfigData}/>
      );
    case BlockType_Processor.audio_compressor:
      return (
        <EditFormProcessorAudio configuration={configuration}
                                updateConfigData={updateConfigData}/>
      );
    case BlockType_Processor.send_email:
      return (
        <EditFormProcessorSendEmail
          configuration={configuration}
          updateConfigData={updateConfigData}
        />
      );
    default:
      return <></>;
  }
}
