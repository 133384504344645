import {ErrorView} from "./views/app/ErrorView";
import React, {ReactNode} from "react";
import AdminView from "./views/app/AdminView";
import {InviteHandler} from "./views/invite/InviteHandler";
import HomeView from "./views/app/HomeView";
import {WithTopBar} from "./WithTopBar";
// import {ContactView} from "./views/app/ContactView";
import {LoginView} from "./views/app/LoginView";
import MainViewHomeScreen from "./views/main/MainViewHomeScreen";
import MainViewProductScreen from "./views/main/MainViewProductScreen";
import MainViewPricingScreen from "./views/main/MainViewPricingScreen";
import MainViewContactScreen from "./views/main/MainViewContactScreen";
import MainViewBusinessScreen from "./views/main/MainViewBusinessScreen";
import {MainViewTopBar} from "./components/top_bar/MainViewTopBar";
import AppView from "./views/app/AppView";

export const routes: {
    path: string;
    element: ReactNode;
    errorElement?: ReactNode
  }[] = [
    {
      path: '/',
      element: <MainViewTopBar><MainViewHomeScreen/></MainViewTopBar>,
      errorElement: <ErrorView/>,
    },
    {
      path: '/app',
      element: <WithTopBar><HomeView/></WithTopBar>,
      errorElement: <ErrorView/>,
    },
    {
      path: '/app/:appId',
      element: <AppView/>,
      errorElement: <ErrorView/>,
    },
    {
      path: '/admin',
      element: <WithTopBar><HomeView/></WithTopBar>,
      errorElement: <ErrorView/>,
    },
    {
      path: '/admin/:blockId',
      element: <WithTopBar><AdminView/></WithTopBar>,
      errorElement: <ErrorView/>,
    },
    {
      path: '/admin/:blockId/:childBlockId',
      element: <WithTopBar><AdminView/></WithTopBar>,
      errorElement: <ErrorView/>,
    },
    {
      path: '/login',
      element: <MainViewTopBar><LoginView/></MainViewTopBar>,
      errorElement: <ErrorView/>,
    },
    {
      path: '/features',
      element: <MainViewTopBar><MainViewProductScreen/></MainViewTopBar>,
      errorElement: <ErrorView/>,
    },
    {
      path: '/business',
      element: <MainViewTopBar><MainViewBusinessScreen/></MainViewTopBar>,
      errorElement: <ErrorView/>,
    },
    {
      path: '/pricing',
      element: <MainViewTopBar><MainViewPricingScreen/></MainViewTopBar>,
      errorElement: <ErrorView/>,
    },
    {
      path: '/contact',
      element: <MainViewTopBar><MainViewContactScreen/></MainViewTopBar>,
      errorElement: <ErrorView/>,
    },
    {
      path: '/error',
      element: <ErrorView/>,
      errorElement: <ErrorView/>,
    },
    {
      path: '/invite/:token',
      element: <InviteHandler/>,
      errorElement: <ErrorView/>,
    },
  ]
;
