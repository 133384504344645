import React from "react";
import photo from '../../assets/images/error.png';
import {useNavigate} from "react-router-dom";
import {MessageView} from "./MessageView";

export function ErrorView() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/`);
  };

  return (
    <MessageView
      photo={photo}
      title="error.title"
      message="error.message"
      buttonText="error.button"
      handleClick={handleClick}
    />
  );
}
