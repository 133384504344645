import {useEffect, useState} from 'react';
import {v4 as uuidv4} from "uuid";
import {SchemaTrace} from "tobl-data-schema/dist/schema/trace";
import {AddRunRequest} from "tobl-data-schema/dist/api/block/block/run/addRun";
import {addRun} from "../../../cloud_functions/addRun";
import {doc, onSnapshot} from "firebase/firestore";
import {COLLECTION_TRACES} from "../../../models/trace";
import {parseDoc} from "../../../firebase/firestore/parseDoc";

export const useAiProcess = (inputBlockId: string, textPrompt?: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const [traceIdToSubscribe, setTraceIdToSubscribe] = useState<string>();
  const [toblTrace, setToblTrace] = useState<SchemaTrace>();
  const [output, setOutput] = useState<string>();

  useEffect(() => {
    if (!traceIdToSubscribe) return;
    const unsubscribe = onSnapshot(
      doc(COLLECTION_TRACES, traceIdToSubscribe),
      (doc) => {
        setToblTrace(parseDoc<SchemaTrace>(doc));
      }
    );
    return () => unsubscribe();
  }, [traceIdToSubscribe]);

  useEffect(() => {
    const blocks = toblTrace?.blocks;

    if (!blocks) return;

    const resultBlock = Object.values(blocks)
      .find(block => block.isResultBlock);

    if (!resultBlock) return;
    if (!isLoading) return;

    if (!resultBlock.preview) {
      return;
    }

    setOutput(resultBlock.preview);
    setIsLoading(false);
  }, [toblTrace]);

  const runAiProcess = () => {
    if (!textPrompt) return;
    setIsLoading(true);

    const traceId = uuidv4();

    const request: AddRunRequest = {
      blockId: inputBlockId,
      clientInputData: {
        text: textPrompt,
        title: "User Input"
      },
      traceId: traceId
    }
    setTraceIdToSubscribe(traceId);
    addRun(request)
  };

  return {runAiProcess, output, isLoading};
};
