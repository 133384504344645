import {useEffect, useState} from 'react';
import {getChildrenBlockIdsFromTree, useBlockTree} from './useBlockTree';

export const useGetChildrenBlockIds = (parentBlockId: string) => {
  const {blockTree} = useBlockTree();
  const [childBlockIds, setChildBlockIds] = useState<string[]>([]);

  useEffect(() => {
    if (!blockTree) {
      return;
    }
    const ids = getChildrenBlockIdsFromTree(blockTree, parentBlockId);
    setChildBlockIds(ids);
  }, [blockTree, parentBlockId]);

  return childBlockIds;
};
