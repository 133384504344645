import React from "react";
import {Box, Paper, Typography, useTheme} from "@mui/material";
import {useTranslation} from "react-i18next";

interface BlockEditFormRowProps {
  label: string;
  children: React.ReactNode;
  titleButton?: React.ReactNode;

}

export const BlockEditFormRow: React.FC<BlockEditFormRowProps> = ({
                                                                    label,
                                                                    children,
                                                                    titleButton,
                                                                  }) => {
  const theme = useTheme();
  const {t} = useTranslation();
  return (
    <>
      <Box
        display={'flex'}
        alignItems={'end'}
        justifyContent={'space-between'}
      >
        <Typography variant={'body2'} mt={'20px'}>{t(label)}</Typography>
        {titleButton}
      </Box>
      <Paper sx={{marginTop: "5px", borderColor: theme.palette.divider}}
             onClick={e => e.stopPropagation()}>{children}</Paper>
    </>
  );
};
