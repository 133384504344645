import React, {useEffect, useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from '@mui/material';

interface TitleChangeDialogProps {
  currentTitle: string;
  open: boolean;
  handleClose: (newTitle?: string) => void;
}

const TitleChangeDialog: React.FC<TitleChangeDialogProps> = ({
                                                               currentTitle,
                                                               open,
                                                               handleClose
                                                             }) => {
  const [newTitle, setNewTitle] = useState(currentTitle);

  useEffect(() => {
    setNewTitle(currentTitle);
  }, [currentTitle]);

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewTitle(event.target.value);
  };

  function handleCloseInternal(newTitle?: string) {
    // undefined means cancel
    if (!newTitle) {
      setNewTitle(currentTitle);
    }
    handleClose(newTitle);
  }

  if (!open) {
    return null;
  }

  return (
    <Dialog open={open} onClose={() => handleCloseInternal()}
            sx={{'& .MuiDialog-paper': {width: '80%', maxHeight: '80%'}}}
    >
      <DialogTitle>Change Title</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter the new title for the block.
        </DialogContentText>
        <TextField
          autoFocus
          type="text"
          fullWidth
          value={newTitle}
          onChange={handleTitleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCloseInternal()}>
          Cancel
        </Button>
        <Button onClick={() => handleCloseInternal(newTitle)}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TitleChangeDialog;
