import React, {useRef} from 'react';
import {Box, TextField} from '@mui/material';
import {addRun} from "../../../cloud_functions/addRun";
import {v4 as uuidv4} from "uuid";

interface TextInputProps {
  blockId: string;
  setProgress: React.Dispatch<React.SetStateAction<number>>;
  handleClose: () => void;
}

const TextInput: React.FC<TextInputProps> = ({
                                               blockId,
                                               setProgress,
                                               handleClose
                                             }) => {
  const textRef = useRef<HTMLInputElement>(null);
  const titleRef = useRef<HTMLInputElement>(null); // New ref for the title input

  const handleBlur = async () => {
    if (textRef.current && titleRef.current) {
      const text = textRef.current.value;
      const title = titleRef.current.value; // Get the value of the title input
      const traceId = uuidv4();
      setProgress(50);
      await addRun({
                     blockId,
                     traceId,
                     clientInputData: {
                       text,
                       title,
                     }
                   });
      setProgress(100); // Assuming the progress is set to 100 when the text is submitted
      handleClose();
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: 2,
        minWidth: '800px',
        width: '100%',
        height: '100%',
      }}
    >
      <TextField
        id="title-input"
        label="Title"
        defaultValue=""
        fullWidth
        inputRef={titleRef} // Assign the titleRef to the title input
      />
      <TextField
        id="outlined-multiline-static"
        label="Text Input"
        multiline
        minRows={20}
        defaultValue=""
        fullWidth
        sx={{
          border: '1px solid #000', // Add a border
          borderRadius: '4px', // Optional: Add border radius
          height: '100%', // Set the height to 100%
        }}
        inputRef={textRef}
        onBlur={handleBlur}
      />
    </Box>
  );
};

export default TextInput;
