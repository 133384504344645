import React, {useState} from 'react';
import Menu from '@mui/material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {SchemaBlock} from "tobl-data-schema/dist/schema/block/block";
import ManageBlockTreeMenuItem from './ManageBlockTreeMenuItem';
import CopyBlockTreeMenuItem from './CopyBlockTreeMenuItem';
import DeleteBlockMenuItem from "./DeleteBlockMenuItem";
import IconButton from "@mui/material/IconButton";
import {useBlockTree} from "../../../hooks/useBlockTree";
import ManageProjectAppMenuItem from "./ManageProjectAppMenuItem";

interface ContextMenuProps {
  block: SchemaBlock;
}

const ProjectBlockContextMenu: React.FC<ContextMenuProps> = ({block}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const {isBlockTreeContextProvided} = useBlockTree();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="more"
        size={'small'}
        onClick={handleClick}
      >
        <MoreVertIcon fontSize={'small'}/>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {isBlockTreeContextProvided && <ManageBlockTreeMenuItem block={block}/>}
        {isBlockTreeContextProvided &&
            <CopyBlockTreeMenuItem block={block} handleClose={handleClose}/>}
        <ManageProjectAppMenuItem blockId={block.id} handleClose={handleClose}/>
        <DeleteBlockMenuItem block={block} handleClose={handleClose}/>
      </Menu>
    </>
  );
};

export default ProjectBlockContextMenu;
