import React from "react";
import TextField from "@mui/material/TextField";
import {
  SchemaBlock_Processor_Config_SendEmail
} from "tobl-data-schema/dist/schema/block/block_type/processor";
import {BlockEditFormRow} from "./BlockEditFormRow";

interface EditFormProcessorSendEmailProps {
  configuration: SchemaBlock_Processor_Config_SendEmail;
  updateConfigData: (key: string, value: any) => void;
}

const EditFormProcessorSendEmail: React.FC<EditFormProcessorSendEmailProps> = ({
                                                                                 configuration,
                                                                                 updateConfigData
                                                                               }) => {
  return (
    <>
      <BlockEditFormRow label="From">
        <TextField
          value={configuration.from || ""}
          onChange={(e) => updateConfigData('from', e.target.value)}
          fullWidth
        />
      </BlockEditFormRow>

      <BlockEditFormRow label="To">
        <TextField
          value={configuration.to || ""}
          onChange={(e) => updateConfigData('to', e.target.value)}
          fullWidth
        />
      </BlockEditFormRow>

      <BlockEditFormRow label="Subject">
        <TextField
          value={configuration.subject || ""}
          onChange={(e) => updateConfigData('subject', e.target.value)}
          fullWidth
        />
      </BlockEditFormRow>

      <BlockEditFormRow label="CC">
        <TextField
          value={configuration.cc || ""}
          onChange={(e) => updateConfigData('cc', e.target.value)}
          fullWidth
        />
      </BlockEditFormRow>

      <BlockEditFormRow label="BCC">
        <TextField
          value={configuration.bcc || ""}
          onChange={(e) => updateConfigData('bcc', e.target.value)}
          fullWidth
        />
      </BlockEditFormRow>
    </>
  );
};

export default EditFormProcessorSendEmail;
