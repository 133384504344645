import React, {useEffect, useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from "@mui/material";
import {useTranslation} from 'react-i18next';
import {
  deleteDoc,
  doc,
  getDoc,
  serverTimestamp,
  setDoc
} from 'firebase/firestore';

import useGetAppAliasByAppId from "../../../hooks/useGetAppAliasByAppId";
import {
  APP_ALIAS_COLLECTION,
  SchemaAppAliasCreate
} from "../../../models/app_alias";
import AppAliasMenuItem from "./AppAliasMenuItem";

interface GroupAliasDialogProps {
  appId: string;
  open: boolean;
  onClose: () => void;
}

const AppAliasDialog: React.FC<GroupAliasDialogProps> = ({
                                                           appId,
                                                           open,
                                                           onClose
                                                         }) => {
  const {t} = useTranslation();
  const [newAlias, setNewAlias] = useState('');
  const [error, setError] = useState('');
  const {
    appAlias,
    loading,
    updateAppAlias
  } = useGetAppAliasByAppId(appId);

  useEffect(() => {
    if (appAlias) {
      setNewAlias(appAlias.alias);
    }
  }, [appAlias]);


  const handleAliasChange = async () => {
    if (newAlias.length < 2) {
      setError(t('The alias must be at least 2 characters.'));
      return;
    }


    // Check if the new alias already exists
    const aliasRef = doc(APP_ALIAS_COLLECTION, newAlias);
    const aliasSnap = await getDoc(aliasRef);

    if (aliasSnap.exists()) {
      setError(t('This alias already exists. Please choose a different one.'));
      return;
    }

    // Update the alias
    const groupAliasRef = doc(APP_ALIAS_COLLECTION, newAlias);

    const data: SchemaAppAliasCreate = {
      alias: newAlias,
      createdAt: serverTimestamp(),
      appId: appId,
      id: newAlias,
      updatedAt: serverTimestamp()
    }

    await setDoc(groupAliasRef, data, {merge: true});
    if (appAlias) {
      await deleteDoc(doc(APP_ALIAS_COLLECTION, appAlias.id));
    }
    updateAppAlias();

    handleClose();
  };

  const handleAliasDelete = async () => {
    if (!appAlias) {
      return;
    }
    // Delete the alias
    await deleteDoc(doc(APP_ALIAS_COLLECTION, appAlias.id));

    updateAppAlias();

    handleClose();
  };

  const handleClose = () => {
    onClose();
    setError('');
    setNewAlias('');
  };

  if (!open || loading) {
    return null;
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t("Set Alias for the App")}</DialogTitle>
      <DialogContent>
        <AppAliasMenuItem appId={appId}/>
        <TextField
          autoFocus
          margin="dense"
          label={t("New Alias")}
          type="text"
          fullWidth
          value={newAlias}
          onKeyUp={(e) => {
            e.stopPropagation();
          }}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
          onChange={(e) => {
            e.stopPropagation();
            setNewAlias(e.target.value);
          }
          }
          error={Boolean(error)}
          helperText={error}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t("Cancel")}</Button>
        {appAlias &&
            <Button onClick={handleAliasDelete}>{t("Delete Alias")}</Button>}
        <Button onClick={handleAliasChange}>{t("Change Alias")}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AppAliasDialog;
