import React from 'react';
import './BlockTreeMap.css';
import BlockRun from "../../../../../views/block/run/BlockRun";
import {useTheme} from "@mui/material";

export type TreeNode = {
  blockId: string;
  runId: string;
  children: TreeNode[];
};

interface BlockTreeMapViewProps {
  treeData: TreeNode | null;
}

const BlockTreeMapView: React.FC<BlockTreeMapViewProps> = ({treeData}) => {
  const theme = useTheme();
  if (!treeData) {
    return null;
  }

  const renderTreeAsTable = (node: TreeNode, level = 0, isLastChild = false, parentHasSiblings = false, index = 0): React.ReactNode => {
    let connectorChar = ""; // Default connector character

    if (level > 0) {
      connectorChar = "─";
      if (index === 0 && parentHasSiblings) {
        connectorChar = "┬";
      } else if (index === 0 && !parentHasSiblings) {
        connectorChar = "─";
      } else if (isLastChild) {
        connectorChar = "└";
      } else {
        connectorChar = "├";
      }
    }

    return (
      <div className="horizontal-tree">
        <div className="branch">
          <div className="connector"
            style={{color: theme.palette.text.secondary}}>
            {connectorChar}
          </div>
          <div className="node">
            <BlockRun key={node.runId} blockId={node.blockId}
              runId={node.runId}
            />
          </div>
        </div>
        <div>
          {node.children.map((child, index) => (
            <React.Fragment key={index}>
              {renderTreeAsTable(
                child,
                level + 1,
                index === node.children.length - 1,
                node.children.length > 1,
                index
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div>
      {treeData && renderTreeAsTable(treeData)}
    </div>
  );
};

export default BlockTreeMapView;
