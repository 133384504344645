import React, {useEffect, useState} from 'react';
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc
} from "firebase/firestore";
import {
  Avatar,
  Box,
  Divider,
  FormControl,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  useTheme
} from '@mui/material';
import {
  BlockUserRelationType,
  SchemaBlockUser,
  TABLE_BLOCK_USERS
} from "tobl-data-schema/dist/schema/block/user/blockUser";
import {COLLECTION_BLOCKS} from "../../models/block/block";
import {SchemaUser, TABLE_USERS} from "tobl-data-schema/dist/schema/user";
import {db} from "../../firebase/firebase";
import {useGlobalContext} from "../../hooks/GlobalContext";
import BlockMenuInviteButton
  from '../../components/block/menu/BlockMenuInviteButton';

interface BlockUsersManageViewProps {
  parentBlockId: string;
}

interface UserWithRelation extends SchemaUser {
  relationType?: string; // Extending the SchemaUser with an optional relationType field
}

const relationTypeOrder: { [key: string]: number } = {
  owner: 1,
  editor: 2,
  viewer: 3,
  admin: 4,
};

function sortUsersByRelationType(a: UserWithRelation, b: UserWithRelation) {
  const orderA = relationTypeOrder[a.relationType ?? ''] || 4; // assign a high number for undefined relation types
  const orderB = relationTypeOrder[b.relationType ?? ''] || 4;
  return orderA - orderB;
}

const ManageBlockUsersView: React.FC<BlockUsersManageViewProps> = ({parentBlockId}) => {
  const theme = useTheme();
  const isAdmin = useGlobalContext().isAdmin;
  const currentUserId = useGlobalContext().userInfo?.id;
  const [blockUsers, setBlockUsers] = useState<SchemaBlockUser[]>([]);
  const [usersDetails, setUsersDetails] = useState<UserWithRelation[]>([]);

  const handleRelationChange = async (userId: string, newRelationType: BlockUserRelationType | 'Remove') => {
    try {
      const blockUser = blockUsers.find((user) => user.userId === userId);
      if (!blockUser) return;

      const blockUserRef = doc(COLLECTION_BLOCKS, parentBlockId, TABLE_BLOCK_USERS, blockUser.userId);

      if (newRelationType === "Remove") {
        await deleteDoc(blockUserRef);
        // Also, update the local state to reflect the changes
        setUsersDetails((prevUsers) => prevUsers.filter((user) => user.id !== userId));
        setBlockUsers((prevBlockUsers) => prevBlockUsers.filter((user) => user.userId !== userId));
      } else {
        await updateDoc(blockUserRef, {
          relationType: newRelationType,
        });

        // Here, instead of mapping the users, we're going to create a new array of users, sort them, and then set the state with the sorted array.
        let updatedUsers = usersDetails.map((user) =>
          user.id === userId ? {
            ...user,
            relationType: newRelationType
          } : user
        );

        // Now, sort the users based on the new relation types
        updatedUsers = updatedUsers.sort(sortUsersByRelationType);

        // Finally, set the state with the newly sorted users
        setUsersDetails(updatedUsers);
      }
    } catch (error) {
      console.error("Error updating relation type: ", error);
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const q = query(collection(doc(COLLECTION_BLOCKS, parentBlockId), TABLE_BLOCK_USERS));
        const snapshot = await getDocs(q);

        if (snapshot) {
          const usersWithRelations: UserWithRelation[] = [];
          for (const document of snapshot.docs) {
            const blockUser = document.data() as SchemaBlockUser;
            const userRef = doc(db, TABLE_USERS, blockUser.userId);
            const userSnapshot = await getDoc(userRef);

            if (userSnapshot.exists()) {
              const user = userSnapshot.data() as SchemaUser;
              usersWithRelations.push({
                ...user,
                relationType: blockUser.relationType
              });
            }
          }

          // Sort users by their relation type
          usersWithRelations.sort(sortUsersByRelationType);

          setBlockUsers(snapshot.docs.map(doc => doc.data() as SchemaBlockUser));
          setUsersDetails(usersWithRelations);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, [parentBlockId]);

  const isCurrentUserOwner = (blockUsers.some(
    (blockUser) =>
      blockUser.userId === currentUserId &&
      blockUser.relationType === BlockUserRelationType.owner
  ));

  const isCurrentUserAdmin = (blockUsers.some(
    (blockUser) =>
      blockUser.userId === currentUserId &&
      blockUser.relationType === BlockUserRelationType.admin
  )) || isAdmin;

  return (
    <>
      <BlockMenuInviteButton blockId={parentBlockId}/>
      <List sx={{padding: 0, margin: 0}}>
        {usersDetails.map((user, index) => (
          <Box key={index}>
            {((isCurrentUserAdmin || user.relationType !== BlockUserRelationType.admin) &&
                <ListItem key={index} alignItems="flex-start"
                  sx={{opacity: user.relationType === BlockUserRelationType.admin ? 0.5 : 1}}>
                  <Grid container justifyContent="space-between"
                    alignItems="center">
                    <Grid item sx={{display: 'flex'}}>
                      <ListItemAvatar>
                        <Avatar alt={user.displayName}
                          src={user.photoURL}/>
                      </ListItemAvatar>
                      <ListItemText
                        primary={user.displayName}
                        primaryTypographyProps={{variant: 'subtitle2'}}
                        secondary={user.email}
                        secondaryTypographyProps={{variant: 'body2'}}
                      />
                    </Grid>
                    <Grid item>
                      {(isCurrentUserOwner || isCurrentUserAdmin) && (
                        <FormControl>
                          <Select
                            value={user.relationType ?? ''}
                            onChange={(event) =>
                              handleRelationChange(
                                user.id,
                                    event.target.value as BlockUserRelationType | 'Remove'
                              )
                            }
                            displayEmpty
                            inputProps={{'aria-label': 'Without label'}}
                            sx={{fontSize: '0.75rem'}}
                          >
                            <MenuItem value={BlockUserRelationType.owner}
                              sx={{paddingRight: '30px'}}>Owner</MenuItem>
                            <MenuItem value={BlockUserRelationType.editor}
                              sx={{paddingRight: '30px'}}>Editor</MenuItem>
                            <MenuItem value={BlockUserRelationType.viewer}
                              sx={{paddingRight: '30px'}}>Viewer</MenuItem>
                            <Divider/>
                            {isCurrentUserAdmin && (
                              <MenuItem value={BlockUserRelationType.admin}
                                sx={{paddingRight: '30px'}}>Admin</MenuItem>)}
                            <MenuItem value={'Remove'}
                              sx={{
                                color: theme.palette.error.main,
                                paddingRight: '30px'
                              }}>Remove</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    </Grid>
                  </Grid>
                </ListItem>)}
          </Box>
        ))}
      </List>
    </>
  );
};

export default ManageBlockUsersView;
