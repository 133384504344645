import React, {useState} from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  List,
  ListItemIcon,
  ListItemText,
  MenuItem,
  useMediaQuery,
  useTheme
} from '@mui/material';
import BlockMenuMoveButtonItem from "./BlockMenuMoveButtonItem";
import {useBlockTree} from "../../../hooks/useBlockTree";
import {updateBlockParent} from "../../../firebase/updateBlockParent";
import {BlockType, SchemaBlock} from "tobl-data-schema/dist/schema/block/block";
import {useTranslation} from "react-i18next";
import {TypeBlockTree} from "tobl-data-schema/dist/api/block/block/getBlockTree";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  BLOCK_TYPE_TO_INPUT_OUTPUT_TYPE
} from "tobl-data-schema/dist/schema/block/block_type/blockTypeToInputOutputType";

interface BlockMenuMoveButtonProps {
  block: SchemaBlock;
  parentBlockId: string;
}

const BlockMenuMoveButton: React.FC<BlockMenuMoveButtonProps> = (
  {
    block,
    parentBlockId,
  }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const {blockTree, refreshBlockTree} = useBlockTree();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const {t} = useTranslation();
  const blockInputType = block.blockType === BlockType.input
    ? BLOCK_TYPE_TO_INPUT_OUTPUT_TYPE[block.configuration.blockType_Input].inputType
    : block.blockType === BlockType.processor
      ? BLOCK_TYPE_TO_INPUT_OUTPUT_TYPE[block.configuration.blockType_Processor].inputType
      : undefined;

  const renderBlockTree = (blockTree: TypeBlockTree, level = 0, filterBlockId: string) => {
    return Object.keys(blockTree).map((blockId) => {
      if (blockId === filterBlockId) {
        return null;
      }
      const subtree = blockTree[blockId];
      return (
        <React.Fragment key={blockId}>
          <BlockMenuMoveButtonItem
            level={level}
            blockId={blockId}
            movingBlockInputType={blockInputType}
            onClick={() => handleMoveClick(parentBlockId, blockId)}
          />
          {subtree && renderBlockTree(subtree, level + 1, filterBlockId)}
        </React.Fragment>
      );
    });
  };

  const handleOpen = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMoveClick = async (prevParentBlockId: string, newParentBlockId: string) => {
    await updateBlockParent(
      block.id,
      prevParentBlockId,
      newParentBlockId
    );

    handleClose();
    refreshBlockTree();
  };

  if (!blockTree) {
    return <LinearProgress/>;
  }

  return (
    <Box>
      <MenuItem color="primary" onClick={handleOpen}>
        <ListItemIcon>
          <ArrowForwardIcon color={'primary'}/>
        </ListItemIcon>
        <ListItemText>
          {t('Move Block')}
        </ListItemText>
      </MenuItem>
      {open && (
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth
          maxWidth={'sm'}
          fullScreen={fullScreen}
        >
          <DialogTitle>{t('Move Block Under:')}</DialogTitle>
          <DialogContent>
            <List>
              {renderBlockTree(blockTree, 1, block.id)}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              {t('cancel')}
            </Button>
          </DialogActions>
        </Dialog>)}
    </Box>
  );
};

export default BlockMenuMoveButton;
