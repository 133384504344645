import React from 'react';
import {Box, CircularProgress} from '@mui/material';
import {useGetBlockDocument} from "../../../hooks/getUseBlockDocument";
import {
  BlockDocumentType
} from "tobl-data-schema/dist/schema/block/document/document";
import {useGetBlock} from "../../../hooks/useGetBlock";
import {BlockType} from "tobl-data-schema/dist/schema/block/block";
import {
  BlockType_Processor
} from "tobl-data-schema/dist/schema/block/block_type/processor";

interface PreviewTableProps {
  blockId: string;
  documentIds: string[];
}

const PreviewTable: React.FC<PreviewTableProps> = ({blockId, documentIds}) => {

  const {block} = useGetBlock(blockId);

  const {document} = useGetBlockDocument(blockId, documentIds[0]);

  if (document?.blockDocumentType !== BlockDocumentType.table_document) {
    return <CircularProgress/>;
  }

  if (block?.blockType !== BlockType.processor || block.configuration.blockType_Processor !== BlockType_Processor.table_summarizer) {
    return <CircularProgress/>;
  }

  console.log(document.data.table.split('\n'));

  return (
    <Box>
      {block && <h1>{block.title}</h1>}
      {
        block && block.configuration.groupByColumns && (
          <div>
            <h3>Group By Columns</h3>
            {block.configuration.groupByColumns.join(', ')}
          </div>
        )
      }
      {
        block && block.configuration.aggregateColumns && (
          <div>
            <h3>Aggregate Columns</h3>
            <table>
              <thead>
              <tr>
                <th>Column</th>
                <th>Aggregate</th>
              </tr>
              </thead>
              <tbody>
              {Object.entries(block.configuration.aggregateColumns).map(([column, aggregate], index) => (
                <tr key={index}>
                  <td>{column}</td>
                  <td>{aggregate}</td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        )
      }
      {document && (
        <div>
          <h3>Results</h3>

          <table>
            <tbody>
            {(JSON.parse(document.data.table) as string[][]).map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex}>{cell}</td>
                ))}
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      )}
    </Box>
  );
};

export default PreviewTable;
