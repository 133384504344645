// src/components/VoiceRecorder.tsx
import React, {useEffect, useState} from 'react';
import {useAudioRecorder} from "react-audio-voice-recorder";
import {v4 as uuidv4} from "uuid";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable
} from "firebase/storage";
import MicIcon from '@mui/icons-material/Mic';
import DoneIcon from '@mui/icons-material/Done';
import StopIcon from '@mui/icons-material/Stop';
import {Box, IconButton} from '@mui/material';
import {LiveAudioVisualizer} from 'react-audio-visualize';
import CloseIcon from "@mui/icons-material/Close";

export function VoiceRecorder({setIsLoading, groupId, setAudioUrl}: {
  setIsLoading: (isLoading: boolean) => void;
  groupId: string,
  setAudioUrl: (audioUrl: {
    fileUrl: string,
    storagePath: string,
    title: string
  }) => void
}) {
  const {
    startRecording,
    stopRecording,
    recordingBlob,
    isRecording,
    mediaRecorder
  } = useAudioRecorder();

  const [isCanceled, setIsCanceled] = useState(false);

  useEffect(() => {
    if (isCanceled) {
      setIsLoading(false);
      setIsCanceled(false);
      return;
    }
    if (!recordingBlob) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    // Convert the Blob to a File
    const file = new File([recordingBlob], `${uuidv4()}.mp3`, {type: recordingBlob.type});

    // Get a reference to the storage service
    const storage = getStorage();

    // Create a storage reference
    const storagePath = `${groupId}/app/audio-${file.name}`;
    const storageRef = ref(storage, storagePath);

    // Upload the file to Firebase Storage
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Handle the upload progress
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
      },
      (error) => {
        // Handle unsuccessful uploads
        setIsLoading(false);
        console.error("Failed to upload audio file:", error);
      },
      async () => {
        // Handle successful uploads on complete
        console.log("Audio file uploaded successfully");

        // Get the public URL of the uploaded file
        const publicUrl = await getDownloadURL(storageRef);
        setAudioUrl({
          fileUrl: publicUrl,
          storagePath,
          title: file.name
        });
        console.log("Public URL of the uploaded file:", publicUrl);
      }
    );

  }, [recordingBlob])

  const handleDoneClick = () => {
    stopRecording();
  };

  const handleStopClick = () => {
    setIsCanceled(true);
    stopRecording();
  };

  const handleMicClick = () => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  return (
    <Box>
      {(isRecording && mediaRecorder)
        ? (
          <Box sx={{display: 'flex', alignItems: 'center', gap: 2, height: '30px', padding: '20px'}}>
            <IconButton onClick={handleStopClick}>
              <CloseIcon/>
            </IconButton>
            <LiveAudioVisualizer mediaRecorder={mediaRecorder}/>
            <IconButton onClick={handleDoneClick}>
              <DoneIcon/>
            </IconButton>
          </Box>
        )
        : <IconButton onClick={handleMicClick}>
          <MicIcon fontSize={'large'}/>
        </IconButton>}
    </Box>
  );
}
