import React, {useState} from 'react';
import {Box, Button, Input} from "@mui/material";
import {addRun} from "../../../../../cloud_functions/addRun";
import {v4 as uuidv4} from "uuid";
import {useSnackbar} from "../../../../../hooks/useSnackbar";

export default function YoutubeLinkUploader(
  {
    handleClose,
    blockId,
  }
    :
    {
      handleClose: () => void;
      blockId: string;
    }
) {
  const [link, setLink] = useState('https://www.youtube.com/watch?v=');
  const [title, setTitle] = useState(''); // Add this line

  const [isEdited, setIsEdited] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Add this line

  const {showSnackbar} = useSnackbar();

  const handleEnter = async () => {
    const isValid = validateYoutubeLink();
    if (isValid) {
      setIsLoading(true); // Add this line
      const traceId = uuidv4();
      showSnackbar('Uploading YouTube link...');
      await addRun({
                     blockId,
                     traceId,
                     clientInputData: {
                       url: link,
                       title: title
                     }
                   });
      setIsLoading(false); // Add this line
      showSnackbar('YouTube link uploaded');
      handleClose();
    }
  };

  const handleKeyPress = async (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      await handleEnter();
    }
  };

  const validateYoutubeLink = () => {
    const youtubeLinkPattern = /^https:\/\/www\.youtube\.com\/watch\?v=/;
    if (!youtubeLinkPattern.test(link)) {
      window.alert('Invalid YouTube link');
      return false;
    }
    return true;
  };

  const handleCancel = () => {
    if (!isEdited || window.confirm('Do you want to discard the input?')) {
      setLink('');
      handleClose();
    }
  };

  return (
    <div>
      <Input
        placeholder="Title"
        fullWidth
        value={title}
        onChange={(e) => {
          setTitle(e.target.value);
          setIsEdited(true);
        }}
        onKeyDown={handleKeyPress}
        sx={{minWidth: '400px', mt: 2}}
      />
      <Input
        placeholder="Youtube Link"
        fullWidth
        value={link}
        onChange={(e) => {
          setLink(e.target.value);
          setIsEdited(true);
        }}
        onKeyDown={handleKeyPress}
        sx={{minWidth: '400px', mt: 2}}
      />
      <Box display="flex" justifyContent="flex-end">
        <Button onClick={handleCancel}
                disabled={isLoading}>Cancel</Button> {/* Add disabled prop */}
        <Button onClick={handleEnter}
                disabled={isLoading}>Enter</Button> {/* Add disabled prop */}
      </Box>
    </div>
  );
}
