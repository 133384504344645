import React, {useEffect, useMemo} from 'react';
import ReactFlow, {
  Edge,
  MarkerType,
  Node,
  Position,
  useEdgesState,
  useNodesState
} from 'reactflow';
import 'reactflow/dist/style.css';
import {TreeNode} from "./BlockTreeMapView";
import {useBlockTree} from "../../../../../hooks/useBlockTree";
import {
  TypeBlockLookup
} from "tobl-data-schema/dist/api/block/block/getBlockTree";
import CustomNodeComponent from "./CustomNodeComponent";

interface FlowNodeData {
  label: string;
  blockId: string;
  runId: string;
  key: string;
}

const HORIZONTAL_SPACING = 80; // Horizontal space between each level
const VERTICAL_SPACING = 60;   // Vertical space between sibling nodes

const convertToFlowNodes = (
  node: TreeNode,
  blockLookup: TypeBlockLookup,
  parentNodeId: string | null = null,
  depth = 0,
  yOffset = 0
): { nodes: Node<FlowNodeData>[], edges: Edge[], subtreeHeight: number } => {
  const nodes: Node<FlowNodeData>[] = [];
  const edges: Edge[] = [];

  const nodeId = `node-${node.runId}`;
  const xPosition = depth * HORIZONTAL_SPACING;
  const yPosition = yOffset;

  if (blockLookup[node.blockId]) {
    const nodeData: Node<FlowNodeData> = {
      id: nodeId,
      type: 'customNode',
      data: {
        label: `${blockLookup[node.blockId].title}`,
        blockId: node.blockId,
        runId: node.runId,
        key: node.blockId
      },
      position: {x: xPosition, y: yPosition},
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
      resizing: false,
    };
    nodes.push(nodeData);
  }

  if (parentNodeId) {
    const edge: Edge = {
      id: `e${parentNodeId}-${nodeId}`,
      source: parentNodeId,
      target: nodeId,
      markerEnd: MarkerType.Arrow
    };
    edges.push(edge);
  }

  let accumulatedHeight = 0;
  node.children.forEach((child, index) => {
    const childYOffset = yOffset + accumulatedHeight;
    const {
      nodes: childNodes,
      edges: childEdges,
      subtreeHeight: childSubtreeHeight
    } = convertToFlowNodes(child, blockLookup, nodeId, depth + 1, childYOffset);

    nodes.push(...childNodes);
    edges.push(...childEdges);

    // Update accumulated height based on child's subtree height
    accumulatedHeight += childSubtreeHeight;
  });

  // The height of the current node's subtree should be its own height plus the accumulated height of its children
  const subtreeHeight = Math.max(VERTICAL_SPACING, accumulatedHeight);

  return {nodes, edges, subtreeHeight};
};

interface BlockTreeMapFlowProps {
  treeData: TreeNode;
}

const BlockTreeMapFlow: React.FC<BlockTreeMapFlowProps> = ({treeData}) => {
  const {blockLookup} = useBlockTree();


  const [flowNodes, setNodes] = useNodesState([]);
  const [flowEdges, setEdges] = useEdgesState([]);

  useEffect(
    () => {
      if (!treeData || !blockLookup) return;

      const {nodes, edges} = convertToFlowNodes(treeData, blockLookup);
      setNodes(nodes);
      setEdges(edges);
    },
    [treeData, blockLookup] // include updateTrigger here
  );


  const nodeTypes = useMemo(() => ({customNode: CustomNodeComponent}), []);

  return (
    <div style={{width: '100%', aspectRatio: 2, maxWidth: '500px'}}>
      <ReactFlow
        nodes={flowNodes}
        edges={flowEdges}
        nodeTypes={nodeTypes}
        fitView
      />
    </div>
  );
};

export default BlockTreeMapFlow;
