import React, {useEffect, useState} from 'react';
import {doc, serverTimestamp, updateDoc} from 'firebase/firestore';
import {db} from '../../../firebase/firebase';
import Box from '@mui/material/Box';
import {ListItemIcon, ListItemText, MenuItem} from "@mui/material";
import {addBlock} from "../../../cloud_functions/addBlock";
import {useGlobalContext} from "../../../hooks/GlobalContext";
import {useBlockTree} from "../../../hooks/useBlockTree";
import {
  BlockActiveStatus,
  BlockType,
  SchemaBlock,
  SchemaBlockEdit,
  TABLE_BLOCKS
} from "tobl-data-schema/dist/schema/block/block";
import {useTranslation} from "react-i18next";
import {
  BlockType_Project
} from "tobl-data-schema/dist/schema/block/block_type/project";
import {
  AddEditMode,
  BlockMenuAddOrEditDialog
} from './BlockMenuAddOrEditDialog';
import ContactView from '../../../views/app/ContactView';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import WidgetsIcon from '@mui/icons-material/Widgets';
import {ManageButton} from "../../../views/block/ManageBlockSettingView";
import {
  BlockDocumentType
} from "tobl-data-schema/dist/schema/block/document/document";
import {useStripeSubscription} from "../../../hooks/useStripeSubscription";
import {TeamSubscriptionDetail} from "../team/TeamSubscriptionDetail";
import {useGetBlock} from "../../../hooks/useGetBlock";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import {BlockEditButtonContext} from "./BlockEditButtonContext";

interface Props {
  addEditMode: AddEditMode;
  blockType?: BlockType;
  existingData?: SchemaBlock;
  parentOutputType?: BlockDocumentType;
  blockId: string;
  children?: React.ReactNode;
  isTeamBlock?: boolean;
  buttonContext: BlockEditButtonContext;
}

/**
 * `BlockMenuAddOrEditButton` is a React component that provides a button for adding or editing a block.
 * The behavior of the button is determined by the props passed to it.
 *
 * Props:
 * - `addEditMode`: Determines whether the button is used to add a new block or edit an existing one.
 * - `blockType`: The type of the block to be added or edited.
 * - `existingData`: The existing data of the block to be edited.
 * - `parentOutputType`: The output type of the parent block.
 * - `blockId`: The id of the block to be added or edited.
 * - `children`: The child components to be rendered inside this component.
 * - `isTeamBlock`: A boolean indicating whether the block is a team block.
 * - `buttonContext`: The context in which the button is used. It can be 'title_change' or 'api_key_change'.
 */
const BlockPartialEditor: React.FC<Props> = (
  {
    addEditMode,
    blockType,
    existingData,
    parentOutputType,
    blockId,
    children,
    isTeamBlock,
    buttonContext,
  }) => {
  const {user, projectBlocks, isAdmin} = useGlobalContext();
  const [open, setOpen] = useState(false);
  const {t} = useTranslation();
  const {refreshBlockTree, setBlockTreeLoading} = useBlockTree();
  const [isContactOpen, setIsContactOpen] = useState(false);
  const subscriptionStatus = useStripeSubscription(blockId);
  const [isSubscriptionDialogOpen, setIsSubscriptionDialogOpen] = useState(false);
  const block = useGetBlock(blockId).block;

  const handleDialogClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsSubscriptionDialogOpen(false);
  };

  const initialBlockData: SchemaBlockEdit = {
    parentBlockIds: [blockId],
    activeStatus: BlockActiveStatus.active,
    title: existingData?.title || '',
    blockType: existingData?.blockType || blockType || BlockType.project,
    configuration: existingData?.configuration || {blockType_Project: BlockType_Project.documents},
    isResultBlock: existingData?.isResultBlock || false,
    ...existingData,
  };

  const [blockData, setBlockData] = useState<SchemaBlockEdit>(initialBlockData);

  useEffect(() => {
    setBlockData(prevState => ({
      ...prevState,
      blockType: existingData?.blockType || blockType || BlockType.project, // Changed to project as it seems more relevant based on context
    }));
  }, [blockType, existingData]);

  const hasProjectBlock = isTeamBlock && (projectBlocks?.[blockId]?.length !== 0);

  const handleClickOpen = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (user) {
      if (!isAdmin && !subscriptionStatus && hasProjectBlock) {
        setIsSubscriptionDialogOpen(true);
        return;
      }

      setOpen(true);
    } else {

      setIsContactOpen(true);
    }
  };

  const handleClose = (e: React.MouseEvent) => {
    setOpen(false);
    e.stopPropagation();
  };

  const handleAddOrEditBlock = async (e: React.MouseEvent) => {
    if (!user) {
      return;
    }

    setBlockTreeLoading(true);

    const newBlockData = {
      ...blockData,
      updatedAt: serverTimestamp(),
    };

    if (addEditMode === AddEditMode.ADD) {
      await addBlock({
                       block: {
                         ...newBlockData,
                         createdAt: serverTimestamp(),
                       },
                     });
    } else if (addEditMode === AddEditMode.EDIT && existingData?.id) {
      const blockRef = doc(db, TABLE_BLOCKS, existingData.id);
      await updateDoc(blockRef, newBlockData);
    }

    refreshBlockTree();

    handleClose(e);
  };

  return (
    <Box>
      {(children
          ? <Box component="span" onClick={handleClickOpen}>
            {children}
          </Box>
          : isTeamBlock
            ? <ManageButton
              icon={buttonContext === "api_key_change" ? <VpnKeyIcon/> :
                <WidgetsIcon/>}
              title={buttonContext === "api_key_change" ? t("api_key") : t("Team name")}
              description={buttonContext === "api_key_change" ? t("You can manage the API key for using AI.") : blockData.title}
              buttonTitle={buttonContext === "api_key_change" ? t("Manage API keys") : t("Change name")}
              onClick={handleClickOpen}
            />
            : <MenuItem onClick={handleClickOpen}>
              <ListItemIcon>
                {addEditMode === AddEditMode.ADD ?
                  <AddIcon color={'primary'}/> : <EditIcon color={'primary'}/>}
              </ListItemIcon>
              <ListItemText>
                {addEditMode === AddEditMode.ADD ? t("Add a Child Block") : t("Edit Block")}
              </ListItemText>
            </MenuItem>
      )}
      <BlockMenuAddOrEditDialog
        open={open}
        onClose={handleClose}
        onSave={handleAddOrEditBlock}
        blockData={blockData}
        parentOutputType={parentOutputType}
        setBlockData={setBlockData}
        addEditMode={addEditMode}
        isTeamBlock={isTeamBlock}
        buttonContext={buttonContext}
        blockId={blockId}
      />
      <ContactView
        isOpen={isContactOpen}
        onClose={() => {
          setIsContactOpen(false);
        }}
      />
      {block && <TeamSubscriptionDetail
          teamBlock={block}
          isDialogOpen={isSubscriptionDialogOpen}
          handleDialogClose={handleDialogClose}
      />}
    </Box>
  );
};

export default BlockPartialEditor;
