import React from 'react';
import {Box, useMediaQuery, useTheme} from '@mui/material';
import EntryBarrierToAI from "../../assets/images/entryBarrierToAI.png";
import OvercomingDifficulties from "../../assets/images/overcomingDifficulties.png";
import GetStartedNow from "../../assets/images/getStartedNow.png";
import {useTranslation} from "react-i18next";
import {useInView} from "react-intersection-observer";
import {HomeIntroduceItem} from "./HomeIntroduceItem";

export type SectionsItem = {
  title: string;
  description: string;
  imageUrl?: string;
};

const sections: SectionsItem[] = [
  // {
  //   title: "introduction.title",
  //   description: "introduction.description",
  //   imageUrl: Introduction,
  // },
  {
    title: "entryBarrierToAI.title",
    description: "entryBarrierToAI.description",
    imageUrl: EntryBarrierToAI,
  },
  {
    title: "overcomingDifficulties.title",
    description: "overcomingDifficulties.description",
    imageUrl: OvercomingDifficulties,
  },
  {
    title: "getStartedNow.title",
    description: "getStartedNow.description",
    imageUrl: GetStartedNow,
  }
];

const HomeIntroduce: React.FC = () => {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const mediumScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const {t} = useTranslation();

  const {ref, inView} = useInView({
    triggerOnce: true,
    rootMargin: '-100px 0px',
  });

  return (
    <>
      <Box padding={'0px 20px'}>
        {sections.map((item, index) => <HomeIntroduceItem key={item.title} item={item} index={index}
          largeScreen={largeScreen}/>)}
      </Box>
    </>
  );
};

export default HomeIntroduce;
