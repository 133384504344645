import React from 'react';
import {useTranslation} from 'react-i18next';
import {ListItemText, MenuItem, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useSnackbar} from "../../hooks/useSnackbar";
import {useGlobalContext} from '../../hooks/GlobalContext';

export default function SignOutButton() {
  const init = useGlobalContext();
  const {showSnackbar} = useSnackbar();
  const navigate = useNavigate();
  const {t} = useTranslation();

  function signOut() {
    navigate('/');
    init.handleLogout();
    showSnackbar('You are signed out', 'success');
  }

  return (
    <MenuItem onClick={signOut}>
      <ListItemText
        primary={
          <Typography variant="body1" color='error'>
            {t('sign_out')}
          </Typography>
        }
      />
    </MenuItem>
  );
}
