import React, {useMemo} from 'react';
import {Box, Grid, Typography, useMediaQuery, useTheme} from '@mui/material';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import BlockTreeMap from "./block_tree_map/BlockTreeMap";
import {SchemaTrace} from "tobl-data-schema/dist/schema/trace";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {useTranslation} from 'react-i18next';
import {DocumentsViewDetailTitle} from './DocumentsViewDetailTitle';
import {DocumentItem} from "./DocumentItem";

interface DocumentsViewDetailDialogProps {
  trace: SchemaTrace
  onClose: () => void;
  isOpen: boolean;
}

export function DocumentsViewDetailDialog(
  {
    trace,
    onClose,
    isOpen
  }: DocumentsViewDetailDialogProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const {t} = useTranslation();

  const resultBlocks = useMemo(() => {
    if (!trace.blocks) {
      return [];
    }
    return Object.entries(trace.blocks)
      .filter(([blockId, block]) => block.isResultBlock)
      .sort(([blockIdA], [blockIdB]) => blockIdA.localeCompare(blockIdB))
      .map(([blockId, block]) => ({blockId, blockDetails: block}));
  }, [trace.blocks]); // Dependencies are trace.blocks

  return (
    <Dialog
      key={trace.id}
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth={'md'}
      fullScreen={fullScreen}
    >
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
          padding: '15px 10px'
        }}
      >
        <IconButton
          color="primary"
          aria-label="close"
          onClick={onClose}
          style={{
            color: theme.palette.text.primary,
            width: '40px',
            height: '40px',
          }}
        >
          <CloseIcon fontSize={'medium'}/>
        </IconButton>
      </Box>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'center',
            padding: '10px 0 40px'
          }}
        >
          <Typography variant="h4" margin={'0px 20px 15px'}>
            <DocumentsViewDetailTitle trace={trace}/>
          </Typography>
          <Typography
            variant="subtitle1"
            mb={2}
            color={theme.palette.text.secondary}
            sx={{width: '100%'}}
          >
            {trace.createdAt.toDate().toLocaleString()}
          </Typography>
          <Box
            mt={6}
            mb={5}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center'
            }}
          >
            <Typography
              variant="h6"
              mb={0}
              sx={{
                width: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                // color: theme.palette.primary.main
              }}
            >
              {t('tree_of_blocks')}
            </Typography>
            <BlockTreeMap trace={trace}/>
          </Box>
          {resultBlocks && <Grid container spacing={2}>
            {resultBlocks.map(({blockId, blockDetails}, index) => (
              <Grid
                key={blockId}
                item
                xs={12}
                sm={6}
                md={4}
                margin={resultBlocks.length < 3 ? 'auto' : 0}
              >
                <DocumentItem key={blockDetails.runId}
                              traceBlockDetails={blockDetails}
                              blockId={blockId}/>
              </Grid>
            ))}
          </Grid>}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
