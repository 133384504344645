import React, {useEffect, useState} from 'react';
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  useTheme
} from '@mui/material';
import IconButton from "@mui/material/IconButton";
import {useGlobalContext} from "../../hooks/GlobalContext";
import {doc, setDoc} from "firebase/firestore";
import {COLLECTION_USERS} from "../../models/user";
import i18n from "i18next";

export function TopBarLanguageButton() {
  const theme = useTheme();
  const init = useGlobalContext();
  const user = init.user;
  const userInfo = init.userInfo;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  const handleLanguageChange = async (lang: string) => {
    i18n.changeLanguage(lang);
    if (user) {
      await setDoc(doc(COLLECTION_USERS, user.uid), {
        ...userInfo,
        language: lang
      });
    }
    handleClose();
  };

  const [currentIcon, setCurrentIcon] = useState('🇺🇸');

  const languages = [
    {code: 'en', label: 'English', icon: '🇺🇸'},
    {code: 'ko', label: '한국어', icon: '🇰🇷'},
  ];

  useEffect(() => {
    const langCode = i18n.language.split('-')[0];
    const langIcon = languages.find(lang => lang.code === langCode)?.icon;
    if (langIcon) {
      setCurrentIcon(langIcon);
    } else {
      console.error("No matching icon found for the current language.");
    }
  }, [i18n.language]);

  return (
    <div>
      <IconButton
        onClick={handleClick}
        sx={{height: '40px', width: '40px', fontSize: '1.75rem'}}
      >
        {currentIcon}
      </IconButton>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {languages.map(lang => (
          <MenuItem
            key={lang.code}
            onClick={() => handleLanguageChange(lang.code)}
            selected={lang.code === i18n.language}
            sx={{paddingRight: '30px'}}
          >
            <ListItemIcon
              sx={{fontSize: '1.5rem', color: theme.palette.text.primary}}>
              {lang.icon}
            </ListItemIcon>
            <ListItemText>
              {lang.label}
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
