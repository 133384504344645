import React from "react";
import {Box, useMediaQuery, useTheme} from "@mui/material";
import {TopBar} from "./components/top_bar/TopBar";
import {useGlobalContext} from "./hooks/GlobalContext";

interface WithTopBarProps {
  children: React.ReactNode;
}

export function WithTopBar({children}: WithTopBarProps) {
  const {user} = useGlobalContext();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <div style={{color: theme.palette.background.default}}>
      <TopBar isMobile={!fullScreen}/>
      <Box
        component="main"
        style={{
          marginLeft: user && fullScreen ? 300 : 0,
          paddingTop: fullScreen ? 20 : 0,
          overflow: 'auto',
        }}
      >
        {children}
      </Box>
    </div>
  );
}
