import React from "react";
import {Box, Dialog, useMediaQuery, useTheme} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {GRAY} from "../../theme/colors";

// 컴포넌트의 props에 대한 인터페이스를 정의합니다.
interface ContactViewProps {
  isOpen: boolean;
  onClose: () => void;
}

// React.FC 타입을 사용하여 함수 컴포넌트의 타입을 정의합니다.
const ContactView: React.FC<ContactViewProps> = ({isOpen, onClose}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth={'sm'}
      fullScreen={fullScreen}
    >
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
          padding: '15px 10px',
        }}
      >
        <IconButton
          color="primary"
          aria-label="close"
          onClick={onClose}
          style={{
            color: GRAY.w700,
            width: '40px',
            height: '40px',
          }}
        >
          <CloseIcon fontSize={'medium'}/>
        </IconButton>
      </Box>
      <iframe
        src="https://calendly.com/tobl/chat-on-ai"
        style={{width: '100%', height: '1050px', border: 'none', marginTop: '-70px'}}
        title="Embedded Page"
      />
    </Dialog>
  );
}

export default ContactView;
