// BlockTitle.tsx
import React, {useState} from 'react';
import {BlockType} from "tobl-data-schema/dist/schema/block/block";
import {
  BlockDocumentType
} from "tobl-data-schema/dist/schema/block/document/document";
import {useGetBlock} from "../../../hooks/useGetBlock";
import {
  BLOCK_TYPE_TO_INPUT_OUTPUT_TYPE
} from "tobl-data-schema/dist/schema/block/block_type/blockTypeToInputOutputType";
import {Box, Card, Typography, useTheme} from '@mui/material';
import BlockTypeIcon from '../../../views/block/BlockTypeIcon';
import {useTranslation} from 'react-i18next';

interface BlockTitleProps {
  level: number;
  blockId: string;
  movingBlockInputType?: BlockDocumentType;
  onClick: (e: React.MouseEvent) => void;
}

const BlockMenuMoveButtonItem: React.FC<BlockTitleProps> = ({
                                                              level,
                                                              blockId,
                                                              movingBlockInputType,
                                                              onClick
                                                            }) => {
  const theme = useTheme();
  const {t} = useTranslation();
  const {block} = useGetBlock(blockId);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  if (!block) {
    return null;
  }

  const blockOutputType = block.blockType === BlockType.input
    ? BLOCK_TYPE_TO_INPUT_OUTPUT_TYPE[block.configuration.blockType_Input].outputType
    : block.blockType === BlockType.processor
      ? BLOCK_TYPE_TO_INPUT_OUTPUT_TYPE[block.configuration.blockType_Processor].outputType
      : undefined;

  const blockType = block.blockType === BlockType.project
    ? block.configuration.blockType_Project
    : block.blockType === BlockType.input
      ? block.configuration.blockType_Input
      : block.blockType === BlockType.processor
        ? block.configuration.blockType_Processor
        : block.blockType;

  return (
    <Card
      elevation={0}
      onClick={movingBlockInputType === blockOutputType ? onClick : undefined}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      style={{
        width: 250,
        boxShadow: 'none',
        border: '1px solid',
        borderColor: theme.palette.text.primary,
        padding: '10px 15px',
        marginLeft: `${level * 20}px`,
        marginBottom: '10px',
        cursor: movingBlockInputType === blockOutputType ? 'pointer' : 'default',
        opacity: movingBlockInputType === blockOutputType ? 1 : 0.4,
        backgroundColor: isHovered && movingBlockInputType === blockOutputType ? theme.palette.secondary.light : theme.palette.background.default,
      }}
    >
      <Box display="flex" flexDirection="row"
           justifyContent="space-between"
           alignItems="center" fontSize={'26px'}>
        <Box width={"100%"} mr={'15px'}>
          <Typography variant="body2" fontSize={'0.75rem'}>
            {t(blockType)}
          </Typography>
          <Typography variant="subtitle1">
            {block.title}
          </Typography>
        </Box>
        <BlockTypeIcon block={block}/>
      </Box>
    </Card>
  );
};

export default BlockMenuMoveButtonItem;
