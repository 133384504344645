import {useEffect, useState} from "react";
import {collection, doc, getDoc} from "firebase/firestore";
import {parseDoc} from "../firebase/firestore/parseDoc";
import {COLLECTION_BLOCKS} from "../models/block/block";
import {
  SchemaBlockDocument,
  TABLE_DOCUMENTS
} from "tobl-data-schema/dist/schema/block/document/document";

export const useGetBlockDocument = (blockId: string | undefined, documentId: string | undefined) => {
  const [document, setDocument] = useState<SchemaBlockDocument>();
  const [documentLoading, setDocumentLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    if (!blockId || !documentId) {
      return;
    }
    setDocumentLoading(true);
    setError(undefined);

    const documentRef = doc(collection(doc(COLLECTION_BLOCKS, blockId), TABLE_DOCUMENTS), documentId);

    getDoc(documentRef)
      .then(parseDoc<SchemaBlockDocument>)
      .then(setDocument)
      .catch(setError)
      .catch(console.error)
      .finally(() => setDocumentLoading(false));
  }, [blockId, documentId]);

  return {document, documentLoading, error};
};
