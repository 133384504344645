import React, {useState} from 'react';
import {Box, Divider, Fade, IconButton, Menu, useTheme} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import BlockPartialEditor from "./BlockPartialEditor";
import BlockMenuRunButton from "./BlockMenuRunButton";
import BlockMenuInviteButton from "./BlockMenuInviteButton";
import BlockMenuActiveStatusButton from "./BlockMenuActiveStatusButton";
import {
  BlockType,
  SchemaBlock,
  TOP_LEVEL_BLOCK_ID
} from "tobl-data-schema/dist/schema/block/block";
import {useGlobalContext} from "../../../hooks/GlobalContext";
import BlockMenuMoveButton from './BlockMenuMoveButton';
import BlockMenuDeleteButton from './BlockMenuDeleteButton';
import BlockMenuCopyBlockIdButton from './BlockMenuCopyBlockIdButton';
import {AddEditMode} from "./BlockMenuAddOrEditDialog";
import {
  BLOCK_TYPE_TO_INPUT_OUTPUT_TYPE
} from "tobl-data-schema/dist/schema/block/block_type/blockTypeToInputOutputType";
import {BlockEditButtonContext} from "./BlockEditButtonContext";

interface BlockContextMenuProps {
  block: SchemaBlock;
  parentBlockId: string;
  children?: React.ReactNode;
}

const BlockMenuButton: React.FC<BlockContextMenuProps> = (
  {
    block,
    parentBlockId,
    children,
  }) => {
  const theme = useTheme();
  const init = useGlobalContext();
  const isAdmin = init.isAdmin;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  const isChildrenBlock = !block.parentBlockIds.includes(TOP_LEVEL_BLOCK_ID) || isAdmin;

  return (
    <>
      {children ? (
        <Box component="span" onClick={(event) => handleClick(event)}>
          {children}
        </Box>
      ) : (
        <IconButton
          aria-label="more"
          aria-controls="block-context-menu"
          aria-haspopup="true"
          onClick={(event) => handleClick(event)}
          sx={{color: theme.palette.text.primary,}}
        >
          <MoreVertIcon fontSize={'small'}/>
        </IconButton>
      )}
      {anchorEl && (
        <Menu
          id="block-context-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          TransitionComponent={Fade}
          sx={{margin: '0px 10px'}}
        >
          {/*<Box width={200}/>*/}
          <BlockMenuRunButton block={block}/>
          <BlockPartialEditor
            addEditMode={AddEditMode.ADD}
            blockType={
              block.blockType === BlockType.team
                ? BlockType.project
                : block.blockType === BlockType.project
                  ? BlockType.input
                  : BlockType.processor
            }
            parentOutputType={
              block.blockType === BlockType.input
                ? BLOCK_TYPE_TO_INPUT_OUTPUT_TYPE[block.configuration.blockType_Input].outputType
                : block.blockType === BlockType.processor
                  ? BLOCK_TYPE_TO_INPUT_OUTPUT_TYPE[block.configuration.blockType_Processor].outputType
                  : undefined
            }
            blockId={block.id}
            buttonContext={BlockEditButtonContext.TITLE_CHANGE}
          />
          <BlockPartialEditor
            addEditMode={AddEditMode.EDIT}
            existingData={block}
            blockId={block.id}
            buttonContext={BlockEditButtonContext.TITLE_CHANGE}
          />
          {isChildrenBlock && block.blockType === BlockType.processor &&
              <BlockMenuMoveButton block={block} parentBlockId={parentBlockId}/>
          }
          {isChildrenBlock &&
              <BlockMenuActiveStatusButton block={block}/>
          }
          {isChildrenBlock &&
              <BlockMenuDeleteButton block={block}/>
          }
          {isAdmin && <Divider/>}
          {!isChildrenBlock && <BlockMenuInviteButton blockId={block.id}/>}
          {isAdmin && <BlockMenuCopyBlockIdButton block={block}/>}
        </Menu>
      )}
    </>
  );
};

export default BlockMenuButton;
