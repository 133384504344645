import React, {useState} from 'react';
import {Box, Button, CardMedia, Typography} from '@mui/material';
import photo from '../../assets/images/welcome1.png';
import {useGlobalContext} from '../../hooks/GlobalContext';
import {useTranslation} from 'react-i18next';
import HomeTitle from "./HomeTitle";
import ContactView from '../../views/app/ContactView';

const HomeWelcome: React.FC<{}> = () => {
  const {user, userInfo} = useGlobalContext();
  const {t} = useTranslation();
  const [isContactOpen, setIsContactOpen] = useState(false);

  return (
    <Box mb={'60px'}>
      <Box sx={{padding: '0px 40px 40px'}}>
        <CardMedia
          component="img"
          image={photo}
          alt="Placeholder Image"
          sx={{
            width: '100%',
            height: '100%',
            maxWidth: '600px',
            margin: 'auto'
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          p: 2,
          maxWidth: '600px',
          margin: 'auto'
        }}>
        <HomeTitle text={t("welcome")} textAlign={'center'} divider={false}/>
        <HomeTitle text={(userInfo?.displayName || user?.displayName) as string}
                   textAlign={'center'} divider={false}/>
        <Typography variant="body1" component="pre"
                    style={{whiteSpace: 'pre-wrap'}} textAlign={'center'}
                    mt={'20px'}
                    mb={'40px'}>
          {t("intro_action")}
        </Typography>
        <Button
          variant="outlined"
          onClick={() => {
            setIsContactOpen(true);
          }}
        >
          {t("Get Started")}
        </Button>
      </Box>
      <ContactView
        isOpen={isContactOpen}
        onClose={() => {
          setIsContactOpen(false);
        }}
      />
    </Box>
  );
}

export default HomeWelcome;
