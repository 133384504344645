import {useEffect, useState} from 'react';
import {collection, onSnapshot, query, where} from 'firebase/firestore';
import {SchemaSubscription} from "../models/stripe/subscription";
import {getDocsFromSnapshot} from "../firebase/firestore/getDocsFromSnapshot";
import {COLLECTION_STRIPE_TEAM_BLOCKS} from "../models/stripe";

// Initialize Firestore
export const useStripeSubscription = (teamBlockId: string | undefined) => {
  const [subscription, setSubscription] = useState<SchemaSubscription>();

  useEffect(() => {
    if (teamBlockId) {
      const subscriptionRef = query(
        collection(COLLECTION_STRIPE_TEAM_BLOCKS, teamBlockId, 'subscriptions'),
        where('status', 'in', ['trialing', 'active'])
      );

      const unsubscribe = onSnapshot(subscriptionRef, (snapshot) => {
        // Filter out subscriptions that are set to cancel at the end of the period
        const activeSubscriptions = getDocsFromSnapshot<SchemaSubscription>(snapshot)
          .sort((subA, subB) =>
            (subA.cancel_at_period_end ? 1 : -1) - (subB.cancel_at_period_end ? 1 : -1));

        // Choose the first subscription from the filtered list
        if (activeSubscriptions.length > 0) {
          setSubscription(activeSubscriptions[0]);
        } else {
          setSubscription(undefined);  // or set to some default value
        }
      });

      return () => {
        unsubscribe();
      };
    }
  }, [teamBlockId]);

  return subscription;
};
