import React from 'react';
import {Box, Link, Typography, useMediaQuery, useTheme} from '@mui/material';
import {GRAY} from "../../theme/colors";
import Logo from "../top_bar/Logo";

const HomeFooter: React.FC = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.up('lg'));
  return (
    <Box
      width={'100%'}
      bgcolor={GRAY.w900}
      sx={{py: 6}}
      padding={fullScreen ? 5 : 3}
    >
      <Box>
        <Logo/>
        <Typography variant="subtitle1" color={GRAY.w500} mt={5}>
          Will Hohyon Ryu
        </Typography>
        <Typography variant="subtitle1" color={GRAY.w500} mb={5}>
          101 Arroyo ct. APT 1 San Mateo CA 94402 USA
        </Typography>
      </Box>
      <Typography variant="body2" color={GRAY.w500} mb={10}>
        {'Copyright © '}
        <Link color="inherit" href="https://tobl.ai/">
          tobl.ai
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    </Box>
  );
};

export default HomeFooter;
