import React, {ReactNode, useContext, useEffect, useState} from 'react';
import {
  onAuthStateChanged,
  signInWithPopup,
  signOut,
  User
} from "firebase/auth";
import {auth, db, googleProvider} from "../firebase/firebase";
import {doc, getDoc, onSnapshot, query, where} from "firebase/firestore";
import {
  SchemaUser,
  TABLE_USERS,
  UserPrivilege
} from "tobl-data-schema/dist/schema/user";
import {COLLECTION_USER_TOP_BLOCKS} from "../models/user_top_blocks";
import {SchemaUserTopBlock} from "tobl-data-schema/dist/schema/user_top_blocks";
import {BlockType, SchemaBlock} from "tobl-data-schema/dist/schema/block/block";
import {COLLECTION_BLOCKS} from "../models/block/block";
import i18n from "i18next";
import {getDocsFromSnapshot} from "../firebase/firestore/getDocsFromSnapshot";
import {parseDoc} from "../firebase/firestore/parseDoc";
import {
  BlockUserRelationType,
  SchemaBlockUser,
  TABLE_BLOCK_USERS
} from "tobl-data-schema/dist/schema/block/user/blockUser";

interface GlobalContextType {
  user: User | null;
  userInfo: SchemaUser | undefined;
  userTopBlocks: SchemaBlock[] | undefined;
  isAdmin: boolean;
  loading: boolean;
  handleLogin: () => void;
  handleLogout: () => void;
  projectBlocks: { [teamBlockId: string]: SchemaBlock[] } | undefined;
  blockUserRelations: { [blockId: string]: BlockUserRelationType } | undefined;
}

const GlobalContext = React.createContext<GlobalContextType | null>(null);

interface InitProviderProps {
  children: ReactNode;
}

export function GlobalContextProvider({children}: InitProviderProps) {
  const context = useFetchGlobalContext();
  return <GlobalContext.Provider
    value={context}>{children}</GlobalContext.Provider>;
}


function useFetchGlobalContext(): GlobalContextType {
  const [user, setUser] = useState<User | null>(null);
  const [userInfo, setUserInfo] = useState<SchemaUser>();
  const [userTopBlocks, setUserTopBlocks] = useState<SchemaBlock[]>();
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [blockUserRelations, setBlockUserRelations] = useState<{
    [blockId: string]: BlockUserRelationType
  }>();

  const [projectBlocks, setProjectBlocks] = useState<{
    [teamBlockId: string]: SchemaBlock[]
  }>();

  useEffect(() => {
    if (!userTopBlocks) return;

    const unsubscribeProjectBlocks = userTopBlocks.map(topBlock => {
      if (topBlock.blockType !== BlockType.team) {
        return null;
      }

      const q = query(
        COLLECTION_BLOCKS,
        where('parentBlockIds', 'array-contains', topBlock.id)
      );

      return onSnapshot(q, (snapshot) => {
        const projectBlocks = getDocsFromSnapshot<SchemaBlock>(snapshot);
        setProjectBlocks(prevState => {
          return {
            ...prevState,
            [topBlock.id]: projectBlocks
          };
        });
      });
    }).filter(Boolean); // Filter out null values

    return () => {
      unsubscribeProjectBlocks.forEach(unsubscribe => unsubscribe && unsubscribe());
    };
  }, [userTopBlocks]);


  const handleLogin = () => {
    setLoading(true);

    signInWithPopup(auth, googleProvider)
      .catch((error) => {
        console.error(error);
      });
  };


  const handleLogout = () => {
    setLoading(true);
    signOut(auth)
      .then(() => {
        setUser(null);
        window.location.assign('/')
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
      setLoading(true);
      console.time('init');
      if (user) {
        setUser(user);

        // Fetch user info, block details, and block user permissions concurrently
        const [userInfo, topBlocks, blockUserRelations] = await Promise.all([
                                                                              fetchUserData(user),
                                                                              fetchBlockDetails(user),
                                                                              fetchBlockUserPermissions(user)
                                                                            ]);

        setUserInfo(userInfo);
        setUserTopBlocks(topBlocks);
        setBlockUserRelations(blockUserRelations);
        setIsAdmin(!!userInfo?.privileges?.includes(UserPrivilege.admin));
        await i18n.changeLanguage(userInfo?.language || 'en');
        setLoading(false);
      } else {
        setUser(null);
        setUserInfo(undefined);
        setUserTopBlocks(undefined);
        setIsAdmin(false);
        setLoading(false);
      }

      console.timeEnd('init');
    });

    // Clean up the listener on unmount
    return () => {
      unsubscribeAuth();
    };
  }, []);

  async function fetchUserData(user: User) {
    // Fetch user info from Firestore
    const userId = user.uid;
    const userRef = doc(db, TABLE_USERS, userId);
    const userSnapshot = await getDoc(userRef);

    if (userSnapshot.exists()) {
      return parseDoc<SchemaUser>(userSnapshot);
    }
  }

  async function fetchBlockDetails(user: User) {
    // Subscribe to user top blocks updates
    const userTopBlockRef = doc(COLLECTION_USER_TOP_BLOCKS, user.uid);
    const docSnapshot = await getDoc(userTopBlockRef);

    if (docSnapshot.exists()) {
      const topBlockData = parseDoc<SchemaUserTopBlock>(docSnapshot).topBlockIds;
      // Fetch the block details for each block ID
      const topBlocks = await Promise.all(topBlockData.map(async (blockId) => {
        const blockRef = doc(COLLECTION_BLOCKS, blockId);
        const blockSnapshot = await getDoc(blockRef);
        return parseDoc<SchemaBlock>(blockSnapshot);
      }));

      return topBlocks.filter(block => block !== undefined);
    } else {
      return [];
    }
  }

  async function fetchBlockUserPermissions(user: User) {
    const permissions: {
      [blockId: string]: BlockUserRelationType
    } = {};

    if (!userTopBlocks) {
      return permissions;
    }

    for (const block of userTopBlocks) {
      const blockUserRef = doc(COLLECTION_BLOCKS, block.id, TABLE_BLOCK_USERS, user.uid);
      const blockUserSnapshot = await getDoc(blockUserRef);

      if (blockUserSnapshot.exists()) {
        const blockUserData = blockUserSnapshot.data() as SchemaBlockUser;
        if (blockUserData && blockUserData.relationType) {
          permissions[block.id] = blockUserData.relationType;
        }
      }
    }

    return permissions;
  }

  return {
    user,
    userInfo,
    userTopBlocks,
    isAdmin,
    loading,
    handleLogin,
    handleLogout,
    projectBlocks,
    blockUserRelations: blockUserRelations
  };
}

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error('GlobalContext must be used within an InitProvider');
  }
  return context;
}
