import React, {ReactElement, useEffect, useState} from 'react';
import {useTheme} from '@mui/material';
import {SchemaBlockDocument} from "tobl-data-schema/dist/schema/block/document/document";
import {collection, getDocs, limit, orderBy, query, where} from "firebase/firestore";
import {db} from "../../../../firebase/firebase";
import {SchemaTrace} from "tobl-data-schema/dist/schema/trace"; // Adjust the import if necessary

interface DocumentTitleProps {
  trace: SchemaTrace;
}

export function DocumentsViewDetailTitle({trace}: DocumentTitleProps): ReactElement {
  const theme = useTheme();
  const [triggerBlockDocument, setTriggerBlockDocument] = useState<SchemaBlockDocument>()

  let title: string | ReactElement = trace.title;

  useEffect(() => {
    // Function to fetch the latest document
    async function fetchLatestDocument() {
      try {
        const blockDocumentRef = collection(db, "blocks", trace.triggerBlockId, "documents");
        const q = query(blockDocumentRef, where("traceId", "==", trace.id), orderBy("createdAt", "desc"), limit(1));

        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const latestDocument = querySnapshot.docs[0].data();
          setTriggerBlockDocument(latestDocument as SchemaBlockDocument);
        }
      } catch (error) {
        console.error("Failed to fetch latest document:", error);
      }
    }

    fetchLatestDocument();

    // Cleanup (if needed)
    return () => {
    };
  }, [trace.id, trace.triggerBlockId]);

  if (triggerBlockDocument && "fileUrl" in triggerBlockDocument.data) {
    title = (
      <div style={{maxWidth: '100%', wordWrap: 'break-word'}}>
        <a
          href={triggerBlockDocument.data.fileUrl}
          target="_blank"
          rel="noopener noreferrer"
          style={{color: theme.palette.text.primary}}
        >
          {triggerBlockDocument.title}
        </a>
      </div>
    ) || "";
  }

  return (
    <div style={{maxWidth: '100%', wordWrap: 'break-word'}}>
      {title}
    </div>
  );
}
