// src/views/app/VoiceInputAppView.tsx
import React, {useEffect, useState} from 'react';
import {SchemaAppEmailSender} from "../../../models/apps";
import {
  Box,
  Button,
  CircularProgress,
  DialogTitle,
  Grid,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import VoiceInputComponent from "./VoiceInputComponent";
import {useAiProcess} from "./AiProcessingButton";
import {useGlobalContext} from "../../../hooks/GlobalContext";
import {addDoc, doc, getDoc, serverTimestamp, setDoc} from "firebase/firestore";
import {COLLECTION_USER_FORM_DATA} from "../../../models/user_form_data";
import {COLLECTION_MAIL, SchemaMail} from "../../../models/mail";
import {useSnackbar} from "../../../hooks/useSnackbar";
import {
  BlockEditFormRow
} from "../../../components/block/edit/BlockEditFormRow";
import ToblBottomSheet from "../../../components/ToblBottomSheet";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import {removeNullValues} from "../../../utils/removeNullValues";

interface VoiceInputAppViewProps {
  app: SchemaAppEmailSender;
}

const htmlHeader = `<!DOCTYPE html>
<html lang="ko">

<head><meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0"><title>구매발주 의뢰서</title><style>body{font-family:'Noto Sans KR',sans-serif;font-size:12px;}table{width:100%;border-collapse:collapse;}h2{text-align:center;flex-grow:1;width:300px;min-width:300px;}th,td{border:1px solid black;padding:8px;text-align:left;}th{background-color:#f2f2f2;}.approval-table{width:250px;min-width:250px;}.approval-table td{font-size:12px;}.approval-table th{font-size:12px;width:90px;text-align:center;}.paper{width:800px;}@media print{body,html{width:210mm;height:297mm;}.page{margin:0;border:initial;border-radius:initial;width:initial;min-height:initial;box-shadow:initial;background:initial;page-break-after:always;}@page{size:A4;margin:10mm;}}</style></head>

<body>`;


const htmlFooter = `</body></html>`;

/**
 * Represents the view of the Voice Input App.
 *
 * @component
 * @param {Object} app - The app object containing information about the app.
 * @returns {JSX.Element} - The Voice Input App view.
 */
const EmailSenderAppView: React.FC<VoiceInputAppViewProps> = ({app}) => {
  const [form, setForm] =
    useState({
               senderName: '',
               senderEmail: '',
               department: '',
               recipientEmails: '',
               ccEmails: '',
               bccEmails: '',
             });

  const globalContext = useGlobalContext();

  const {showSnackbar} = useSnackbar();

  const userId = globalContext.user?.uid;

  const [emailContent, setEmailContent] = useState<string>();
  const [hasChange, setHasChange] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.up('md'));
  const [open, setOpen] = React.useState(false);
  const [showCC, setShowCC] = React.useState(false);
  const [showName, setShowName] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleShowCC = () => {
    setShowCC(!showCC);
  };

  const handleShowName = () => {
    setShowName(!showName);
  };

  useEffect(() => {
    if (!userId) {
      return;
    }
    const loadFormData = async () => {
      const docRef = doc(COLLECTION_USER_FORM_DATA, userId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setForm(docSnap.data() as any);
      }
    };

    loadFormData();
  }, [userId]);

  const [promptText, setPromptText] = useState<string>();

  const {
    runAiProcess: runSenderReceiptProcessing,
    output: senderReceiptOutput,
    isLoading: isSenderReceiptProcessing,
  } = useAiProcess(app.senderReceiptProcessingBlockId, promptText);
  const {
    runAiProcess: runContentProcessing,
    output: contentOutput,
    isLoading: isContentProcessing,
  } = useAiProcess(
    app.contentProcessingBlockId,
    promptText + `\n\nExisting Data:
${emailContent}\n

오늘 날짜: ${new Date().toLocaleDateString()}
의뢰자: ${form.senderName} (overwrite if necessary)
부서: ${form.department} (overwrite if necessary)
`
  );

  const [isGeneratingEmail, setIsGeneratingEmail] = useState(false)
  const handleGenerateEmail = async () => {
    setIsGeneratingEmail(true);
    await Promise.all(
      [
        runSenderReceiptProcessing(),
        runContentProcessing()
      ]);
  };


  useEffect(() => {
    if (!isGeneratingEmail) {
      return;
    }

    if (isContentProcessing || isSenderReceiptProcessing) {
      return;
    }
    setIsGeneratingEmail(false);

    if (senderReceiptOutput) {
      const aiForm = removeNullValues(JSON.parse(senderReceiptOutput));
      setForm(prevForm => ({
        ...prevForm,
        ...aiForm
      }));
    }

    setEmailContent(contentOutput);
    handleOpen();
  }, [isSenderReceiptProcessing, isContentProcessing]);


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    const newFormData = {
      ...form,
      [name]: value,
    };

    setForm(newFormData);
    updateScratchPad(newFormData);
    setHasChange(true); // Set hasChange to true whenever there's a change in the form
  };

  const updateScratchPad = (newFormData: any) => {
    const currentUserUid = globalContext.user?.uid;
    if (!currentUserUid) {
      return;
    }
    setDoc(doc(COLLECTION_USER_FORM_DATA, currentUserUid), {
      ...newFormData,
      updatedAt: serverTimestamp(),
    }, {merge: true});
  };

  const handleSubmit = () => {
    const emailData: SchemaMail = {
      to: form.recipientEmails,
      cc: form.ccEmails,
      bcc: form.bccEmails,
      from: form.senderEmail,
      message: {
        subject: `기안서 - ${form.senderName}`,
        html: emailContent || "",
        text: ""
      }
    }
    setHasChange(false); // Set hasChange to false after the email is sent

    addDoc(COLLECTION_MAIL, emailData)
      .then(() => {
        showSnackbar("Email was sent successfully!");
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
        showSnackbar("Error sending email: " + error);
      });

    handleClose();
  };

  return (
    <Box
      sx={{
        p: 2,
        borderRadius: 1,
        boxShadow: 1,
        height: '100vh',
        marginLeft: 'auto', // set left margin to auto
        marginRight: 'auto', // set right margin to auto
      }}
    >
      <Box
        sx={{
          maxWidth: 600,
          maxHeight: 700,
          margin: 'auto',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 2,
            width: '100%',
          }}
        >
          <img
            src={app.logoImageUrl}
            alt={app.title}
            style={{
              maxWidth: 40,
              maxHeight: 40,
              margin: 20,
            }}
          />
          <Typography variant="h5" component="div">{app.title}</Typography>
        </Box>

        <VoiceInputComponent
          app={app}
          promptText={promptText}
          setPromptText={setPromptText}
        />
        <Box/>
        {isGeneratingEmail
          ? <Box sx={{
            display: "flex", flexDirection: "column",
            alignItems: "center",
            gap: 1,
          }}>
            <CircularProgress color={'primary'}/>
            기안서 이메일을 생성하고 있습니다.
          </Box>
          : <Button
            variant="contained"
            size={"large"}
            onClick={handleGenerateEmail}
            disabled={!promptText}
          >
            Generate Email
          </Button>}
      </Box>

      <ToblBottomSheet
        open={open}
        onClose={handleClose}
        maxWidth={'md'}
        fullWidth
        fullScreen={!fullScreen}
        appBar={
          <DialogTitle
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '0px 10px',
              height: '60px',
              width: '100%',
            }}
            onClick={(event) => event.stopPropagation()}
          >
            <IconButton
              color="primary"
              aria-label="close"
              onClick={() => {
                if (window.confirm(
                  '생성된 데이터를 삭제하시겠습니까? 수신자, 발신자 정보는 저장되고 생성된 기안서는 삭제됩니다.'
                )) {
                  handleClose();
                }
              }}
              style={{
                width: '40px',
                height: '40px',
              }}
            >
              <CloseIcon sx={{fontSize: '26px'}}/>
            </IconButton>

            <IconButton
              type="submit"
              aria-label="send"
              onClick={handleSubmit}
            >
              <SendIcon sx={{fontSize: '26px'}}/>
            </IconButton>
          </DialogTitle>
        }
        body={<Box padding={'0px 15px'}>
          <Box component="form">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <BlockEditFormRow
                  label={'From'}
                  titleButton={<Typography
                    variant={'subtitle2'}
                    color={showName ? 'primary' : theme.palette.text.secondary}
                    sx={{
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    }}
                    onClick={handleShowName}
                  >
                    {(form.senderName || 'Name') + ' / ' + (form.department || 'Department')}
                  </Typography>}
                >
                  <TextField
                    required
                    fullWidth
                    id="from"
                    name="from"
                    value={form.senderEmail}
                    onChange={handleChange}
                  />
                </BlockEditFormRow>
              </Grid>
              {showName && <Grid item xs={6}>
                  <BlockEditFormRow label={'Name'}>
                      <TextField
                          required
                          fullWidth
                          id="name"
                          name="name"
                          value={form.senderName}
                          onChange={handleChange}
                      />
                  </BlockEditFormRow>
              </Grid>}
              {showName && <Grid item xs={6}>
                  <BlockEditFormRow label={'Department'}>
                      <TextField
                          required
                          fullWidth
                          id="department"
                          name="department"
                          value={form.department}
                          onChange={handleChange}
                      />
                  </BlockEditFormRow>
              </Grid>}
              <Grid item xs={12}>
                <BlockEditFormRow
                  label={'To'}
                  titleButton={<Typography
                    variant={'subtitle2'}
                    color={showCC ? 'primary' : theme.palette.text.secondary}
                    sx={{
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    }}
                    onClick={handleShowCC}
                  >
                    Cc / Bcc
                  </Typography>}
                >
                  <TextField
                    required
                    fullWidth
                    id="to"
                    name="to"
                    value={form.recipientEmails}
                    onChange={handleChange}
                  />
                </BlockEditFormRow>
              </Grid>
              {showCC && <Grid item xs={12} sm={6}>
                  <BlockEditFormRow label={'CC Emails'}>
                      <TextField
                          fullWidth
                          id="ccEmails"
                          name="ccEmails"
                          value={form.ccEmails}
                          onChange={handleChange}
                      />
                  </BlockEditFormRow>
              </Grid>}
              {showCC && <Grid item xs={12} sm={6}>
                  <BlockEditFormRow label={'BCC Emails'}>
                      <TextField
                          fullWidth
                          id="bccEmails"
                          name="bccEmails"
                          value={form.bccEmails}
                          onChange={handleChange}
                      />
                  </BlockEditFormRow>
              </Grid>}
            </Grid>
          </Box>

          {emailContent
            ? <>
              <Typography variant={'body2'} mt={'30px'}>Content</Typography>
              <Paper
                sx={{
                  width: '100%',
                  overflow: 'auto',
                  mt: 1,
                  p: 2,
                  backgroundColor: 'white',
                  color: 'black',
                  minHeight: 600,
                }}
                dangerouslySetInnerHTML={{
                  __html:
                    htmlHeader +
                    (emailContent || '') + htmlFooter
                }}/>
            </>
            : <Box
              sx={{
                width: '100%',
                overflow: 'auto',
                margin: '30px 0px',
                backgroundColor: 'white',
                color: 'black',
              }}
            >
              아직 이메일 내용이 작성되지 않았습니다.
            </Box>
          }
        </Box>}
      />
    </Box>
  );
};

export default EmailSenderAppView;
